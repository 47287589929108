import React, { useContext, useEffect, useState, useRef } from 'react'
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows'
import { useParams, useNavigate } from 'react-router-dom'
import { SurveyContext } from '../Survey/SurveyContext'
import { Table } from '../../components/Table'
import { Loader, StartLoader } from '../../components/Loader'
import Modal from '../../components/Modal'
import SurveyIcon from '../../components/Icons/SurveyIcon'
import Guidelines from '../../components/Guidelines'
import TourGuide from '../../components/TourGuide'
import { FeedbackIcon, HelpIcon } from '../../components/Icons'
import Rating from '../../components/Rating'
import { Helmet } from 'react-helmet'
import { TourGuideContext } from '../../context/TourGuideContext'
import Navbar from '../../components/Navbar'
import { ratingConstants } from '../../lib/constants'
import ToolFooter from '../../components/ToolFooter'

const Diagram = () => {
  const navigate = useNavigate()
  const updateXarrow = useXarrow()
  const { id } = useParams()
  const [cursor, setCursor] = useState<any>({})
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const {
    loading,
    contentData,
    getContent,
    surveyData,
    setSurveyData,
    setCenteredNode,
    centeredNode,
    updateConnection,
    addSubNode,
    changeNode,
    showPopup,
    setShowPopup,
    setCount,
    setCompleted,
    completed,
    downloadReport,
    setContentData
  } = useContext(SurveyContext)
  const { run, go, next, setState } = useContext(TourGuideContext)
  const [editor, setEditor] = useState<any>({})
  const [addingNodes, setAddingNodes] = useState<boolean>(false)
  const [addedNodes, setAddedNodes] = useState<any>({})
  const [centerNodeData, setCenterNodeData] = useState<any>({})
  const [openAccordionData, setOpenAccordionData] = useState<any>({})
  const tableRef = useRef<HTMLDivElement>(null)
  const tableRef2 = useRef<HTMLDivElement>(null)
  const centerRef = useRef<HTMLDivElement>(null)
  const tempRef = useRef<HTMLDivElement>(null)
  const [addingArrowPosition, setAddingArrowPosition] = useState<any>({})
  const [openAccordion, setOpenAccordion] = useState<string>('')
  const [fixed, setFixed] = useState<any>({})
  const [selectedSubnodes, setSelectedSubnodes] = useState<Array<any>>([])
  const [rerender, setRerender] = useState<number>(1)
  const addConnection = (subNode: any, type: string = 'positive', position: string = 'center') => {
    if (!addedNodes?.start) {
      setAddedNodes({ start: subNode, type, position })
    } else if (addedNodes.start?.id === subNode?.id) {
      setAddedNodes({})
    } else {
      updateConnection({ ...addedNodes, end: subNode }, addedNodes?.type === 'positive' ? 'r' : 'b')
      setAddedNodes({})
    }
  }

  useEffect(() => {
    if (!loading && !contentData?.contentId && id) {
      // console.log('loading', loading)
      // console.log('id', id)
      // console.log('contentData', contentData)
      // console.log('--------------------------------')
      getContent(id)
    }
  }, [id, loading])

  useEffect(() => {
    let temp = contentData?.nodes?.find((item: any) => item.id === centeredNode)
    setCenterNodeData(temp)
  }, [contentData?.nodes, centeredNode])
  useEffect(() => {
    let temp = contentData?.nodes?.find((item: any) =>
      openAccordion === 'cn' ? item.id === centeredNode : item.id === openAccordion,
    )
    setOpenAccordionData(temp)
  }, [contentData?.nodes, openAccordion])

  useEffect(() => {
    function handleCursor(event: MouseEvent) {
      const target = event.target as Element
      if (tableRef.current && centerRef.current && !containsTarget(target)) {
        setCursor({})
      }
      if (addedNodes.start) {
        if (tempRef?.current) {
          tempRef.current.style.left = event.x + 'px'
          tempRef.current.style.top = event.y + 'px'
        } else setAddingArrowPosition({ top: event.y + 'px', left: event.x + 'px', position: 'fixed' })
      }
      updateXarrow()
    }
    const handleScroll = () => {
      updateXarrow()
    }
    // Bind the event listener
    document.addEventListener('mousemove', handleCursor)
    // Bind the event listener
    document.addEventListener('scroll', handleScroll)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousemove', handleCursor)
      document.removeEventListener('scroll', handleScroll)
    }
  }, [addedNodes?.start])
  const containsTarget = (target: Element): boolean => {
    let res = false
    if (tableRef?.current && centerRef?.current && tableRef2?.current) {
      tableRef?.current?.childNodes?.forEach((child) => {
        if (child?.contains(target)) {
          res = true
        }
      })
      tableRef2?.current?.childNodes?.forEach((child) => {
        if (child?.contains(target)) {
          res = true
        }
      })
      centerRef?.current?.childNodes?.forEach((child) => {
        if (child?.contains(target)) {
          res = true
        }
      })
    }
    return res
  }

  const [selectedCategory, setSelectedCategory] = useState([1])
  return (
    <>
      {contentData?.projectName ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title> {`System Mapper Tool  ${contentData?.projectName ? ` | ${contentData?.projectName}` : ''}`}</title>
        </Helmet>
      ) : (
        <></>
      )}
      {/* Navbar */}
      <Navbar
        {...{
          contentData,
          selectedCategory,
          setSelectedCategory,
          setFixed,
          changeNode,
          centerNodeData,
          openAccordionData,
          showCategories: true,
          showCTA: true,
        }}
      />

      {!contentData?.nodes?.length ? (
        <StartLoader />
      ) : (
        <Xwrapper>
          {/* <Guidelines /> */}
          <div className="relative flex justify-between pt-5 w-full min-h-[100vh] h-full bg-[color:var(--background-light)]">
            {showPopup?.show && (
              <Modal
                onClose={() => {
                  setShowPopup({ type: false })
                  setCount((e: number) => {
                    return e + 3
                  })
                  if (showPopup?.type === 'done') {
                    window.location.href = `${window.location.origin}/${contentData.contentId}/user`
                  }
                }}
              >
                {showPopup?.type === 'ask' ? (
                  <>
                    <div className="items-center md:flex">
                      <div className="flex items-center justify-center flex-shrink-0 w-16 h-16 rounded-full">
                        <SurveyIcon />
                      </div>
                      <div className="mt-4 text-center md:mt-0 md:ml-6 md:text-left">
                        <p className="text-xl font-bold font-[Ubuntu]">Continue Survey ?</p>
                        <p className="mt-1 text-base text-gray-700">
                          Do you want to Continue ? Or close the survey here !
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 text-center md:text-right md:flex md:justify-end">
                      <button
                        className="block w-full px-4 py-3 text-base font-semibold text-white bg-[color:var(--background-button)] rounded-lg md:inline-block md:w-auto md:py-2 md:ml-2 md:order-2"
                        onClick={() => {
                          setShowPopup({ type: false })
                          setCount((e: number) => {
                            return e + 3
                          })
                        }}
                      >
                        Continue
                      </button>
                      <button
                        className="block w-full px-4 py-3 mt-4 text-base font-semibold bg-gray-200 rounded-lg md:inline-block md:w-auto md:py-2 md:mt-0 md:order-1"
                        onClick={() => setCompleted(true)}
                      >
                        {completed ? 'Saving...' : 'Save and Exit'}
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center p-4 my-2 font-thin">
                    <h1 className="text-2xl">Thank you for completing the survey.</h1>
                    <div className="flex items-center gap-3 mt-6">{/* Thank you for completing the survey. */}</div>
                    <div className="flex items-center justify-end w-full gap-3 text-base">
                      {/* <span> Click on button to proceed for feedback.</span> */}
                      <button
                        className="px-4 mt-2 py-1 text-sm rounded-md text-white bg-[color:var(--background-button)]"
                        onClick={() => {
                          downloadReport()
                        }}
                      >
                        Download report
                      </button>
                      <button
                        className="px-4 mt-2 py-1 text-sm rounded-md text-white bg-[color:var(--background-button)]"
                        onClick={() => {
                          navigate(`/${id}/feedback`)
                        }}
                      >
                        Feedback
                      </button>
                    </div>
                  </div>
                )}
              </Modal>
            )}
            {openAccordion && rerender &&
              contentData?.nodes
                ?.filter((x: any) => x.id === openAccordion)
                ?.map((node: any, index: any) => {
                  return node?.nodes.map((subNode: any, index: any) => {
                    return subNode?.connections?.map((con: any, index: any) => {
                      if (con.id.slice(1).split('|')?.[0] === centeredNode)
                        return (
                          <div
                            className={`
                      ${
                        fixed?.start || fixed?.end
                          ? (fixed?.end && !fixed?.start && subNode.id === fixed.end) ||
                            (fixed?.start && fixed?.end && subNode.id === fixed.end && con.id === fixed.start) ||
                            (fixed?.start && con.id === fixed.start) ||
                            fixed.start === `cn-${con.id.split('|')[1]}`
                            ? 'animate-fadeIn z-[999]'
                            : 'animate-fadeOut z-[1]'
                          : `${
                              !cursor.node ||
                              (cursor.subNode === subNode.id &&
                                (cursor?.connections?.includes(con.id) ||
                                  cursor?.connections?.includes(`cn-${con.id.split('|')[1]}`)))
                                ? 'animate-fadeIn dem'
                                : 'animate-fadeOut'
                            }`
                      }
                       ${editor?.type ? 'opacity-100' : ''}`}
                            onMouseEnter={() => {
                              // console.log("enter")
                              if (subNode?.connections && subNode.connections?.length) {
                                setCursor({
                                  node: node.id,
                                  subNode: subNode.id,
                                  connections: [con.id.includes(centeredNode) ? `cn-${con.id.split('|')[1]}` : con.id],
                                })
                              } else {
                                setCursor({})
                              }
                            }}
                          >
                            <Xarrow
                              start={subNode.id}
                              end={con.id}
                              labels={{
                                // middle: 'Reinforcing',
                                middle: (
                                  <Rating
                                    value={con.value}
                                    i={index}
                                    subNode={subNode}
                                    setCenterNodeData={setCenterNodeData}
                                    setCursor={setCursor}
                                    connection={con}
                                    centerNodeData={centerNodeData}
                                    setFixed={setFixed}
                                    openAccordionData={openAccordionData}
                                    setOpenAccordionData={setOpenAccordionData}
                                    changeNode={changeNode}
                                    setRerender={setRerender}
                                  />
                                ),
                              }}
                              // headShape={{svgElem:<path onClick={()=>{console.log("subnode:",subNode.id, "  con:", con.id )}} d="M 0 0 L 1 0.5 L 0 1 L 0.25 0.5 z"></path>}}
                              strokeWidth={cursor.subNode === subNode.id ? 3 : 2}
                              headSize={6}
                              lineColor={
                                con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value)
                                  ? '#ff3e3e'
                                  : '#2E2E2E'
                              }
                              headColor={
                                con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value)
                                  ? '#ff3e3e'
                                  : '#2E2E2E'
                              }
                              dashness={
                                (con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value)) && {
                                  strokeLen: 5,
                                  nonStrokeLen: 7,
                                  animation: 2,
                                }
                              }
                              zIndex={99}
                              curveness={1}
                            />
                          </div>
                        )
                    })
                  })
                })}

            {
              <div ref={centerRef} className="fixed top-0 bottom-0 left-0 right-0 mx-auto my-auto w-max h-max">
                <Table
                  node={{ ...contentData?.nodes?.find((item: any) => item.id === centeredNode) }}
                  key={'centered1'}
                  cursor={cursor}
                  setCursor={setCursor}
                  addingNodes={addingNodes}
                  addedNodes={addedNodes}
                  addConnection={addConnection}
                  tourguideNode={{
                    startConnection: 'startConnection',
                    addConnection: 'addConnection',
                    pinsubnode: 'pinsubnode',
                    run,
                    // next,
                  }}
                  // position={positions?.[centeredNode]}
                  centeredNode={centeredNode}
                  centerNodeData={centerNodeData}
                  setCenterNodeData={setCenterNodeData}
                  editor={editor}
                  addSubNode={addSubNode}
                  openAccordion={openAccordion}
                  setOpenAccordion={setOpenAccordion}
                  fixed={fixed}
                  setFixed={setFixed}
                  setCenteredNode={setCenteredNode}
                  setEditor={setEditor}
                  selectedSubnodes={selectedSubnodes}
                  setSelectedSubnodes={setSelectedSubnodes}
                  changeNode={changeNode}
                  openAccordionData={openAccordionData}
                  setOpenAccordionData={setOpenAccordionData}
                  setRerender={setRerender}
                />
              </div>
            }

            <div ref={tableRef} className="grid items-end h-full grid-cols-1 mr-auto w-max mb-[80px]">
              {contentData?.nodes?.map((item: any, i: number) => {
                if (item.id && i < contentData.nodes.length / 2)
                  return (
                    <Table
                      node={
                        item.id === centeredNode
                          ? {
                              ...item,
                              id: `cn`,
                              nodes: item.nodes.map((node: any, i: number) => {
                                return { ...node, id: `cn-${i + 1}` }
                              }),
                            }
                          : item
                      }
                      tourguideNode={
                        i === Math.round(contentData.nodes.length / 4)
                          ? {
                              nodeClass: 'sidenode',
                              dropdownClass: 'dropdown',
                              // next: next,
                              run: run,
                              bringCenter: 'bringCenter',
                            }
                          : i === Math.round(contentData.nodes.length / 4) + 1
                          ? {
                              anothersideNode: 'anothersideNode',
                              // addConnection:"addConnection",
                              endConnection: 'endConnection',
                              run,
                              // next,
                            }
                          : {}
                      }
                      key={i}
                      cursor={cursor}
                      setCursor={setCursor}
                      addingNodes={addingNodes}
                      addedNodes={addedNodes}
                      addConnection={addConnection}
                      // position={positions?.[item.id]}
                      centeredNode={centeredNode}
                      centerNodeData={centerNodeData}
                      setCenterNodeData={setCenterNodeData}
                      editor={editor}
                      addSubNode={addSubNode}
                      openAccordion={openAccordion}
                      setOpenAccordion={setOpenAccordion}
                      fixed={fixed}
                      setFixed={setFixed}
                      leftNode={true}
                      setCenteredNode={setCenteredNode}
                      setEditor={setEditor}
                      selectedSubnodes={selectedSubnodes}
                      setSelectedSubnodes={setSelectedSubnodes}
                      changeNode={changeNode}
                      selectedCategory={selectedCategory}
                      openAccordionData={openAccordionData}
                      setOpenAccordionData={setOpenAccordionData}
                      setRerender={setRerender}
                    />
                  )
              })}
            </div>

            <div ref={tableRef2} className="grid items-end h-full grid-cols-1 ml-auto w-max mb-[80px]">
              {contentData?.nodes?.map((item: any, i: number) => {
                if (item.id && i >= contentData.nodes.length / 2)
                  return (
                    <Table
                      node={
                        item.id === centeredNode
                          ? {
                              ...item,
                              id: `cn`,
                              nodes: item.nodes.map((node: any, i: number) => {
                                return { ...node, id: `cn-${i + 1}` }
                              }),
                            }
                          : item
                      }
                      key={i}
                      cursor={cursor}
                      setCursor={setCursor}
                      addingNodes={addingNodes}
                      addedNodes={addedNodes}
                      addConnection={addConnection}
                      // position={positions?.[item.id]}
                      centeredNode={centeredNode}
                      centerNodeData={centerNodeData}
                      setCenterNodeData={setCenterNodeData}
                      editor={editor}
                      addSubNode={addSubNode}
                      openAccordion={openAccordion}
                      setOpenAccordion={setOpenAccordion}
                      fixed={fixed}
                      setFixed={setFixed}
                      leftNode={false}
                      setCenteredNode={setCenteredNode}
                      setEditor={setEditor}
                      selectedSubnodes={selectedSubnodes}
                      setSelectedSubnodes={setSelectedSubnodes}
                      changeNode={changeNode}
                      selectedCategory={selectedCategory}
                      openAccordionData={openAccordionData}
                      setOpenAccordionData={setOpenAccordionData}
                      setRerender={setRerender}
                    />
                  )
              })}
            </div>
            {addedNodes?.start && (
              <>
                <div id="temp" className="fixed" ref={tempRef} style={addingArrowPosition}></div>
                <Xarrow
                  start={addedNodes?.start.id}
                  end={'temp'}
                  labels={''}
                  strokeWidth={1.2}
                  lineColor={editor.value === 'negative' ? '#ff3e3e' : '#2E2E2E'}
                  headColor={editor.value === 'negative' ? '#ff3e3e' : '#2E2E2E'}
                  dashness={editor.value === 'negative' && { strokeLen: 5, nonStrokeLen: 7, animation: 2 }}
                  // path={"grid"}
                  zIndex={10}
                />
              </>
            )}

            <div
              className="fixed bottom-0 w-full h-[15%]"
              style={{ background: 'linear-gradient(to bottom,rgba(226,223,225,0) 20%,rgba(226,223,225,1) 100%)' }}
            ></div>
          </div>
        </Xwrapper>
      )}
      <ToolFooter
        setFixed={setFixed}
        changeNode={changeNode}
        centerNodeData={centerNodeData}
        openAccordionData={openAccordionData}
        contentData={contentData}
        setContentData={setContentData}
        setOpenAccordion={setOpenAccordion}
      />
    </>
  )
}
export default Diagram;