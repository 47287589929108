export const designations = [
    { title: 'Business, finance and administration occupations', value: 'd1' },
    { title: 'Natural and applied sciences and related occupations', value: 'd2' },
    { title: 'Health occupations', value: 'd3' },
    { title: 'Education, law and social, community and government services', value: 'd4' },
    { title: 'Art, culture, recreation and sport', value: 'd5' },
    { title: 'Sales and service occupations', value: 'd6' },
    { title: 'Trades, transport and equipment operators and related occupations', value: 'd7' },
    { title: 'Natural resources, agriculture and related production occupations', value: 'd8' },
    { title: 'Manufacturing and utilities', value: 'd9' },
    { title: 'Others', value: 'd10' },
  ]

export const domains =  [
    { title: 'Food', value: 'food' },
    { title: 'Climate', value: 'climate' },
    { title: 'Biodiversity', value: 'biodiversity' },
    { title: 'Health', value: 'health' },
    { title: 'Planning', value: 'planning' },
    { title: 'Others', value: 'NA' }
  ]

export const gender = [
    { title: 'Male', value: 'male' },
    { title: 'Female', value: 'female' },
    { title: 'Non-binary', value: 'non-binary' },
    { title: 'Prefer not to say', value: 'N/A' }
  ]
export const ratingConstants = {
  BALANCING_REGEX : /^[bB]\s?$/,
  REINFORCING_REGEX : /^[rR]\s?$/,
}

export const EDITOR_FONT_FAMILIES = [ 'monospace', 'serif', 'montserrat', 'lato', 'poppins', 'roboto', 'playfair-display', 'libre-bodoni', 'lora', 'quicksand',  'nunito', 'raleway', "orbit", "comfortaa",]