import React, { FC, useState, useEffect } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children?: React.ReactNode;
  ClassName?: string
};

const Portal: FC<PortalProps> = ({
  children,
  ClassName
}) => {
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);


  useEffect(() => {
    const node = document.createElement("div");
    document.body.appendChild(node);
    setPortalNode(node);

    return () => {
      document.body.removeChild(node);
    };
  }, []);

  if (!portalNode) return null;

  return (
    <>
      {createPortal(
        <div
          className={ClassName}
        >
          {children}
        </div>,
        portalNode
      )}
    </>
  );
};

export default Portal;
