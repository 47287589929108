import { createContext, useState, useEffect, useRef } from 'react'
import content from '../../services/content.api'
import { downloadPdf, getSourceTargetData, getxlsx } from '../../lib/utils'
import survey from '../../services/survey.api'

export const SurveyContext = createContext<any>(null)

type XlsxData = Array<{
  sheet: string
  columns: Array<{ label: string; value: string | number }>
  content: Array<any>
}>

export const SurveyState = (props: any) => {
  const [loading, setLoading] = useState(false)
  const [contentData, setContentData] = useState<any>({})
  const [surveyData, setSurveyData] = useState<any>({})
  const [centeredNode, setCenteredNode] = useState<any>(null)
  const [completed, setCompleted] = useState<boolean>(false)
  const [count, setCount] = useState<number>(3)
  const [showPopup, setShowPopup] = useState<any>({ show: false })
  const reportTemplateRef = useRef<HTMLDivElement>(null)
  const [reportTableData, setReportTableData] = useState<
    {
      source: string
      target: string
      value: string
      notes: string
      count: string | number
    }[]
  >()
  const getRandom = (max: number, min: number) => {
    return Math.floor(Math.random() * (max - min) + min)
  }

  const getContent = (id: string) => {
    if (!loading) {
      setLoading(true)
      content
        .getContentById(id)
        .then((res) => {
          if (!centeredNode) setCenteredNode(res.data.nodes[getRandom(res.data.nodes.length, 0)]?.id)
          setContentData(res.data)
          setSurveyData(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const updateConnection = (node: any, value: number) => {
    let nodes = [...contentData.nodes]
    nodes.forEach((item, i) => {
      item.nodes.forEach((item2: any, i2: any) => {
        if (item2.id === node.start.id) {
          if (item2.connections) {
            item2.connections.push({
              id: node.end.id?.includes('cn') ? `s${centeredNode}|${node.end.id?.split('-')?.[1]}` : node.end.id,
              value: value.toString(),
            })
          } else {
            item2.connections = [
              {
                id: node.end.id?.includes('cn') ? `s${centeredNode}|${node.end.id?.split('-')?.[1]}` : node.end.id,
                value: value.toString(),
              },
            ]
          }
        }
      })
    })
  }
  const addSubNode = (node: any, newValues: any) => {
    let nodes = [...contentData.nodes]
    nodes.forEach((item: any, i: number) => {
      if (item.id === node.id) {
        let length = item.nodes.length
        newValues.forEach((newNode: any, index: number) => {
          item.nodes.push({ id: `sn-${i + 1}|${length + index + 1}`, title: newNode })
        })
      }
    })
    setContentData({ ...contentData, nodes: nodes })
  }

  const changeNode = (centerNodeData: any, openAccordionData: any, newNodeId: string) => {
    // let surveyNodes =
    //   surveyData?.nodes?.map((sur: any) => {
    //     return sur.id
    //   }) || []
    // surveyNodes.push(centerNodeData?.id)
    // let temp = contentData.nodes.filter((item: any) => !surveyNodes.includes(item.id))
    // let temp = contentData.nodes.filter((item: any) => item.id !== centerNodeData?.id)
    setSurveyData((e: any) => {
      if (e.nodes) {
        // return { ...e, nodes: [...e.nodes?.filter((item:any)=>item.id !== centerNodeData?.id), centerNodeData] }
        return {
          ...e,
          nodes: e.nodes.map((item: any) =>
            item.id === centerNodeData?.id
              ? centerNodeData
              : item.id === openAccordionData?.id
              ? openAccordionData
              : item,
          ),
        }
      } else {
        return { ...e, nodes: [centerNodeData] }
      }
    })
    setContentData((e: any) => {
      if (e.nodes) {
        return {
          ...e,
          nodes: e.nodes.map((item: any) =>
            item.id === centerNodeData?.id
              ? centerNodeData
              : item.id === openAccordionData?.id
              ? openAccordionData
              : item,
          ),
        }
      } else {
        return { ...e, nodes: [centerNodeData] }
      }
    })
    if (newNodeId !== 'end' && newNodeId !== 'close' && newNodeId !== 'openAccordion') {
      setCenteredNode(newNodeId)
    } else if (!completed && newNodeId === 'end') {
      setShowPopup({ show: true, type: 'ask' })
    }

    if (newNodeId === 'close') setCenteredNode(null)
    // newNodeId === "openAccordion" for side node data to be updated.
  }
  const download = () => {
    let xlsxData: XlsxData = [
      { sheet: contentData?.title || 'Survey', columns: [{ label: '', value: 'title' }], content: [] },
    ]

    const contSchema = contentData?.nodes?.reduce(
      (acc: any, curr: any) => {
        acc[curr.id] = ''
        curr?.nodes?.forEach((subNode: any) => {
          acc[subNode.id] = ''
        })
        return acc
      },
      { title: '' },
    )

    surveyData?.nodes?.forEach((node: any) => {
      xlsxData[0].columns.push({ label: node?.title, value: node?.id })
      // xlsxData[0].content.push({title: node?.title})
      let contNode = { ...contSchema }
      contNode['title'] = node?.title
      xlsxData[0].content.push(contNode)

      node?.nodes?.forEach((subNode: any) => {
        let cont = { ...contSchema }
        if (!subNode?.title || !subNode?.id) {
          // console.log("====>NoValue",subNode)
        } else {
          xlsxData[0].columns.push({ label: subNode?.title, value: subNode?.id })
        }
        // let content:any = {title:subNode?.title};
        cont['title'] = subNode?.title
        subNode?.connections?.forEach((con: any) => {
          cont[con.id] = con.value
        })
        xlsxData[0].content.push(cont)
      })
    })
    // console.log('Data:', xlsxData)
    getxlsx(xlsxData)
  }

  // const download = (data: any) => {
  //   let url = getxlsx(data)
  //   // window.open(url, '__blank')
  // }
  useEffect(() => {
    if (completed) {
      survey
        .addSurvey({ ...surveyData, contentId: contentData?.contentId })
        .then((res) => {
          // console.log("res",res)
          showPopup({ show: true, type: 'done' })
        })
        .catch((err) => {
          console.log(err)
          setShowPopup({ show: true, type: 'done' })
        })
    }
  }, [completed])
  const downloadReport = () => {
    const content = getSourceTargetData(surveyData)
    setReportTableData(content)
    let xlsxData: XlsxData = [
      {
        sheet: contentData?.title || 'Survey',
        columns: [
          { label: 'Source', value: 'source' },
          { label: 'Target', value: 'target' },
          { label: 'Value', value: 'value' },
          { label: 'Count', value: 'count' },
          { label: 'Notes', value: 'notes' },
        ],
        content: content,
      },
    ]
    setTimeout(() => {}, 2000)
    downloadPdf(reportTemplateRef, 'Report')
    // getxlsx(xlsxData, "Report")
    // notes list in aggregate excel
  }

  return (
    <SurveyContext.Provider
      value={{
        contentData,
        loading,
        getContent,
        surveyData,
        setSurveyData,
        centeredNode,
        setCenteredNode,
        updateConnection,
        addSubNode,
        changeNode,
        completed,
        showPopup,
        setShowPopup,
        setCount,
        setCompleted,
        downloadReport,
        setContentData
      }}
    >
      <div className="max-w-[595px] overflow-hidden w-[450px] h-full">
        <div className="absolute invisible inset-0 max-w-[450px]">
          <div ref={reportTemplateRef} className="text-xs w-[450px] p-4" style={{ scale: 0.8, verticalAlign: "middle", boxSizing: "border-box" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<style>
                #pdftable{
                  max-width: 400px;
                }
        #pdftable tr,  #pdftable td{
          padding: 2px;
          padding-bottom:4px;
          border: 0.5px solid #292929;
        }
        #pdftable td{
          max-width: 120px;
          word-break : break-word;
        }
        #pdftable td, #pdftable th { 
          padding: 3px; 
          padding-bottom: 6px; 
          font-size: 10px;
          }
        #pdftable td:first-child {
            text-align: center;
          }
         
        </style>`,
              }}
            ></div>
            {surveyData ? <div className="p-4 border border-slate-400 rounded-xl">
              <div className="px-3 my-3 ml-auto font-bold text-right" style={{ fontSize: '10px' }}>
                Systems Mapper Tool
              </div>
              <div className="flex items-center px-4 pt-1.5 my-3 font-bold rounded-md bg-slate-100" style={{fontSize:"12px", verticalAlign: "middle", paddingBottom:"15px"}}>{contentData?.projectName}</div>
              <div className="w-full border-t border-slate-400 h-[1px]"></div>
              <div className="px-3 my-3">
                <div className="text-base font-bold" style={{ fontSize: '12px' }}>
                  User Details
                </div>
                {Object.keys(surveyData?.userData || {})?.map((item, index) => {
                  return (
                    <div className="flex items-center justify-start gap-3 " key={index}>
                      <span className="text-sm font-medium break-words" style={{ fontSize: '10px' }}>
                        {item}:
                      </span>
                      <span className="text-sm break-words" style={{ fontSize: '10px' }}>
                        {' '}
                        {surveyData?.userData[item]}{' '}
                      </span>
                    </div>
                  )
                })}
              </div>
              <div className="mx-2 mt-3 mb-3 text-base font-bold" style={{ fontSize: '12px' }}>
                Survey Report
              </div>
              <div className="flex items-center justify-center">
                <table className="text-black max-w-[595px] min-w-[200px] mr-auto" id="pdftable">
                  <tr className="max-w-[595px]">
                    <th className="text-xs">Source</th>
                    <th className="text-xs">Target</th>
                    <th className="text-xs">Value</th>
                    <th className="text-xs">Notes</th>
                  </tr>
                  {reportTableData?.map((row) => {
                    return (
                      <tr className="max-w-[595px]">
                        <td className="text-xs">{row.source}</td>
                        <td className="text-xs">{row.target}</td>
                        <td className="text-xs">{row.value}</td>
                        <td className="text-xs">{row.notes}</td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </div> : <></>}
          </div>
        </div>
      </div>
      {props.children}
    </SurveyContext.Provider>
  )
}
