import React, { useState, useEffect, FC, useRef } from 'react'
import Input from '../Input'
import user from '../../services/user.api'
import { SelectInput } from '../Input'
import Toaster, { ToasterRef } from  '../Toaster/Toaster'
type ForgotPasswordProps = {
  setTab: React.Dispatch<React.SetStateAction<string>>
}
const ForgotPassword: FC<ForgotPasswordProps> = ({ setTab }) => {
  const [fields, setFields] = useState<Array<any>>([
    { type: 'text', title: 'Email', value: '', name: 'email' },
    // { type: 'password', title: 'Password', value: '', name: 'password' },
  ])
  const [step, setStep] = useState('email')
  const [userDetails, setUserDetails] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const toasterRef = useRef<any>(null)
  const handleInputChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const handleSelectChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const next = (e: any) => {
    e.preventDefault()
    if (validateUserDetails()) {
      if (step === 'email') {
        user
        .forgotPassword(userDetails?.email)
        .then((res: any) => {
          if (res.data.data) {
              if(toasterRef.current) toasterRef.current.showToast('OTP sent successfully', 'success')
              setStep('otp')
            } else if (res.data.error) {
              if(toasterRef.current) toasterRef.current.showToast(res.data.error, 'error')
              setErrors({ email: res.data.error })
            }
          })
          .catch((err) => {
            setErrors({
              email: 'Please enter registered email address!',
            })
          })
        } else if (step === 'otp') {
          user
          .verifyOTP(userDetails?.email, userDetails?.otp)
          .then((res: any) => {
            if (res.data.data) {
              if(toasterRef.current) toasterRef.current.showToast('OTP verified successfully', 'success')
              setStep('password')
            } else if (res.data.error) {
              if(toasterRef.current) toasterRef.current.showToast(res.data.error, 'error')
              setErrors({ otp: res.data.error })
            }
          })
          .catch((err) => {
            setErrors({
              ...errors,
              otp: 'Please enter valid OTP!',
            })
          })
        } else if (step === 'password') {
          user
          .resetPassword(userDetails?.email, userDetails?.otp, userDetails?.password)
          .then((res: any) => {
            if (res.data.data) {
              if(toasterRef.current) toasterRef.current.showToast('Password Reseted successfully', 'success')
              setTimeout(()=>{setTab('signin')},3000)
            } else if (res.data.error) {
              if(toasterRef.current) toasterRef.current.showToast(res.data.error, 'error')
              setErrors({ confirmPassword: res.data.error })
            }
          })
          .catch((err) => {
            setErrors({
              ...errors,
              password: 'Please enter valid OTP!',
            })
          })
      }
      // setSurveyData((t: any) => {
      //   return { ...t, ...userDetails }
      // })
      // navigate(`/${id}/tutorial`)
    }
  }
  const validateUserDetails = (): boolean => {
    let status = true
    if (step === 'email') {
      if (!userDetails.email) {
        status = false
        setErrors({ email: 'Please enter your registered email!' })
      }
    }
    if (step === 'otp') {
      if (!userDetails.otp) {
        status = false
        setErrors({ otp: 'Please enter OTP!' })
      }
    }
    if (step === 'password') {
      if (!userDetails.password) {
        status = false
        setErrors({ password: 'Please enter your Password!' })
      }
      if (!userDetails.confirmPassword) {
        status = false
        setErrors({ confirmPassword: 'Please enter your Password!' })
      }
      if (userDetails.password !== userDetails.confirmPassword) {
        status = false
        setErrors({ confirmPassword: `Didn't match with password.` })
      }
    }
    return status
  }
  return (
    <div>
      <div className="mt-8 text-lg font-medium" style={{ fontFamily: 'Sora' }}>
        {step === 'email'
          ? 'Forgot you password ?'
          : step === 'otp'
          ? 'Verify OTP'
          : step === 'password'
          ? 'Reset Password'
          : ''}
      </div>
      <Toaster ref={toasterRef} />
      <h6 className="font-light text-gray-600">
        {step === 'email'
          ? 'Enter your regitered email to reset your password.'
          : step === 'otp'
          ? 'We have sent you OTP on this email address. Please enter otp to verify'
          : step === 'password'
          ? 'Reset Password'
          : 'Please enter new password.'}
      </h6>
      <form className="pt-4 bg-transparent">
        <div className="my-6">
          <Input
            type={'text'}
            title={'Email'}
            defaultValue={userDetails.email}
            name={'email'}
            onChange={handleInputChange}
            inputClassName={''}
            labelClassName={'bg-[#fff]'}
            value={userDetails?.email}
            error={errors?.email}
            disabled={step === 'otp' || step === 'password'}
          />
        </div>

        {step === 'otp' && (
          <div className="my-6">
            <Input
              type={'text'}
              title={'OTP'}
              defaultValue={userDetails.otp}
              name={'otp'}
              onChange={handleInputChange}
              inputClassName={''}
              labelClassName={'bg-[#fff]'}
              value={userDetails?.otp}
              error={errors?.otp}
              disabled={false}
            />
          </div>
        )}
        {step === 'password' && (
          <>
            <div className="my-6">
              <Input
                type={'password'}
                title={'New Password'}
                defaultValue={userDetails.password}
                name={'password'}
                onChange={handleInputChange}
                inputClassName={''}
                labelClassName={'bg-[#fff]'}
                value={userDetails?.password}
                error={errors?.password}
                disabled={false}
              />
            </div>
            <div className="my-6">
              <Input
                type={'password'}
                title={'Confirm Password'}
                defaultValue={userDetails.confirmPassword}
                name={'confirmPassword'}
                onChange={handleInputChange}
                inputClassName={''}
                labelClassName={'bg-[#fff]'}
                value={userDetails?.confirmPassword}
                error={errors?.confirmPassword}
                disabled={false}
              />
            </div>
          </>
        )}

        <div className="mt-3">
          <button
            className="text-white px-6 md:px-10 py-3 md:py-5 rounded-md bg-[#1f3BB3] hover:bg-[#172D88] button-[Manrope]"
            onClick={next}
          >
            {step === 'email' ? 'Get OTP' : step === 'otp' ? 'Verify OTP' : step === 'password' ? 'Reset Password' : ''}
          </button>
        </div>
        <div className="flex items-center justify-between my-2">
          <div className="text-base font-normal">
            {/* <label className="flex items-center gap-3 text-muted">
              <input type="checkbox" className="pl-3" />
              Keep me signed in
            </label> */}
          </div>
          <span
            className="text-black underline cursor-pointer"
            onClick={() => {
              setTab('signin')
            }}
          >
            Back to Sign In?
          </span>
        </div>
        <div className="mt-4 font-light text-center">
          Don't have an account?{' '}
          <span
            className="text-blue-500 underline cursor-pointer text-primary"
            onClick={() => {
              // setTab('signup')
            }}
          >
            Register
          </span>
        </div>
      </form>
    </div>
  )
}

export default ForgotPassword
