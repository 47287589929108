import React, { FC, useState, useRef, Dispatch, useEffect, SetStateAction } from 'react'
// ============ Icons to include ============
import { BsChevronDown } from 'react-icons/bs'

const Accordion: FC<any> = (props) => {
  const accordionItems = props.children

  const [accodionOpenIndex, setAccordionOpenIndex] = useState<number>(0)
  useEffect(() => {
    if (props?.openAccordionItem) {
      setAccordionOpenIndex(Number(Object.keys(props?.openAccordionItem)[0]))
    }
  }, [props?.openAccordionItem])

  const childWithProps = React.Children.map(accordionItems, (item, index) => {
    const partialDetails: Partial<{
      index: number
      accodionOpenIndex: number
      setAccordionOpenIndex: Dispatch<SetStateAction<number>>
    }> = {
      index: index,
      accodionOpenIndex,
      setAccordionOpenIndex,
    }
    if (React.isValidElement(item)) {
      return React.cloneElement(item, partialDetails)
    }
    return item
  })

  return (
    <div>
      <ul className="">{childWithProps}</ul>
    </div>
  )
}

const AccordionItem: FC<any> = (props) => {
  const { title, index, accodionOpenIndex, setAccordionOpenIndex, validation } = props
  const accordionData = props.children
  const contentEl = useRef<HTMLDivElement>(null)
  const [childHeight, setChildHeight] = useState<number>(0)

  useEffect(() => {
    if (contentEl.current) setChildHeight(contentEl.current.scrollHeight)
  }, [contentEl?.current, contentEl?.current?.scrollHeight])

  const childWithProps = React.Children.map(accordionData, (item, index) => {
    const partialDetails: Partial<{
      setChildHeight: Dispatch<SetStateAction<number>>
    }> = {
      setChildHeight,
    }
    if (React.isValidElement(item)) {
      return React.cloneElement(item, partialDetails)
    }
    return item
  })

  return (
    <li className="mb-2 last:mb-0 last:border-t-0">
      {/* Accorion header */}
      <div
        className={`p-4 flex rounded-md justify-between before:!content-[none] py-1.5 px-4 cursor-pointer whitespace-nowrap select-none  bg-[color:var(--background-darker)] text-white `}
        onClick={() => {
          const valNo = validation ? validation() : -1
          if (valNo !== -1) setAccordionOpenIndex(valNo)
          else if (accodionOpenIndex === index) {
            setAccordionOpenIndex(-1)
            props?.setCurrOpenAcc && props.setCurrOpenAcc(-1)
          } else if (props.currOpenAcc) {
            setAccordionOpenIndex(index)
            props?.setCurrOpenAcc && props.setCurrOpenAcc(index)
          } else {
            setAccordionOpenIndex(index)
            props?.setCurrOpenAcc && props.setCurrOpenAcc(index)
          }
        }}
      >
        <div
          className={`cursor-pointer text-lg font-medium${
            accodionOpenIndex === index ? '' : ''
          } `}
        >
          {title}
        </div>
        <BsChevronDown
          className={`my-auto lg:h-4 lg:w-4 overflow-hidden transition-all duration-500 ease-in-out ${
            index === accodionOpenIndex ? 'rotate-[-90deg]' : ''
          }`}
        />
      </div>
      {/* Accordion body */}
      <div
        ref={contentEl}
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          index === accodionOpenIndex ? 'bg-[#ffffffd4]' : 'bg-white'
        }`}
        style={{
          height: index === accodionOpenIndex ? childHeight : '0',
        }}
      >
        {childWithProps}
      </div>
    </li>
  )
}

export { Accordion, AccordionItem }
