import React, { FC, useEffect, useState } from 'react'
import Portal from './Portal'
import { MdClose } from 'react-icons/md'
import VideoPlayer from './VideoPlayer'

const DemoVideosModal: FC<{ show:boolean, handleClose:()=>void }> = ({ show, handleClose }) => {
   const [videos, setVideos] = useState([
    {
      title: 'Connections and Rating',
      videoUrl: 'https://ik.imagekit.io/2e5xfxvehcg/abc_cs5vCxvYXt.jpg',
      thumbnailUrl:
        'https://images.unsplash.com/photo-1690029670420-34fa5ad95814?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODIzNDJ8MHwxfHNlYXJjaHwyMHx8Y29ubmVjdGlvbnMlMjBhbmQlMjBub2Rlc3xlbnwwfHx8fDE3MzMyNTE4NzN8MA&ixlib=rb-4.0.3&q=80&w=1080',
    },
    {
      title: 'Review connection',
      videoUrl: 'https://ik.imagekit.io/2e5xfxvehcg/abc_9pBkj0pK1.jpg',
      thumbnailUrl:
        'https://images.unsplash.com/photo-1658963642269-0a36c9eb5d66?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDA2ODB8MHwxfHNlYXJjaHwzfHxjb25uZWN0aW9ucyUyMGFuZCUyMHN5c3RlbXN8ZW58MHx8fHwxNzMzMjUxOTg3fDA&ixlib=rb-4.0.3&q=80&w=1080',
    },
    {
      title: 'Review node groups and nodes',
      videoUrl: 'https://ik.imagekit.io/2e5xfxvehcg/_abc_cs5vCxvYXt_ZQFgDhiOy.jpg',
      thumbnailUrl:
        'https://images.unsplash.com/photo-1658963658597-58f336d455ea?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w1ODIyOTd8MHwxfHNlYXJjaHwyMXx8bGlua3MlMjBhbmQlMjBjb25uZWN0aW9uc3xlbnwwfHx8fDE3MzMyNTIwNTh8MA&ixlib=rb-4.0.3&q=80&w=1080',
    },
  ])
  return (
    <>
      {show && (
        <Portal>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-[85%] lg:min-w-[400px] max-w-[85%] md:max-w-[90%] lg:max-w-[650px] xl:max-w-[850px]">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-xl focus:outline-none ">
                {/*header*/}
                {
                  <div className="flex items-center justify-between p-4 border-b border-solid px-7 border-blueGray-200">
                    <h3 className="text-xl  lg:text-2xl font-black mb-0 text-[#5959FF] font-[Lato]">
                      {'How it works'}
                    </h3>
                    <span
                      className="text-2xl cursor-pointer"
                      onClick={handleClose}
                    >
                      <MdClose></MdClose>
                    </span>
                  </div>
                }
                {/*body*/}
                <div className="relative flex-auto max-h-[80vh] overflow-y-auto">
                  <div className="p-6 px-7">
                    <div className="text-xl font-semibold" style={{fontFamily:"Open Sans"}}>Demo Videos</div>
                    <div className="grid grid-cols-1 gap-3 py-5 sm:grid-cols-2 lg:grid-cols-3">
                      {videos?.map((video, index) => {
                        return (
                          <div
                            className="flex flex-col w-full h-full gap-2 p-2 border rounded-md shadow-lg border-slate-300"
                            key={index}
                          >
                            <div className="max-h-[150px] lg:max-h-[200px ] xl:max-h-[250px] h-full w-full">
                            <VideoPlayer video={{ src: video.videoUrl }} thumbnail={{ src: video?.thumbnailUrl }} />
                            </div>
                            <div className="px-2 mb-3 text-base font-[Montserrat] font-semibold text-center">{video.title}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-[999] bg-black"></div>
        </Portal>
      )}
    </>
  )
}

export default DemoVideosModal
