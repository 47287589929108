import React, { useState, useEffect } from 'react'
import Input from '../../components/Input'
import { SelectInput } from '../../components/Input'
import SignIn from '../../components/Login/SignIn'
import SignUp from '../../components/Login/SignUp'
import ForgotPassword from '../../components/Login/ForgotPassword'

const Login = () => {
  const [tab, setTab] = useState<string>('signin')
  return (
    <div className="font-[Manrope]" style={{ fontFamily: 'Lexend Deca' }}>
      {/* JSX fragment */}
      <div className="bg-[#f4f5f7]">
        <div className="py-4 md:py-8"></div>
        <div className="w-5/6 h-full py-4 mx-auto bg-white rounded-md shadow-lg px-7 md:w-5/12">
          <div className="px-4 py-5 text-left sm:px5">
            <div className="flex items-center gap-4 bg-[#f4f5f7] rounded-md p-3">
              <div className="h-20">
                <img className="w-full h-full" src="./Assets/logonodes.svg" alt="logo" />
              </div>
              <h2 className="text-xl"> System Mapper Tool</h2>
            </div>
            {tab === 'signin' ? (
              <SignIn setTab={setTab}></SignIn>
            ) : tab === 'signup' ? (
              <SignUp setTab={setTab}></SignUp>
            ) : tab === 'forgotpassword' ? (
              <ForgotPassword setTab={setTab}></ForgotPassword>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
