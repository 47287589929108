import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Input from '../../components/Input'
import { SelectInput } from '../../components/Input'
import { SurveyContext } from '../Survey/SurveyContext'
import { gender, domains, designations } from '../../lib/constants'
import Navbar from '../../components/Navbar'

const UserForm = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { loading, contentData, getContent, surveyData, setSurveyData } = useContext(SurveyContext)

  useEffect(() => {
    if (!loading && id) {
      getContent(id)
    }
  }, [])

  useEffect(() => {
    if (surveyData?.name && !userDetails?.name) {
      let { name, gender, area, location, ageGroup } = surveyData
      setUserDetails({ name, gender, area, location, ageGroup })
    }
  }, [surveyData])
  useEffect(() => {
    if (contentData?.userFormSchema) {
        setFields(
          contentData?.userFormSchema?.reduce((result: any, cur: any) => {
            let obj = {
              ...cur,
              title: cur.name,
              ...(cur.options ? { options: cur.options.map((item: string) => ({ title: item, name: item })) } : {}),
            }
            result.push(obj)
            return result
          }, []),
        )
      }
      else
        setFields([
          { type: 'text', title: 'Name', value: '', name: 'name', required: true },
          { type: 'text', title: 'Contact-no / Email', value: '', name: 'contact' },
          { type: 'text', title: 'Location', value: '', name: 'location' },
          {
            type: 'select',
            title: 'Gender',
            name: 'gender',
            options: gender,
          },
          {
            type: 'select',
            title: 'Age-group',
            name: 'ageGroup',
            options: [
              { title: 'Below 18 yrs', value: '0-18' },
              { title: '19 - 30 yrs', value: '19-30' },
              { title: '31 - 50 yrs', value: '31-50' },
              { title: '51 - 75 yrs', value: '51-75' },
              { title: 'Above 75 yrs', value: '76-100' },
              { title: 'Prefer not to say', value: 'NA' },
            ],
          },
        ])
  }, [contentData])
  const [fields, setFields] = useState<Array<any>>([
    // { type: 'text', title: 'Name', value: '', name: 'name', required:true },
    // { type: 'text', title: 'Contact-no / Email', value: '', name: 'contact' },
    // { type: 'text', title: 'Location', value: '', name: 'location' },
    // {
    //   type: 'select',
    //   title: 'Gender',
    //   name:'gender',
    //   options: gender,
    // },
    // {
    //   type: 'select',
    //   title: 'Age-group',
    //   name:'ageGroup',
    //   options: [
    //     { title: 'Below 18 yrs', value: '0-18' },
    //     { title: '19 - 30 yrs', value: '19-30' },
    //     { title: '31 - 50 yrs', value: '31-50' },
    //     { title: '51 - 75 yrs', value: '51-75' },
    //     { title: 'Above 75 yrs', value: '76-100' },
    //     { title: 'Prefer not to say', value: 'NA' },
    //   ],
    // },
  ])
  const [userDetails, setUserDetails] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const handleInputChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const handleSelectChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const next = () => {
    if (validateUserDetails()) {
      setSurveyData((t: any) => {
        return { ...t, userData: userDetails }
      })
      navigate(`/${id}/survey`)
    }
  }
  const validateUserDetails = (): boolean => {
    let status = true
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].required) {
        if (!userDetails[fields[i].name]) {
          status = false
          setErrors((error: any) => ({ ...error, [fields[i].name]: 'Required field' }))
        }
      }
    }

    return status
  }
  return (
    <>
      <Navbar />
      <div
        className="relative w-8/12 max-w-[900px] rounded-md h-auto min-h-[200px] border-[0px] border-slate-500 bg-[#d2e4d8] mx-auto mt-[100px] p-3 pb-6 px-6"
        style={{ boxShadow: '10px 15px 20px -15px #00000080' }}
      >
        <div className="flex my-4 items-center justify-center gap-4 bg-[#304164]  text-white rounded-md p-3 py-4">
          <h1 className="text-xl font-medium " style={{ fontFamily: 'Sora' }}>
            {' '}
            User Profile
          </h1>
        </div>
        <div className="grid items-center justify-center grid-cols-2 gap-x-8">
          {fields?.map((field, index) => {
            if (field.type === 'text' || field.type === 'number') {
              return (
                <div className="my-3">
                  <Input
                    type={field.type}
                    title={field.title}
                    defaultValue={field.value}
                    name={field.name}
                    onChange={handleInputChange}
                    inputClassName={''}
                    labelClassName={'bg-[#d2e4d8]'}
                    value={userDetails[field.name]}
                    error={errors[field.name]}
                    required={field.required}
                  />
                </div>
              )
            } else {
              return (
                <div className="w-full my-3">
                  <SelectInput
                    title={field.title}
                    name={field.name}
                    options={field.options}
                    error={errors[field.name]}
                    handleChange={handleSelectChange}
                    labelClass={'bg-[#d2e4d8]'}
                    optionsClass={'bg-[#d2e4d8]'}
                    required={field.required}
                  ></SelectInput>
                </div>
              )
            }
          })}
        </div>

        <button
          className="tracking-wider rounded-lg cursor-pointer bg-[#11A8AB] hover:opacity-90 text-white px-8 p-3 m-auto flex items-center justify-center text-xl font-[Ubuntu] font-normal mt-5"
          onClick={next}
        >
          Start
        </button>
        {/* <div className="absolute rounded-full cursor-pointer bg-[#cb768a] hover:opacity-90 text-white bottom-[-50px] right-[80px] w-24 h-24 m-auto flex items-center justify-center text-xl font-semibold" onClick={next}>
        Start
      </div> */}
      </div>
    </>
  )
}
export default UserForm
