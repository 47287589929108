import client from './client'
type contentType = {
  surveyName: string
  // createdOn: string
  // modifiedOn: string
  nodes: any
  // status: boolean
}

class ContentServices {
  getAllContents() {
    return client.get('/content/')
  }
  getDistinctContents() {
    return client.get('/content/distinct')
  }
  getContentById(id: string) {
    return client.get(`/content/${id}`)
  }
  addContent(body: contentType) {
    return client.post('/content/', body)
  }
  addExistingContent(body: contentType) {
    return client.post('/content/existing', body)
  }
  updateContent(id: string, body: contentType) {
    return client.put(`/content/${id}`, body)
  }
  deleteContent(id: string) {
    return client.delete(`/content/${id}`)
  }
}
let content = new ContentServices()
export default content
