import React, { useState, useEffect } from 'react'
import content from '../services/content.api'
import Input, { RadioInput, SelectInput } from './Input'
import { MdDelete } from 'react-icons/md'
import { AiTwotoneEdit } from 'react-icons/ai'
import { CgArrowLongLeft } from 'react-icons/cg'
import Modal from './Modal'
type FieldType = {
  name?: string
  type?: 'text' | 'dropdown'
  required?: boolean
  options?: string[]
  referenced?: boolean
  showFilter?: boolean
}
const UserFormCreation = ({ distinctContents, selectedProject, setSelectedProject, setEditType }: any) => {
  const [loading, setLoading] = useState(false)
  const [contentData, setContentData] = useState<any>(null)
  const [userFormSchema, setUserFormSchema] = useState<FieldType[]>([])
  // const [changeThere, setChangeThere] = useState(false)
  const [isEditting, setIsEditting] = useState<number | null>()
  const [isDeleting, setIsDeleting] = useState<number | null>()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [fieldEditor, setFieldEditor] = useState<FieldType>()
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const getContent = (id: string) => {
    if (!loading) {
      setLoading(true)
      content
        .getContentById(id)
        .then((res) => {
          setContentData(res.data)
          if (res.data.userFormSchema) setUserFormSchema(res.data.userFormSchema)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }
  useEffect(() => {
    if (selectedProject !== 'Project') {
      setLoading(false)
      getContent(selectedProject.toString())
    }
  }, [selectedProject])
  // handle category selection of subnode
  const handleChange = (e: any) => {
    setErrors((t) => ({ ...t, [e.target.name]: '' }))
    setFieldEditor((t) => ({ ...t, [e.target.name]: e.target.value }))
  }

  // Update content to db.
  const updateContent = () => {
    content.updateContent(selectedProject, { ...contentData, userFormSchema: userFormSchema }).then((res) => {
      console.log('res', res)
    })
  }
  const initialFieldData: FieldType = { name: '', type: 'text', required: false }
  const addField = () => {
    // setUserFormSchema((schema)=>[...schema, initialFieldData])
    setFieldEditor(initialFieldData)
    setShowModal(true)
  }
  const addOptionValidator = (): boolean => {
    let result = true
    if (fieldEditor?.options) {
      const trimmedAndNonEmpty = fieldEditor.options.map((str) => str.trim()).filter((str) => str !== '')
      const uniqueSet = new Set(fieldEditor.options)
      if (trimmedAndNonEmpty.length < fieldEditor.options.length) {
        setErrors((t) => ({ ...t, options: 'Empty options are not allowed' }))
        result = false
      } else if (uniqueSet.size < fieldEditor.options.length) {
        setErrors((t) => ({ ...t, options: 'All the options should be unique' }))
        result = false
      }
    }
    return result
  }
  const addOption = () => {
    if (addOptionValidator()) setFieldEditor((f) => ({ ...f, options: [...(f?.options || []), ''] }))
  }
  const handleOptionChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors?.options) setErrors((t) => ({ ...t, options: '' }))
    let updatedOptions = JSON.parse(JSON.stringify(fieldEditor?.options))
    updatedOptions[index] = e.target.value
    setFieldEditor((t) => ({ ...t, options: updatedOptions }))
  }
  const deleteOption = (index: number) => () => {
    if (errors?.options) setErrors((t) => ({ ...t, options: '' }))
    let updatedOptions = JSON.parse(JSON.stringify(fieldEditor?.options))
    updatedOptions.splice(index, 1)
    setFieldEditor((t) => ({ ...t, options: updatedOptions }))
  }
  const validateFormData = (): boolean => {
    let result = true
    if (!fieldEditor?.name) {
      setErrors((t) => ({ ...t, name: 'Please provide field name' }))
      result = false
    }
    if (!fieldEditor?.type) {
      setErrors((t) => ({ ...t, type: 'Please select field type' }))
      result = false
    }
    if (fieldEditor?.type === 'dropdown' && !fieldEditor?.options?.length) {
      setErrors((t) => ({ ...t, options: 'Please add atleast one option for dropdown field' }))
      result = false
    } else if (fieldEditor?.type === 'dropdown' && !addOptionValidator()) {
      result = false
    }
    const existingIndex = userFormSchema?.findIndex((x) => x.name === fieldEditor?.name)
    if (
      existingIndex !== -1 &&
      ((!isEditting && isEditting !== 0) || ((isEditting || isEditting === 0) && existingIndex !== isEditting))
    ) {
      setErrors((t) => ({ ...t, name: 'Field already exists' }))
      result = false
    }
    return result
  }
  const handleSave = () => {
    if (validateFormData()) {
      if (fieldEditor) {
        if (isEditting || isEditting === 0)
          setUserFormSchema((t) => {
            return t.map((field, index) => {
              if (index === isEditting) return fieldEditor
              else return field
            })
          })
        else setUserFormSchema((t) => [...t, fieldEditor])
      }
      setFieldEditor({})
      setErrors({})
      setShowModal(false)
      setIsDeleting(null)
      setIsEditting(null)
    }
  }
  const handleModalClose = () => {
    setFieldEditor({})
    setErrors({})
    setShowModal(false)
    setIsDeleting(null)
    setIsEditting(null)
  }
  const handleEditField = (index: number) => () => {
    setIsEditting(index)
    setFieldEditor(userFormSchema[index])
    setShowModal(true)
  }
  const handleDeleteField = (index: number) => () => {
    setIsDeleting(index)
    setShowDeleteModal(true)
  }
  const confirmDeleteField = () => {
    let updatedData = JSON.parse(JSON.stringify(userFormSchema))
    if (isDeleting || isDeleting === 0) updatedData.splice(isDeleting, 1)
    setUserFormSchema(updatedData)
    setIsDeleting(null)
    setShowDeleteModal(false)
  }
  return (
    <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
      <div
        className="flex items-center justify-between gap-4 mb-[30px] cursor-pointer"
        onClick={() => {
          setEditType('')
          setSelectedProject('')
        }}
      >
        <div className="flex items-center justify-start gap-4">
          {' '}
          <span className="text-xl">
            <CgArrowLongLeft />
          </span>
          <span className="text-base">Back to Projects</span>
        </div>
        <button
          className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center "
          onClick={updateContent}
        >
          {' '}
          Save changes
        </button>
      </div>
      <div className="w-full">
        <table className="text-left">
          <thead className="!bg-[#304164] text-base text-white" style={{ fontFamily: 'Lexend Deca' }}>
            <tr className="!bg-[#304164] text-left">
              <td className="px-4 text-left min-w-[350px]">Field name</td>
              <td className="px-4 text-left min-w-[350px]">Field type</td>
              <td className="px-4 text-left">Action</td>
            </tr>
          </thead>
          <tbody>
            {!userFormSchema?.length && (
              <tr>
                <td colSpan={2} className="mx-auto text-center">
                  {' '}
                  No fields
                </td>
                <td onClick={addField}>Add Field</td>
              </tr>
            )}

            {userFormSchema?.map((field, index: number) => {
              return (
                <tr key={index}>
                  <td className="px-4 text-left"> {field.name}</td>
                  <td className="px-6 text-left"> {field.type}</td>
                  <td className="px-3">
                    <div className="flex gap-3 items center">
                      <span className="cursor-pointer hover:scale-110" onClick={handleEditField(index)}>
                        <AiTwotoneEdit />
                      </span>
                      <span className="cursor-pointer hover:scale-110" onClick={handleDeleteField(index)}>
                        <MdDelete />{' '}
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
            {!!userFormSchema?.length && (
              <tr>
                <td colSpan={2} className="mx-auto text-center">
                  {' '}
                </td>
                <td onClick={addField}>Add Field</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <Modal onClose={handleModalClose}>
          <div className="p-4 lg:w-[750px] mx-auto text-base flex items-start justify-between gap-8 max-h-[80vh] overflow-y-auto">
            <div className="flex flex-col items-center w-full gap-2 my-2 lg:w-[50%]">
              <div className="w-full gap-2 my-2 ">
                <Input
                  type={'text'}
                  title={'Field name'}
                  defaultValue={''}
                  name={'name'}
                  onChange={handleChange}
                  inputClassName={'my-2 '}
                  labelClassName={'bg-[#ffffff] !normal-case'}
                  value={fieldEditor?.name}
                  error={''}
                />
                {errors?.name ? <p className="text-sm text-red-400">{errors?.name}</p> : <></>}
              </div>
              <div className="w-full gap-2 my-2">
                <SelectInput
                  title={'Field Type'}
                  name={'type'}
                  options={[
                    { title: 'Text', value: 'text' },
                    { title: 'Dropdown', value: 'dropdown' },
                  ]}
                  handleChange={handleChange}
                  labelClass={'bg-[#d2e4d8] my-2 w-full'}
                  optionsClass={'bg-[#d2e4d8]'}
                  defaultValue="text"
                ></SelectInput>
                {errors?.type ? <p className="text-sm text-red-400">{errors?.type}</p> : <></>}
              </div>
              {fieldEditor?.type === 'dropdown' ? (
                <div className="w-full">
                  <div className="flex items-center justify-between gap-2 mb-2">
                    <p className="text-base font-medium">Options of dropdown</p>
                    <button
                      className="bg-[color:var(--background-button)] text-sm hover:bg-[color:var(--background-button-hover)] text-[#fff] px-4 py-1 rounded-md font-normal"
                      onClick={addOption}
                    >
                      + Add option
                    </button>
                  </div>
                  {fieldEditor?.options?.map((option, index) => {
                    return (
                      <div className="flex items-center gap-2">
                        <input
                          type="text"
                          className="w-full px-2 py-1 my-1 font-normal border rounded-md border-slate-400"
                          value={option}
                          onChange={handleOptionChange(index)}
                        />
                        <div className="px-2 cursor-pointer" onClick={deleteOption(index)}>
                          <MdDelete className="text-2xl" />{' '}
                        </div>
                      </div>
                    )
                  })}
                  {errors?.options ? <p className="text-sm text-red-400">{errors?.options}</p> : <></>}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="flex flex-col gap-2 w-full lg:w-[50%] my-2">
              <div className="grid grid-cols-2 gap-4">
                <RadioInput
                  title={'Required?'}
                  name={'required'}
                  value={fieldEditor?.required}
                  defaultValue={fieldEditor?.required}
                  options={[
                    { title: 'Required', value: true },
                    { title: 'Optional', value: false },
                  ]}
                  // error={errors['layout']}
                  handleChange={(e: any) => {
                    setFieldEditor((t) => ({ ...t, required: e.target.value === 'true' }))
                  }}
                  labelClass="font-medium"
                />
                <div className="">
                  <RadioInput
                    title={'Reference Field?'}
                    name={'referenced'}
                    value={fieldEditor?.referenced}
                    defaultValue={fieldEditor?.referenced}
                    options={[
                      { title: 'True', value: true },
                      { title: 'False', value: false },
                    ]}
                    disabled={userFormSchema.reduce((res: boolean, curr, index) => {
                      if (curr.referenced && index !== isEditting) res = true
                      return res
                    }, false)}
                    // error={errors['layout']}
                    handleChange={(e: any) => {
                      setFieldEditor((t) => ({ ...t, referenced: e.target.value === 'true' }))
                    }}
                    labelClass="font-medium"
                  />
                  {userFormSchema.reduce((res: boolean, curr) => {
                    if (curr.referenced) res = true
                    return res
                  }, false) ? (
                    <p className="text-xs font-normal text-slate-600 text-centers">
                      (Only one field can be set as referenced field)
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {fieldEditor?.type === 'dropdown' ? (
                <div className="flex items-center justify-between gap-4">
                  <RadioInput
                    title={'Make filter field?'}
                    name={'showFilter'}
                    value={fieldEditor?.showFilter}
                    defaultValue={fieldEditor?.showFilter}
                    options={[
                      { title: 'Enable', value: true },
                      { title: 'Disable', value: false },
                    ]}
                    // error={errors['layout']}
                    handleChange={(e: any) => {
                      setFieldEditor((t) => ({ ...t, showFilter: e.target.value === 'true' }))
                    }}
                    labelClass="font-medium"
                  />
                </div>
              ) : (
                <></>
              )}
              <button
                className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center w-auto text-center justify-center mx-auto"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal
          onClose={() => {
            setIsDeleting(null)
            setShowDeleteModal(false)
          }}
        >
          <div className="p-4 w-[80%] lg:w-[5-50px] mx-auto text-base">
            <p className="text-lg font-medium">Are you sure you want to delete this field?</p>
            <div className="flex items-center justify-center gap-3 mt-5">
              <button
                className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center"
                onClick={() => {
                  setIsDeleting(null)
                  setShowDeleteModal(false)
                }}
              >
                Cancel
              </button>
              <button
                className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center"
                onClick={confirmDeleteField}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default UserFormCreation
