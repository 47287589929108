import Form from 'react-bootstrap/Form'

const SelectInput = ({
  label,
  title,
  options,
  handleChange,
  name,
  labelClass,
  optionsClass,
  error,
  defaultValue,
  required,
}: any) => {
  return (
    <Form.Select
      aria-label={title}
      defaultValue={defaultValue}
      name={name}
      className={` text-[#383635] font-[Sora] bg-[#00000000] autofill:!bg-[#00000000] focus:outline-none w-full p-4 px-4 border border-slate-500 tracking-wide rounded-lg ${
        error && 'border-red-500'
      } ${labelClass}`}
      size="lg"
      onChange={handleChange}
    >
      <option className={` font-[Sora] font-normal p-2 ${optionsClass}`}>
        {title}
        {required && (
          <svg height="30" width="200">
            <text x="0" y="15" fill="red">
              {' '}
              *
            </text>
          </svg>
        )}
      </option>
      {options?.map((opt: any) => {
        return (
          <option value={opt.value} className={`p-2 ${optionsClass}`}>
            {opt.title}
          </option>
        )
      })}
      {/* <option value="2">Two</option>
      <option value="3">Three</option> */}
    </Form.Select>
  )
}

export default SelectInput
