import React, { useState, useEffect, FC , useRef} from 'react'
import Input from '../Input'
import { SelectInput } from '../Input'
import user from '../../services/user.api'
import Toaster, { ToasterRef } from  '../Toaster/Toaster'

const SignUp: FC<any> = ({setAddingUsers}) => {
  const [fields, setFields] = useState<Array<any>>([
    { type: 'text', title: 'Name', value: '', name: 'name' },
    { type: 'email', title: 'Email', value: '', name: 'email' },
    { type: 'text', title: 'UserName', value: '', name: 'username' },
    { type: 'select', title: 'Role', value: '', name: 'role',  options: [
      { title: 'Admin', value: 'admin' },
      { title: 'Super Admin', value: 'superadmin' }
    ], },
    { type: 'password', title: 'Password', value: '', name: 'password' },
    { type: 'password', title: 'Confirm Password', value: '', name: 'confirmPassword' },
  ])
  const [userDetails, setUserDetails] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const toasterRef = useRef<any>(null)
  const handleInputChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const handleSelectChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const signup = (e:any) => {
    e.preventDefault();
    if (validateUserDetails()) {
      user
        .addUser({...userDetails, userName: userDetails.username})
        .then((res: any) => {
          if (res.data.data) {
            setAddingUsers(false)
          } else if (res.data.error) {
            if(toasterRef.current) toasterRef.current.showToast(res.data.error, 'error')
            setErrors({ password: res.error })
          }
        })
        .catch((err) => {
          console.log("err",err)
          setErrors({
            ...errors,
            password: "UserName or Password didn't matched!",
          })
        })
    }
  }
  const validateUserDetails = (): boolean => {
    let status = true
    if (!userDetails.name) {
      status = false
      setErrors({ email: 'Please enter name!' })
    }
    if (!userDetails.password) {
      status = false
      setErrors({ password: 'Please enter Password!' })
    }
    if (!userDetails.username) {
      status = false
      setErrors({ username: 'Please enter username!' })
    }
    if (!userDetails.email) {
      status = false
      setErrors({ email: 'Please enter email!' })
    }
    if (!userDetails.role) {
      status = false
      setErrors({ role: 'Please enter role!' })
    }
    if (userDetails.password !== userDetails.confirmPassword) {
      status = false
      setErrors({ confirmPassword: 'Both password should match!' })
    }
    return status
  }
  return (
    <div>
      <Toaster ref={toasterRef} />
      <div className="mt-8 text-lg font-medium" style={{ fontFamily: 'Sora' }}>
        Create New Admin Login
      </div>
      <h6 className="font-light text-gray-600">Enter below details to continue.</h6>
      <form className="grid grid-cols-2 pt-4 bg-transparent gap-x-3">
        {fields.map((field, index) => {
          if (field.type === 'text' || field.type === 'email' || field.type === 'password') {
            return (
              <div className="my-6">
                <Input
                  type={field.type}
                  title={field.title}
                  defaultValue={field.value}
                  name={field.name}
                  onChange={handleInputChange}
                  inputClassName={''}
                  labelClassName={'bg-[#f3f7ff]'}
                  value={userDetails[field.name]}
                  error={errors[field.name]}
                />
              </div>
            )
          } else {
            return (
              <div className="w-full my-6">
                <SelectInput
                  title={field.title}
                  name={field.name}
                  options={field.options}
                  error={errors[field.name]}
                  handleChange={handleSelectChange}
                  labelClass={'bg-[#f3f7ff]'}
                  optionsClass={'bg-[#d2e4d8]'}
                ></SelectInput>
              </div>
            )
          }
        })}
        <div className="mt-3">
          <button className="text-white px-6 md:px-10 py-3 md:py-5 rounded-md bg-[#1f3BB3] hover:bg-[#172D88] button-[Manrope]" onClick={signup}>
            Create New user
          </button>
        </div>
        <div className="flex items-center justify-between my-2">
          <div className="text-base font-normal">
            {/* <label className="flex items-center gap-3 text-muted">
              <input type="checkbox" className="pl-3" />
              Keep me signed in
            </label> */}
          </div>
          {/* <span className="text-black underline cursor-pointer" onClick={()=>{setTab("forgotpassword")}}>Forgot password?</span> */}
        </div>
        {/* <div className="mt-4 font-light text-center">
          Already have an account? <p className="text-blue-500 underline cursor-pointer text-primary" onClick={()=>{setTab("signin")}}>Back to Sign In</p>
        </div> */}
      </form>
    </div>
  )
}

export default SignUp
