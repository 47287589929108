import { createContext, useState, useEffect } from 'react'
import userObj from '../services/user.api'
import { useNavigate } from 'react-router-dom'
export const LoginContext = createContext<any>(null)

export const LoginState = (props: any) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const navigate = useNavigate()
    const verifyUser = () => {
      if (localStorage.token) {
        userObj.getUserProfile().then((res) => {
          setUser(res.data.data);
          setLoggedIn(true);
          setInitialLoading(false);
        }).catch((err) => {
          setInitialLoading(false);
          // console.log(err)
          // navigate("/login")
        });
      } else {
        setInitialLoading(false);
      }
    };
    const updateUser = (user:any) => {
      setUser(user);
      userObj
        .updateUser(user)
        .then((res) => {})
        .catch((err) => {});
    };
  
    useEffect(() => {
      verifyUser();
    }, []);

  return (
    <LoginContext.Provider
      value={{
        loggedIn, user, initialLoading, verifyUser, updateUser
      }}
    >
      {props.children}
    </LoginContext.Provider>
  )
}
