import React, { useMemo, useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { EDITOR_FONT_FAMILIES } from "../../lib/constants";

// Ensure Quill modules and plugins are dynamically imported
const QuillEditor = (props:any) => {
  const [isQuillReady, setIsQuillReady] = useState(false);

  useEffect(() => {
    const initializeQuill = async () => {
      try {
        const Quill = (await import("react-quill")).default.Quill;

        var Font = Quill.import("formats/font");
        var Size = Quill.import("attributors/style/size");

        Size.whitelist = [
          "10px",
          "12px",
          "14px",
          "16px",
          "18px",
          "20px",
          "24px",
          "28px",
          "32px",
          "48px",
          "56px",
        ];
        Quill.register(Size, true);

        Font.whitelist = EDITOR_FONT_FAMILIES;
        Quill.register(Font, true);


        setIsQuillReady(true);
      } catch (error) {
        console.error("Error initializing Quill:", error);
      }
    };

    initializeQuill();
  }, []);

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "color",
    "background",
    "list",
    "image",
    "imageBlot",
    "video",
    "align",
    "indent",
  ];

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [
          { header: "1" },
          { header: "2" },
          { font: EDITOR_FONT_FAMILIES },
          {
            size: [
              "10px",
              "12px",
              "14px",
              "16px",
              "18px",
              "20px",
              "24px",
              "28px",
              "32px",
              "48px",
              "56px",
            ],
          },
        ],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        ["link"],
        [{ color: [] }],
        [{ background: [] }],
        ["clean"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        // ...(props.showImage ? [["image"], ["video"]] : []),
      ],
    },
    // imageUploader: {
    //   upload: (file:any) =>
    //     new Promise((resolve, reject) => {
    //       props.uploadImage(file).then((imgUrl:any) => {
    //         resolve(imgUrl);
    //       }).catch(reject);
    //     }),
    // },
    clipboard: {
      matchVisual: false,
    },
  }), [props]);

  if (!isQuillReady) {
    return <p>Loading...</p>;
  }

  return <ReactQuill {...props} modules={modules} formats={formats} />;
};

export default QuillEditor;
