import React, { useContext, FC, useState } from 'react'
import TourGuide from './TourGuide'
import Guidelines from './Guidelines'
import { useNavigate, useParams } from 'react-router-dom'
import { TourGuideContext } from '../context/TourGuideContext'
import { FeedbackIcon } from './Icons'
import { FiLogOut } from 'react-icons/fi'
import { MdPlayLesson } from 'react-icons/md'
import DemoVideosModal from './DemoVideosModal'
import { RiQuestionAnswerLine } from 'react-icons/ri'
import FAQModal from './FAQModal'

const Navbar: FC<any> = ({
  contentData,
  selectedCategory,
  setSelectedCategory,
  setFixed,
  changeNode,
  centerNodeData,
  openAccordionData,
  showCategories,
  showCTA,
  showLogoutButton,
}) => {
  const navigate = useNavigate()
  const { go, setState } = useContext(TourGuideContext)
  const { id } = useParams()
  const logout = () => {
    localStorage.removeItem('token')
    window.location.reload()
  }
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showFAQModal, setShowFAQModal] = useState(false)
  return (
    <>
      <div className="h-[60px] w-full sticky top-0 flex bg-[#304164] items-center justify-between border-b-2 border-b-[#BDBDBD] z-[9999]">
        <div className="flex items-center my-auto">
          <div className="w-[65px] h-[50px] ml-[20px] my-auto">
            <img className="w-full h-full" src="/Assets/logonodes.svg"></img>
          </div>
          {/* <div className="w-[90px] h-[75px] ml-[20px]">
          <img className="w-full h-full" src="/Assets/logonodes.svg"></img>
        </div> */}
          <div className="font-[Montserrat] font-[500] text-xl text-white pl-4" style={{ fontFamily: 'Sora' }}>
            Systems <br></br> Mapper
          </div>
        </div>
        <div className="flex flex-col gap-1 px-3 text-white font-[Lexend_Deca] justify-center items-center B__font-normal">
          <span className="text-lg font-medium capitalize">{contentData?.projectName}</span>
          {contentData?.createdOn && (
            <span className="text-[#ffffff6e] text-sm">{new Date(contentData?.createdOn)?.toDateString()}</span>
          )}
        </div>
        {/* Categories in navbar (old flow) */}
        {/* {showCategories ? 
        <div className="max-w-[80%] flex items-center gap-3 categoryBar">
          {contentData?.categories?.map((category: any) => {
            return (
              <span
                className={`selectCategory px-8 pt-2 cursor-pointer text-white font-[Sora] text-lg border-x border-[#BDBDBD] border-t border-b select-none rounded-t-md ${
                  selectedCategory?.includes(category.value) ? 'bg-white text-black border-b-[#fff]' : ''
                }`}
                key={category.value}
                onClick={() => {
                  if (selectedCategory.includes(category.value)) {
                    setSelectedCategory([])
                  } else {
                    setSelectedCategory([category.value])
                  }
                }}
              >
                {category.title}
              </span>
            )
          })}
        </div> : <></> } */}
        {showCTA ? (
          <div className="flex flex-col items-center justify-center mx-5">
            <div className="flex items-center justify-center gap-4 helpButtons">
              <div
                className="z-[999] relative tooltip"
                onClick={() => {
                  setShowVideoModal(true)
                }}
              >
                {!showVideoModal && (
                  <span className={`text-sm tooltip-text tooltip-left`}>
                    {' '}
                    <span className="rotate-[45deg]"></span> Tour guide{' '}
                  </span>
                )}
                <MdPlayLesson className="text-2xl text-white cursor-pointer hover:scale-110" />
              </div>
              {contentData?.projectInfo?.faq?.length && contentData?.projectInfo?.showFaq ? <div
                className="z-[999] relative tooltip"
                onClick={() => {
                  setShowFAQModal(true)
                }}
              >
                {!showFAQModal && (
                  <span className={`text-sm tooltip-text tooltip-left`}>
                    {' '}
                    <span className="rotate-[45deg]"></span> FAQs
                  </span>
                )}
                <RiQuestionAnswerLine className="text-2xl text-white cursor-pointer hover:scale-110" />
              </div> : <></>}
              <FAQModal show={showFAQModal} handleClose={()=>{setShowFAQModal(false)}} faqs={contentData?.projectInfo?.faq} />
              <DemoVideosModal show={showVideoModal} handleClose={()=>{setShowVideoModal(false)}} />
              {/* <TourGuide go={go} setState={setState} /> */}
              <Guidelines />
              <span
                className="relative tooltip"
                onClick={() => {
                  navigate(`/${id}/feedback`)
                }}
              >
                <FeedbackIcon />
                <span className={`text-sm tooltip-text tooltip-left`}>
                  {' '}
                  <span className="rotate-[45deg]"></span> Share your feedback.{' '}
                </span>
              </span>
            </div>
            {/* <button
              className="savenexit m-1.5 mt-2 mx-5 mx text-base bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md  flex gap-2 items-center"
              onClick={() => {
                setFixed({})
                changeNode(centerNodeData, openAccordionData, 'end')
              }}
            >
              Save & Exit
            </button> */}
          </div>
        ) : (
          <></>
        )}
        {showLogoutButton && (
          <div className="flex items-center justify-center my-auto mr-10 ">
            {/* <span>Hi jissac</span> */}
            <button
              className="items-end text-white mt-auto px-4 md:px-4 py-2 md:py-3 rounded-md bg-[#b3261f] hover:bg-[#883f17] button-[Manrope]"
              onClick={logout}
            >
              <FiLogOut />
            </button>
          </div>
        )}
      </div>
      {showCategories && contentData?.categories?.length ? (
        <div className="w-full bg-white py-2.5 px-8 flex items-center gap-5 categoryBar shadow-md sticky top-[60px] z-[1000]">
          <span className="font-[Sora] px-1 text-base font-semibold text-[#304164]">View similar nodes by:</span>
          {contentData?.categories?.map((category: any) => {
            return (
              <span
                className={`selectCategory px-6 py-1.5 cursor-pointer text-[#11a8ab] font-[Sora] text-base border border-[#11a8ab] select-none rounded-md ${
                  selectedCategory?.includes(category.value) ? '!bg-[#11a8ab] !text-white' : ''
                }`}
                key={category.value}
                onClick={() => {
                  if (selectedCategory.includes(category.value)) {
                    setSelectedCategory([])
                  } else {
                    setSelectedCategory([category.value])
                  }
                }}
              >
                {category.title}
              </span>
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default Navbar
