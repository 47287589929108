import React, { FC, useEffect, useState } from 'react'
import Portal from './Portal'
import { MdClose } from 'react-icons/md'
import VideoPlayer from './VideoPlayer'

const InitialBanner: FC<{ id?: string }> = ({ id }) => {
  const [showPopup, setShowPopup] = useState(false)
  useEffect(() => {
    if (id && !localStorage[`init-banner-${id}`]) {
      setShowPopup(true)
      // localStorage.setItem(`tour-${id}`,"true")
    }
  }, [id])
  return (
    <>
      {showPopup && (
        <Portal>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-[85%] lg:min-w-[400px] max-w-[85%] md:max-w-[90%] lg:max-w-[650px] xl:max-w-[850px]">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-xl focus:outline-none ">
                {/*header*/}
                {
                  <div className="flex items-center justify-between p-4 border-b border-solid px-7 border-blueGray-200">
                    <h3 className="text-xl  lg:text-2xl font-black mb-0 text-[#5959FF] font-[Lato]">
                      {'How it works'}
                    </h3>
                    <span
                      className="text-2xl cursor-pointer"
                      onClick={() => {
                        setShowPopup(false)
                      }}
                    >
                      <MdClose></MdClose>
                    </span>
                  </div>
                }
                {/*body*/}
                <div className="relative flex-auto max-h-[80vh] overflow-y-auto">
                  <div className="p-6">
                    <VideoPlayer video={{ src: '/Assets/tutorial.mp4' }} thumbnail={{ src: '/Assets/smt.jpeg' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-[999] bg-black"></div>
        </Portal>
      )}
    </>
  )
}

export default InitialBanner
