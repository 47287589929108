import React, { useState, useEffect, FC, useContext,useRef } from 'react'
import Input from '../Input'
import { SelectInput } from '../Input'
import user from '../../services/user.api'
import { LoginContext } from '../../context/LoginContext'
import { useNavigate } from 'react-router-dom'
import Toaster, { ToasterRef } from  '../Toaster/Toaster'

type SignInProps = { setTab: React.Dispatch<React.SetStateAction<string>> }
const SignIn: FC<SignInProps> = ({ setTab }) => {
  const navigate = useNavigate()
  const { verifyUser, loggedIn } = useContext(LoginContext)
  useEffect(() => {
    if (loggedIn) {
      navigate("/admin")
    }
  }, [loggedIn])
  const [fields, setFields] = useState<Array<any>>([
    { type: 'text', title: 'UserName / Email', value: '', name: 'userName' },
    { type: 'password', title: 'Password', value: '', name: 'password' },
  ])
  const [userDetails, setUserDetails] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const toasterRef = useRef<any>(null)
  const handleInputChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const handleSelectChange = (e: any) => {
    setErrors({})
    let tempuser = { ...userDetails }
    tempuser[e.target.name] = e.target.value
    setUserDetails({ ...tempuser })
  }
  const signIn = (e: any) => {
    e.preventDefault()
    if (validateUserDetails()) {
      user
        .loginUser({ userName: userDetails.userName, password: userDetails.password })
        .then((res: any) => {
          if (res.data.data) {
            localStorage.setItem('token', res.data.data)
            verifyUser()
            window.location.reload();
          } else if (res.data.error) {
            if(toasterRef.current) toasterRef.current.showToast(res.data.error, 'error')
            setErrors({ password: res.error })
          }
        })
        .catch((err) => {
          setErrors({
            ...errors,
            password: "UserName or Password didn't matched!",
          })
        })
      // setSurveyData((t: any) => {
      //   return { ...t, ...userDetails }
      // })
      // navigate(`/${id}/tutorial`)
    }
  }
  const validateUserDetails = (): boolean => {
    let status = true
    if (!userDetails.userName) {
      status = false
      setErrors({ email: 'Please enter your Username!' })
    }
    if (!userDetails.password) {
      status = false
      setErrors({ password: 'Please enter your Password!' })
    }
    return status
  }
  return (
    <div>
      <div className="mt-8 text-lg font-medium" style={{ fontFamily: 'Sora' }}>
        Hello! let's get started
      </div>
      <Toaster ref={toasterRef} />
      <h6 className="font-light text-gray-600">Sign in to continue.</h6>
      <form className="pt-4 bg-transparent">
        {fields.map((field, index) => {
          if (field.type === 'text' || field.type === 'password') {
            return (
              <div className="my-6">
                <Input
                  type={field.type}
                  title={field.title}
                  defaultValue={field.value}
                  name={field.name}
                  onChange={handleInputChange}
                  inputClassName={''}
                  labelClassName={'bg-[#fff]'}
                  value={userDetails[field.name]}
                  error={errors[field.name]}
                />
              </div>
            )
          } else {
            return (
              <div className="w-full my-3">
                <SelectInput
                  title={field.title}
                  name={field.name}
                  options={field.options}
                  error={errors[field.name]}
                  handleChange={handleSelectChange}
                  labelClass={'bg-[#fff]'}
                  optionsClass={'bg-[#d2e4d8]'}
                ></SelectInput>
              </div>
            )
          }
        })}
        <div className="mt-3">
          <button
            className="text-white px-6 md:px-10 py-3 md:py-5 rounded-md bg-[#1f3BB3] hover:bg-[#172D88] button-[Manrope]"
            onClick={signIn}
          >
            SIGN IN
          </button>
        </div>
        <div className="flex items-center justify-between my-2">
          <div className="text-base font-normal">
            <label className="flex items-center gap-3 text-muted">
              <input type="checkbox" className="pl-3" />
              Keep me signed in
            </label>
          </div>
          <span
            className="text-black underline cursor-pointer"
            onClick={() => {
              setTab('forgotpassword')
            }}
          >
            Forgot password?
          </span>
        </div>
        <div className="mt-4 font-light text-center">
          Don't have an account?{' '}
          <span
            className="text-blue-500 underline cursor-pointer text-primary"
            onClick={() => {
              // setTab('signup')
            }}
          >
            Register
          </span>
        </div>
      </form>
    </div>
  )
}

export default SignIn
