import React from 'react'
import Navbar from '../../components/Navbar'
import { Helmet } from 'react-helmet'
import Statistics from '../../components/Statistics'
import { useParams } from 'react-router-dom'

const StatisticsPage = () => {
    const {id} = useParams()
  return (
    <div>
        <Navbar {...{ showCategories: false, showLogoutButton: false, }} />
      <div className="flex w-full h-full">
        <Helmet>
          <meta charSet="utf-8" />
          <title> System Mapper Tool | Admin </title>
        </Helmet>
        {id && <Statistics projectId={id} filter={{}} />}
    </div>
    </div>
  )
}

export default StatisticsPage