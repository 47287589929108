import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { MdArrowRight, MdDelete } from 'react-icons/md'
import InputSwitch from './Input/InputSwitch'
import { ConnectionType, NodeType } from '../pages/Tool/Tool.types'
import { ratingConstants } from '../lib/constants'

const ConnectionList: FC<{ showPopup: boolean; handleClose: () => void; nodes?: Array<NodeType>, setContentData?:any }> = ({
  showPopup,
  handleClose,
  nodes,
  setContentData
}) => {
    const [nodeSchema, setNodeSchema] = useState<{ [key: string]: string } >({})
    const [nodesCopy, setNodesCopy] = useState<Array<NodeType> >([])
    const [subNodeSchema, setsubNodeSchema] = useState<{ [key: string]: string } >({})
  useEffect(()=>{
    if(nodes){
        setNodeSchema(nodes?.reduce((res: { [key: string]: string }, node) => {
            if (node?.id) res[node?.id] = node?.title || ""
            return res
          }, {}) || {})
        setsubNodeSchema(nodes?.reduce((res: {[key:string] : string}, node)=>{
            node?.nodes?.forEach((subNode, index)=>{
               if(subNode?.id) res[subNode.id] = subNode?.title || ""
            })
            return res
        }, {}))
        setNodesCopy(JSON.parse(JSON.stringify(nodes)))
    }
  }, [nodes])
  const getConnectionRating = (value : any) =>{
    return Math.abs( (value) &&
    !ratingConstants.BALANCING_REGEX.test(value)  &&
    !ratingConstants.REINFORCING_REGEX.test(value) &&
      parseFloat(value)) ||
      0
  }
  const checkConnectionType = (value: any) =>{
    return isNaN(parseFloat(value)) ? ratingConstants.REINFORCING_REGEX.test(value) : parseFloat(value) >0 
  }
  const add = (nodeIndex: number, subnodeIndex: number, connectionIndex: number) => () => {
    setNodesCopy((t) => {
      let temp: Array<NodeType> = JSON.parse(JSON.stringify(t))
      let value = temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]?.value
      let updatedValue = value
      if (value) {
        if (typeof value == 'string') {
          if (ratingConstants.BALANCING_REGEX.test(value)) {
            updatedValue = -0.1
          } else {
            updatedValue = 0.1
          }
        } else {
          let isPositiveValue = value > 0
          if (Math.abs(value) >= 0 && Math.abs(value) <= 0.9) {
            updatedValue = (isPositiveValue ? 1 : -1)*(Math.abs(value)*10 + 1)/10
          } 
        }
      }
      if (temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]){
        (temp as any)[nodeIndex].nodes[subnodeIndex].connections[connectionIndex].value = updatedValue
      }
      return temp
    })
  }
  const substract = (nodeIndex: number, subnodeIndex: number, connectionIndex: number) => () => {
    setNodesCopy((t) => {
      let temp: Array<NodeType> = JSON.parse(JSON.stringify(t))
      let value = temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]?.value
      let updatedValue = value
      if (value) {
        if (typeof value == 'string') {
          if (ratingConstants.BALANCING_REGEX.test(value)) {
            // updatedValue = -0.1
          } else {
            // updatedValue = 0.1
          }
        } else {
          let isPositiveValue = value > 0
          if (Math.abs(value) > 0.1 && Math.abs(value) <= 1) {
            updatedValue = (isPositiveValue?1:-1)*(Math.abs(value)*10 - 1)/10
          }
        }
      }
      if (temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]){
        (temp as any)[nodeIndex].nodes[subnodeIndex].connections[connectionIndex].value = updatedValue}
      return temp
    })
  }
  const toggleSwitch = (nodeIndex: number, subnodeIndex: number, connectionIndex: number) => {
    setNodesCopy((t) => {
      let temp: Array<NodeType> = JSON.parse(JSON.stringify(t))
      let value = temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]?.value
      let updatedValue = value
      if (value) {
        if (typeof value == 'string') {
          if (ratingConstants.BALANCING_REGEX.test(value)) {
            updatedValue = 'r'
          } else {
            updatedValue = 'b'
          }
        } else {
          updatedValue = value * -1
        }
      }
      if (temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]){
        (temp as any)[nodeIndex].nodes[subnodeIndex].connections[connectionIndex].value = updatedValue
      }
      return temp
    })
  }
  const handleInputChange = (nodeIndex: number, subnodeIndex: number, connectionIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) =>{
    setNodesCopy((t) => {
      let temp: Array<NodeType> = JSON.parse(JSON.stringify(t))
      if (temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]){
        (temp as any)[nodeIndex].nodes[subnodeIndex].connections[connectionIndex].notes = e.target.value
      }
      return temp
    })
  }
  const handleDelete = (nodeIndex: number, subnodeIndex: number, connectionIndex: number) => () =>{
    setNodesCopy((t) => {
      let temp: Array<NodeType> = JSON.parse(JSON.stringify(t))
      let value = temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]?.deleted
      if (temp[nodeIndex]?.nodes?.[subnodeIndex]?.connections?.[connectionIndex]){
        (temp as any)[nodeIndex].nodes[subnodeIndex].connections[connectionIndex].deleted = !value
      }
      return temp
    })
  }
  const handleSave = () =>{
    setContentData((t:any)=>({...t, nodes: nodesCopy?.map((node, index)=>({
      ...node, nodes:node?.nodes?.map((subnode, i)=>({
        ...subnode,
        connections : subnode?.connections?.reduce((res:ConnectionType[], connection)=>{
          if(!connection?.deleted){
            res.push(connection) 
          }
          return res
        },[])
      }))
    }))}))
    handleClose()
  }
  
  return (
    <>
      <div
        className={`fixed right-0 h-full top-0 transition-[width] duration-300 overflow-hidden z-[10010] bg-white shadow-lg ${
          showPopup ? 'lg:w-[900px] xl:w-[950px] 2xl:w-[65vw]' : 'w-0'
        }`}
      >
        <div className="w-full flex justify-between gap-3 items-center px-5 py-2.5 font-[Sora] text-base font-semibold bg-[#304164] text-white">
          <span>All connections</span>
          <span
            className="text-xl cursor-pointer"
            onClick={() => {
              handleClose()
            }}
          >
            <IoCloseCircleOutline />
          </span>
        </div>
        <div className="px-10 overflow-y-auto h-[calc(100vh-100px)] hidescrollbar">
          {nodesCopy?.map((node, nodeIndex) => {
            return node?.nodes?.map((subnode, subnodeIndex) => {
              return subnode?.connections?.map((connection, index) => {
                return (
                  <div
                    className="flex items-center justify-between gap-4 py-5 border-b border-[#bdbdbd] w-full"
                    key={index}
                  >
                    <div className={`flex flex-col items-start justify-center flex-1 w-auto gap-5 ${connection?.deleted ? "opacity-50" :""}`}>
                      <div className="flex items-start justify-start w-full gap-6 xl:gap-10">
                        <div className="flex items-center justify-start flex-1 w-1/2 gap-2 ">
                          <div className="flex flex-col items-start gap-3 font-normal">
                            <span className="text-[#304264] font-lexend-deca max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                              {node?.title}
                            </span>
                            <span className="text-white px-5 font-[Sora] py-1.5 rounded-md bg-[#1f253d] max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                              {subnode?.title}
                            </span>
                          </div>
                          <div className={`relative border-b-[2px]  w-auto flex-1 h-[1px] mt-auto mb-5 ${checkConnectionType(connection?.value || 0) ? "border-[#000000]" : "border-red-600 border-dashed"}`}>
                            <div className="absolute left-1/2 -translate-x-1/2 top-0 -translate-y-1/2 bg-[color:var(--background-button)] text-white text-xs rounded-full p-1 w-6 h-6 flex items-center justify-center font-semibold">
                              {getConnectionRating(connection?.value || 0)}
                            </div>
                            <div className={`absolute right-0 z-10 top-[1px] -translate-y-1/2 translate-x-1/2 text-xl ${checkConnectionType(connection?.value || 0) ? "text-[#000000]" : "text-red-600"}`}><MdArrowRight className='text-2xl'  /> </div>
                          </div>
                          <div className="flex flex-col items-start gap-3 font-normal">
                            <span className="text-[#304264] font-normal font-lexend-deca max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                              {nodeSchema[connection?.id?.split('|')[0]?.slice(1)]}
                            </span>
                            <span className="text-white px-5 py-1.5 font-[Sora] rounded-md bg-[#1f253d] max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                              {subNodeSchema[connection?.id]}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-3.5 font-normal">
                          <span className="text-[#304264] font-lexend-deca ">Rating</span>
                          <div className="flex items-center justify-start gap-2">
                            <button className="w-6 h-6 flex items-center justify-center border border-[#d9d9d9] rounded-md font-semibold" 
                            onClick={substract(nodeIndex, subnodeIndex, index)}
                            >
                              -
                            </button>
                            <div className="bg-[color:var(--background-button)] text-white text-xs rounded-full p-1 w-6 h-6 flex items-center justify-center font-semibold">
                            {getConnectionRating(connection?.value || 0)}
                            </div>
                            <button className="w-6 h-6 flex items-center justify-center border border-[#d9d9d9] rounded-md font-semibold" onClick={add(nodeIndex, subnodeIndex, index)}>
                              +
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-3.5 font-normal">
                          <span className="text-[#304264] font-lexend-deca ">Connection Type</span>
                          <div className="flex items-center justify-start gap-2">
                            <span className={`text-[#304264] font-lexend-deca ${!checkConnectionType(connection?.value || 0) ? "opacity-50" : ""}`}>Reinforcing</span>
                            <InputSwitch value={!checkConnectionType(connection?.value || 0)} name={`connection-${nodeIndex}-${subnodeIndex}-${index}`} onChange={()=>{toggleSwitch(nodeIndex, subnodeIndex, index)}} />
                            <span className={`text-[#304264] font-lexend-deca ${checkConnectionType(connection?.value || 0) ? "opacity-50" : ""}`}>Balancing</span>
                          </div>
                        </div>
                      </div>
                      <input
                        className="rounded-md border border-[#bdbdbd] p-2 font-normal font-[Sora] text-sm w-full"
                        placeholder="Note : Enter notes here."
                        name="notes"
                        onChange={handleInputChange(nodeIndex, subnodeIndex, index)}
                      ></input>
                    </div>
                    <div className="text-[color:var(--background-button)] text-2xl cursor-pointer w-[40px] " onClick={handleDelete(nodeIndex, subnodeIndex, index)}>
                      {connection?.deleted ? <span className='px-2 py-1 text-sm font-bold text-white rounded-md bg-slate-400'>undo</span> :<MdDelete className="ml-auto" />}
                    </div>
                  </div>
                )
              })
            })
          })}
          {/* {Array(3).fill(1)?.map((connection, index)=>{
                return (
                  <div
                    className="flex items-center justify-between gap-4 py-5 border-b border-[#bdbdbd] w-full"
                    key={index}
                  >
                    <div className="flex flex-col items-start justify-center flex-1 w-auto gap-5">
                      <div className="flex items-start justify-start w-full gap-6 xl:gap-10">
                        <div className="flex items-center justify-start flex-1 w-1/2 gap-2 ">
                          <div className="flex flex-col items-start gap-3 font-normal">
                            <span className="text-[#304264] font-lexend-deca ">Climate hazards and risks</span>
                            <span className="text-white px-5 font-[Sora] py-1.5 rounded-md bg-[#1f253d]">
                              Climate risks
                            </span>
                          </div>
                          <div className="relative border-b border-[#000000] w-auto flex-1 h-[1px] mt-auto mb-5">
                            <div className="absolute left-1/2 -translate-x-1/2 top-0 -translate-y-1/2 bg-[color:var(--background-button)] text-white text-xs rounded-full p-1">
                              0.5
                            </div>
                          </div>
                          <div className="flex flex-col items-start gap-3 font-normal">
                            <span className="text-[#304264] font-normal font-lexend-deca">
                              Climate hazards and risks
                            </span>
                            <span className="text-white px-5 py-1.5 font-[Sora] rounded-md bg-[#1f253d]">
                              Climate risks
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-3.5 font-normal">
                          <span className="text-[#304264] font-lexend-deca ">Rating</span>
                          <div className="flex items-center justify-start gap-2">
                            <span className="w-6 h-6 flex items-center justify-center border border-[#d9d9d9] rounded-md">
                              -
                            </span>
                            <div className="bg-[color:var(--background-button)] text-white text-xs rounded-full p-1">
                              0.5
                            </div>
                            <span className="w-6 h-6 flex items-center justify-center border border-[#d9d9d9] rounded-md">
                              +
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-3.5 font-normal">
                          <span className="text-[#304264] font-lexend-deca ">Connection Type</span>
                          <div className="flex items-center justify-start gap-2">
                            <span className={`text-[#304264] font-lexend-deca`}>Reinforcing</span>
                            <InputSwitch value={false} name="connection" onChange={()=>{}} />
                            <span className={`text-[#304264] font-lexend-deca opacity-50`}>Balancing</span>
                          </div>
                        </div>
                      </div>
                      <input
                        className="rounded-md border border-[#bdbdbd] p-2 font-normal font-[Sora] text-sm w-full"
                        placeholder="Note : Enter notes here."
                        name="notes"
                        onChange={(e) => {}}
                      ></input>
                    </div>
                    <div className="text-[color:var(--background-button)] text-2xl cursor-pointer w-[40px] ">
                      <MdDelete className='ml-auto' />
                    </div>
                  </div>
                )
            })} */}
        </div>
        <div className="absolute bottom-0 flex items-center justify-end w-full gap-2 px-10 py-4 bg-white border-t border-slate-300">
          <button className="text-sm bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md  flex gap-2 items-center" onClick={handleSave}>
            Save Changes
          </button>
        </div>
      </div>
      {showPopup && <div className="fixed w-full h-full bg-[#000000B2] z-[10000] top-0"></div>}
    </>
  )
}

export default ConnectionList
