import React, { FC, useState } from 'react'
import { BsPlayCircle } from 'react-icons/bs'

const VideoPlayer: FC<{ video: { src: string }; thumbnail?: { src: string } }> = ({ video, thumbnail }) => {
  const [play, setPlay] = useState<boolean>(false)
  return (
    <>
      {' '}
      {!play && thumbnail?.src ? ( <div className="relative w-full h-full mx-auto my-auto cursor-pointer" onClick={() => setPlay(true)}>
          <div className="w-full h-full flex justify-center items-center absolute bg-gradient-to-b from-[#1e1d1c83] to-[#1e1d1c83]">
            <BsPlayCircle className="w-12 h-12 fill-[#e5e5e5] hover:fill-[#fff]"></BsPlayCircle>
          </div>
          <img
            src={thumbnail?.src}
            className="object-cover object-center w-full h-full transition-all duration-300 ease-in-out"
          ></img>
        </div>
      ) : (
        <div className="w-full h-full mx-auto my-auto transition-all duration-300 ease-in-out">
          <video src={video?.src} className="w-full h-full" autoPlay controls />
        </div>
      )}
    </>
  )
}

export default VideoPlayer
