import React from 'react'

const HelpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="24"
      height="24"
      viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
      className='fill-[#fff] cursor-pointer hover:scale-110'
    >
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="inherit" stroke="none">
        <path d="M714 871 c-30 -13 -64 -59 -64 -87 0 -21 5 -24 39 -24 30 0 40 4 44 20 6 24 34 37 49 22 18 -18 13 -70 -9 -95 -27 -29 -53 -81 -53 -107 0 -17 6 -20 40 -20 35 0 40 3 40 23 0 13 17 43 38 67 33 39 37 50 37 97 0 43 -5 57 -26 79 -29 29 -97 41 -135 25z" />
        <path d="M335 706 c-101 -44 -125 -178 -46 -257 65 -65 157 -65 222 0 124 124 -15 327 -176 257z" />
        <path d="M733 522 c-24 -15 -20 -69 7 -77 34 -11 60 4 60 34 0 42 -34 64 -67 43z" />
        <path d="M282 305 c-74 -21 -108 -40 -158 -91 -39 -39 -44 -49 -44 -89 l0 -45 320 0 320 0 0 45 c0 41 -5 51 -48 94 -63 63 -138 92 -252 97 -56 3 -103 -1 -138 -11z" />
      </g>
    </svg>
  )
}

export default HelpIcon
