import client from './client'
type surveyType = {
  name: string
  gender: string
  ageGroup: string
  area: string
  location: string
  contentId: number
  createdOn: string
  modifiedOn: string
  nodes: any
  status: boolean
}

class SurveyServices {
  getAllSurveys() {
    return client.get('/survey/')
  }
  getSurveyById(id: number) {
    return client.get(`/survey/${id}`)
  }
  addSurvey(body: surveyType) {
    return client.post('/survey/', body)
  }
  updateSurvey(id: number, body: surveyType) {
    return client.put(`/survey/${id}`, body)
  }
  deleteSurvey(id: number) {
    return client.delete(`/survey/${id}`)
  }
  getAggregatedData(body:any) {
    console.log(body)
    return client.post(`/survey/aggregate/data`, body)
  }
}
let survey = new SurveyServices()
export default survey
