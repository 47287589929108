import React, { useState, useEffect } from 'react'
import content from '../services/content.api'
import Input, { SelectInput } from './Input'
import { MdDelete } from 'react-icons/md'
import { AiTwotoneEdit } from 'react-icons/ai'
import { CgArrowLongLeft } from 'react-icons/cg'

const CategoriesSelection = ({ distinctContents, selectedProject, setSelectedProject, setEditType }: any) => {
  const [loading, setLoading] = useState(false)
  const [contentData, setContentData] = useState<any>(null)
  const [addedCateories, setAddedCategories] = useState<any>([])
  // const [selectedProject, setSelectedProject] = useState('')
  const [categoryInput, setCategoryInput] = useState('')
  const [isEditting, setIsEditting] = useState('')
  const [allProjects, setAllProjects] = useState<any>([])
  const [changeThere, setChangeThere] = useState(false)

  // const id = 11023
  const getContent = (id: string) => {
    if (!loading) {
      setLoading(true)
      content
        .getContentById(id)
        .then((res) => {
          setContentData(res.data)
          console.log('res.data', res.data)
          if(res.data.categories) setAddedCategories(res.data.categories)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }
  console.log(contentData)
  useEffect(() => {
    if (selectedProject !== 'Project') {
      setLoading(false)
      getContent(selectedProject.toString())
    }
  }, [selectedProject])
  // handle category selection of subnode
  const handleSelectChange = (nodeId: string) => (e: any) => {
    // setSelectedData((t: any) => {
    //   return { ...t, [e.target.name]: e.target.value }
    // })
    const selectedNode = contentData.nodes.find((node: any) => node.id === nodeId)
    selectedNode.category = addedCateories?.find((cats: any) => cats.value === e.target.value)
    setContentData((content: any) => ({
      ...content,
      nodes: content.nodes.map((node: any) => (node.id === nodeId ? selectedNode : node)),
    }))
    setChangeThere(true)
  }

  // Update content to db.
  const updateContent = () => {
    content.updateContent(selectedProject, { ...contentData, categories: addedCateories }).then((res) => {
      console.log('res', res)
    })
  }

  // handle change of category input
  const handleInputChange = (e: any) => {
    setCategoryInput(e.target.value)
  }
  // on add category button
  const handleCategorySave = () => {
    // setSelectedProject(e.target.value);
    if (isEditting && isEditting?.length) {
      setAddedCategories((cats: any) => [
        ...cats.filter((cat: any) => cat.value !== isEditting),
        { value: isEditting, title: categoryInput },
      ])
    } else {
      setAddedCategories((cats: any) => [...cats, { value: (cats.length + 100).toString(), title: categoryInput }])
    }
    setCategoryInput('')
    setIsEditting('')
    setChangeThere(true)
  }
  const handleEditAddedCat = (value: string) => (e: any) => {
    setIsEditting(value)
    setCategoryInput(addedCateories?.find((cat: any) => cat.value === value)?.title || '')
  }
  const handleDeleteAddedCat = (value: string) => (e: any) => {
    setAddedCategories((cats: any) => [...cats.filter((cat: any) => cat.value !== value)])
    setChangeThere(true)
  }
  return (
    <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
      <div
        className="flex items-center justify-between gap-4 mb-[30px] cursor-pointer"
        onClick={() => {setEditType("") ;setSelectedProject('')}}
      >
        <div className="flex items-center justify-start gap-4">
          {' '}
          <span className="text-xl">
            <CgArrowLongLeft />
          </span>
          <span className="text-base">Back to Projects</span>
        </div>
       {changeThere && <button className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center "
       onClick={updateContent}
       >
          {' '}
          Save
        </button>}
      </div>
      <div className="w-full">
        <table className="text-left">
          <thead className="!bg-[#304164] text-base text-white" style={{ fontFamily: 'Lexend Deca' }}>
            <tr className="!bg-[#304164] text-left">
              <td className="px-4 text-left min-w-[100px]">Id</td>
              <td className="px-4 text-left min-w-[350px]">Category</td>
              <td className="px-4 text-left">Action</td>
            </tr>
          </thead>
          <tbody>
            {!addedCateories?.length && (
              <tr>
                <td colSpan={3} className="mx-auto text-center">
                  {' '}
                  No Categories added
                </td>
              </tr>
            )}
            {addedCateories?.map((cat: any, index: number) => {
              return (
                <tr key={index}>
                  <td className="px-4 text-left"> {cat.value}</td>
                  <td className="px-6 text-left"> {cat.title}</td>
                  <td className="px-3">
                    <div className="flex gap-3 items center">
                      <span className="cursor-pointer hover:scale-110" onClick={handleEditAddedCat(cat.value)}>
                        <AiTwotoneEdit />
                      </span>
                      <span className="cursor-pointer hover:scale-110" onClick={handleDeleteAddedCat(cat.value)}>
                        <MdDelete />{' '}
                      </span>
                    </div>
                  </td>
                </tr>
              )
            })}
            <tr>
              <td colSpan={3}>
                <div className="flex items-center justify-end gap-3">
                  <div className="my-3">
                    <Input
                      type={'text'}
                      title={'Category Name'}
                      defaultValue={categoryInput}
                      name={'category'}
                      onChange={handleInputChange}
                      inputClassName={'text-base py-3'}
                      labelClassName={'bg-[#f3f7ff] text-base'}
                      value={categoryInput}
                      error={''}
                    />
                  </div>

                  <button
                    className="rounded-md bg-button text-button bg-[color:var(--background-button)] font-normal text-white px-4 py-3"
                    onClick={handleCategorySave}
                  >
                    {isEditting ? 'Update Category' : 'Add Category'}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        <>
          <div className="flex items-center justify-start my-4">{contentData?.projectName}</div>
          <div className="w-full">
            {!loading && contentData && (
              <table className="w-full text-left">
                <thead className="!bg-[#304164] text-base text-white" style={{ fontFamily: 'Lexend Deca' }}>
                  <tr className="!bg-[#304164] text-left">
                    <td className="px-4 text-left min-w-[100px]">Id</td>
                    <td className="px-4 text-left">Node</td>
                    <td className="px-4 text-left">Category</td>
                  </tr>
                </thead>
                <tbody>
                  {contentData?.nodes?.map((node: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="px-4 text-left"> {node.id}</td>
                        <td className="px-6 text-left"> {node.title}</td>
                        <td className="text-left">
                          {' '}
                          {/* {node?.category} */}
                          <div className="w-full">
                            <SelectInput
                              title={'title'}
                              name={'name'}
                              options={addedCateories}
                              handleChange={handleSelectChange(node.id)}
                              labelClass={'bg-[#d2e4d8] text-base'}
                              optionsClass={'bg-[#d2e4d8] text-base'}
                              defaultValue={node.category?.value}
                            ></SelectInput>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </>
      }
    </div>
  )
}

export default CategoriesSelection
