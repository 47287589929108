import React, { useState } from 'react'
import { HelpIcon } from './Icons'
import { Accordion, AccordionItem } from './Accordion'

const Guidelines = () => {
  const [showGuidelines, setShowGuidelines] = useState<boolean>(false) // modal toggle
  return (
    <div className="relative z-[9999]">
      {showGuidelines && (
        <>
          <div className="bg-[#fff] text-[#000] font-[Ubuntu] z-[999] rounded-md absolute right-10 top-0 w-[350px] flex flex-col mx-2 justify-center text-[20px] max-w-[600px]">
            <div className="w-[12px] h-[12px] rotate-[45deg] absolute right-[-4px] top-[10px] bg-white"></div>
            <div className="relative p-2">
              <Accordion>
                <AccordionItem title="Rating Scale">
                  <div className="p-2 mb-3 text-base">
                    <h2 className="mb-4 text-lg font-medium">Rating Scale</h2>
                    <div className="flex items-center justify-center w-[200px]">
                      <div className="w-1/2 px-3 py-1 border border-[#3e4045]">0.1 - 0.3 </div>
                      <div className="w-1/2 px-3 py-1 border border-[#3e4045]">Low</div>
                    </div>
                    <div className="flex items-center justify-center w-[200px]">
                      <div className="w-1/2 px-3 py-1 border border-[#3e4045]">0.4 - 0.6 </div>
                      <div className="w-1/2 px-3 py-1 border border-[#3e4045]">Medium</div>
                    </div>
                    <div className="flex items-center justify-center w-[200px]">
                      <div className="w-1/2 px-3 py-1 border border-[#3e4045]">0.7 - 1.0 </div>
                      <div className="w-1/2 px-3 py-1 border border-[#3e4045]">High</div>
                    </div>
                  </div>
                </AccordionItem>
                <AccordionItem title="Types of connections">
                  <div className="p-2 mb-3 text-base">
                    <h2 className="mb-3 text-lg font-medium">Types of connections</h2>
                    <div className="flex items-center justify-center w-auto">
                      <span className="flex items-center justify-center w-5 h-5 text-sm text-white bg-gray-600 rounded-full">
                        1
                      </span>
                      <div className="w-1/2 px-3 py-1 ">Reinforcing/directly proportional</div>
                      <div className="w-1/2 px-3 py-1 ">
                        <span className="flex items-center h-full">
                          <span className="invisible">:</span>
                          <div className="h-[1px] w-full border-b-2 border-[#2E2E2E]"></div>
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-center w-auto">
                      <span className="flex items-center justify-center w-5 h-5 text-sm text-white bg-gray-600 rounded-full">
                        2
                      </span>
                      <div className="w-1/2 px-3 py-1 ">Balancing/indirectly proportional </div>
                      <div className="w-1/2 px-3 py-1 ">
                        <span className="flex items-center h-full">
                          <span className="invisible">:</span>
                          <div className="h-[1px] w-full border-dashed border-b-2 border-[#ff3e3e]"></div>
                        </span>
                      </div>
                    </div>
                  </div>
                </AccordionItem>
                <AccordionItem title="Guiding questions">
                  <div className="p-2 mb-3 text-base">
                    <h2 className="py-2 my-1 text-lg font-medium border-b rounded-md border-slate-300">
                      Guiding questions
                    </h2>
                    <ul className="">
                      <li className="flex items-start gap-3 py-2 border-b rounded-md border-slate-300">
                        <span className="flex items-center justify-center w-5 h-5 my-1 text-sm text-white bg-gray-600 rounded-full">
                          1
                        </span>
                        <span className="w-[90%]">
                          {' '}
                          What do you think about the selected sub-nodes and their relationship?{' '}
                        </span>
                      </li>
                      <li className="flex items-start gap-3 py-2 border-b rounded-md border-slate-300">
                        <span className="flex items-center justify-center w-5 h-5 my-1 text-sm text-white bg-gray-600 rounded-full">
                          2
                        </span>
                        <span className="w-[90%]">According to you, how important is the connection? </span>
                      </li>
                      <li className="flex items-start gap-3 py-2 border-b rounded-md border-slate-300">
                        <span className="flex items-center justify-center w-5 h-5 my-1 text-sm text-white bg-gray-600 rounded-full">
                          3
                        </span>
                        <span className="w-[90%]">
                          Do you think the impact of the selected sub-node is relevant to the other one? If yes, is it
                          low, medium or high?
                        </span>
                      </li>
                    </ul>
                  </div>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div
            className="w-full h-full bg-[#0000008f] fixed left-0 top-0 z-[990] flex
          justify-center items-center"
            onClick={() => {
              setShowGuidelines(false)
            }}
          ></div>
        </>
      )}
      <div className="z-[999] relative tooltip" onClick={() => setShowGuidelines((e) => !e)}>
        {!showGuidelines && (
          <span className={`text-sm tooltip-text tooltip-left`}>
            {' '}
            <span className="rotate-[45deg]"></span> Need help?{' '}
          </span>
        )}
        <HelpIcon />
      </div>
    </div>
  )
}

export default Guidelines
