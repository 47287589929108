import React, {useRef} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { EffectFade, Navigation, Lazy } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import Particles from "../../Particles"

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Slider = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
  return (
    <div className="main-banner header-text relative" id="top">
      {/* <Particles /> */}
        {/* <div className="absolute z-10 flex items-center w-full h-full gap-2 top-1/2">
          <button
            className="absolute left-0 p-2 text-white border rounded-md disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-700 bg-slate-600 border-slate-300"
            ref={prevRef}
          >
            <BsChevronLeft></BsChevronLeft>
          </button>
          <button
            className="absolute right-0 p-2 text-white border rounded-md disabled:cursor-not-allowed disabled:bg-slate-200 disabled:text-slate-700 bg-slate-600 border-slate-300"
            ref={nextRef}
          >
            <BsChevronRight></BsChevronRight>
          </button>
        </div> */}
      <div className="Modern-Slider overflow-hidden">
        <Swiper
          // modules={[Navigation, Pagination, A11y]}
          // spaceBetween={20}
          // slidesPerView={1}
          // navigation={{
          //   nextEl: prevRef.current,
          //   prevEl: nextRef.current,
          // }}
          // onBeforeInit={(swiper :any) => {
          //   if(swiper.params?.navigation?.prevEl && swiper.params?.navigation?.nextEl){
          //       swiper.params.navigation.prevEl = prevRef.current
          //       swiper.params.navigation.nextEl = nextRef.current
          //   }
          // }}
          // pagination={{ clickable: true }}
          modules={[Navigation, EffectFade]}
          navigation
          // navigation={{  nextEl: prevRef.current,
          //   prevEl: nextRef.current, }}
            slidesPerView={1}
            // onSlideChange={onSlideChange}
            // onSwiper={(swiper) => console.log(swiper)}
          // loop={infinite}
          // effect={effect}
          observer={true}
          observeParents={true}
        //   onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <div className="item">
            <Particles />
              <div className="img-fill">
                <img src="Assets/images/slide-01.jpg" className='w-full h-full' alt="" />
                <div className="max-w-3xl text-white text-content">
                  <h5 className=''>Unlock the power of systems mapping for your research.</h5>
                  <p className='!font-medium text-lg leading-7'>With Systems Mapper, you can easily create comprehensive systems mapping surveys to gather valuable perception-based information from your stakeholders. Harness the power of fuzzy cognitive knowledge to enhance your research.</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item">
            {/* <Particles />  */}
              <div className="img-fill">
                <img src="Assets/images/slide-02.jpg" alt="" className='w-full h-full' />
                <div className="max-w-3xl text-white text-content">
                  <h5 className=''>Unlock valuable insights with interactive systems mapping.</h5>
                  <p className='!font-medium text-lg leading-7'>Gain a deeper understanding of your research topic by allowing participants to create connections and rate the importance of those connections. With Systems Mapper, unlock valuable insights and procure perception-based information from your stakeholders.</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="item">
            {/* <Particles /> */}
              <div className="img-fill">
                <img src="Assets/images/slide-03.jpg" alt="" className='w-full h-full' />
                <div className="max-w-3xl text-white text-content">
                  <h5 className=''>Unlock valuable insights with participant-driven data.</h5>
                  <p className='!font-medium text-lg leading-7'>Don't rely solely on subjective opinions. With Systems Mapper, obtain semi-quantitative information directly from your participants. Unlock valuable insights and make data-driven decisions for your research topic.</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div className='asolute left-0 top-1/2 z-10' ref={prevRef}>Prev</div> */}
      {/* <div className='asolute right-0 top-1/2 z-10' ref={nextRef}>Next</div> */}
    </div>
  )
}

export default Slider
