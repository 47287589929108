import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Navigator: FC<{ to?: string; delay: number }> = ({ to, delay = 0 }) => {
  const navigate = useNavigate()
  useEffect(() => {
    let timeout = setTimeout(() => {
      navigate(to || '')
    }, delay)
    return () => clearTimeout(timeout)
  }, [to])
  return <div></div>
}

export default Navigator
