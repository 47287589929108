import React, { FC } from 'react'

const InputSwitch: FC<{
  name: string
  onChange: any
  inputClassName?: string
  switchClassName?: string
  containerClassName?: string
  value?: boolean
  disabled?: boolean
}> = ({ name, onChange, inputClassName, value, disabled, switchClassName, containerClassName }) => {
  return (
    <div className={`flex items-center justify-center w-full ${containerClassName? containerClassName : ""}`}>
      <label htmlFor={name || 'toggle'} className="flex items-center cursor-pointer">
        <input
          type="checkbox"
          id={name || 'toggle'}
          name={name || 'toggle'}
          className={`sr-only peer ${inputClassName ? inputClassName : ''}`}
          checked={value}
          disabled={disabled}
          onChange={onChange}
        />
        <div
          className={`relative block w-11 p-0.5 bg-[#304164] rounded-full h-6 before:absolute before:bg-white before:w-5 before:h-5 before:p-0 before:rounded-full before:transition-all before:duration-500 before:left-0.5 peer-checked:before:left-[20px] peer-checked:before:bg-white ${
            switchClassName ? switchClassName : ''
          }`}
        ></div>
      </label>
    </div>
  )
}

export default InputSwitch
