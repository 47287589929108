import React from 'react'

const About = () => {
  return (
    <section className="section" id="about">
      <div className="w-9/12 pb-10 mx-auto my-8 lg:mb-20 ">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-xs-12">
            <div className="left-text-content">
              <div className="section-heading">
                <h6>About Us</h6>
                <h2>Welcome to Systems Mapper</h2>
                <p className='my-3'>A research-based semi-quantitative tool that allows you to create a survey
                for your research topic based on the principles of systems thinking. Our tool is designed to extract the
                fuzzy cognitive knowledge of your stakeholders and enable you to procure perception-based information
                from your participants.</p>
              </div>
            </div>
          </div>
          <div className="lg:px-4 col-lg-6 col-md-6 col-xs-12">
            <div className="">
              {/* <div>
                <a rel="nofollow noopener" href="https://templatemo.com/tm-543-breezed" target="_parent">
                Welcome to Systems Mapper,
                </a>{' '}
                a research-based semi-quantitative tool that allows you to create a survey
                for your research topic based on the principles of systems thinking. Our tool is designed to extract the
                fuzzy cognitive knowledge of your stakeholders and enable you to procure perception-based information
                from your participants.
              </div> */}
              <video controls className='w-full h-full'></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
