import React, {useContext} from "react";
import { LoginContext } from "../context/LoginContext";

export default function Sidebar({ selectedOption, handleChange, options }:any) {
  // const logout = ()=>{
  //   localStorage.removeItem("token");
  //   window.location.reload();
  // }
  const {user} = useContext(LoginContext)
  return (
    <div className=" w-1/5 py-6 pt-36 pl-6 space-x-4 border-r border-gray-200 sidebar bg-[#212b36] fixed top-0 h-full overflow-y-auto overflow-x-hidden">
      {/* <h2 className="p-4 px-10 text-lg font-medium">Options</h2> */}
      <div className="flex flex-col items-center h-full gap-4">
        {options.map((item:any, i:number) => {
          return (
            <div
              className="w-full p-4 px-10 lg:py-4 rounded-l-lg text-center transition ease-in-out delay-200 border-b border-slate-300 hover:-translate-y-1 hover:scale-110 hover:bg-[#11A8AB] active:bg-[#11A8AB] text-[#fff] font-[Ubuntu]"
              key={i}
              // to={item.url}
              style={
                selectedOption === item.id
                  ? { backgroundColor: "#11A8AB"  }
                  : {}
              }
              onClick={() => {
                handleChange(item);
              }}
            >
              {item.title}
            </div>
          );
        })}
        {/* <button className="items-end text-white mt-auto px-6 md:px-10 py-2 md:py-3 rounded-md bg-[#b3261f] hover:bg-[#883f17] button-[Manrope]" onClick={logout}>Log Out</button> */}
       {user.role === "superadmin" && <div
              className="w-full p-4 px-10 lg:py-4 rounded-l-lg text-center transition ease-in-out delay-200 border-b border-slate-300 hover:-translate-y-1 hover:scale-110 hover:bg-[#11A8AB] active:bg-[#11A8AB] text-[#fff] font-[Ubuntu]"
              key={Math.random().toString()}
              // to={item.url}
              style={
                selectedOption === 'users'
                  ? { backgroundColor: "#11A8AB"  }
                  : {}
              }
              onClick={() => {
                handleChange({id :"users"});
              }}
            >
              {"Users"}
            </div>}
      </div>
    </div>
  );
}
