import React, { FC, useState, useEffect } from "react";
import ReactDOM, { createPortal } from "react-dom";
import { debounce } from "../lib/utils";

type AdjacentPortalProps = {
  targetRef?: React.RefObject<HTMLElement>;
  targetElement?: HTMLElement;
  children?: React.ReactNode;
  render?: (position: { top: number; left: number }) => React.ReactNode;
  offset?: { top?: number; left?: number };
  alignCenter?: boolean;
};

const useScreenSizeChange = () => {
  const [screenSize, setScreenSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screenSize;
};

const AdjacentPortal: FC<AdjacentPortalProps> = ({
  targetRef,
  targetElement,
  children,
  render,
  alignCenter = false,
  offset: _offset = { top: 0, left: 0 },
}) => {
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  const screenSize = useScreenSizeChange();
  const offset = {
    top: _offset.top ?? 0,
    left: _offset.left ?? 0,
  };

  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  useEffect(() => {
    const element = targetRef?.current || targetElement;
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const top = rect.bottom + window.scrollY + offset.top;
    const isMobile = screenSize.width <= 768;
    const left = isMobile
      ? screenSize.width / 2
      : (alignCenter
          ? rect.left + window.scrollX + rect.width / 2
          : rect.left + window.scrollX) + offset.left;

    setPosition({ top, left });
  }, [
    targetRef,
    targetElement,
    screenSize,
    alignCenter,
    offset.top,
    offset.left,
  ]);

  useEffect(() => {
    const node = document.createElement("div");
    document.body.appendChild(node);
    setPortalNode(node);

    return () => {
      document.body.removeChild(node);
    };
  }, []);

  if (!portalNode) return null;

  return (
    <>
      {!!targetElement && targetElement}
      {createPortal(
        <div
          style={{
            position: "absolute",
            top: position.top,
            left: position.left,
            zIndex : 1000
          }}
        >
          {render ? render(position) : children}
        </div>,
        portalNode
      )}
    </>
  );
};

export default AdjacentPortal;
