import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import Survey from './pages/Survey'
import { Admin } from './pages/Admin'
import { Login } from './pages/Login'
import ErrorBoundary from './components/ErrorBoundary'
import Navigator from './components/Navigator'
import { LoginContext } from './context/LoginContext'
import { StartLoader } from './components/Loader'
import LandingPage from './pages/LandingPage'
// import DownloadXlxs from './components/DownloadXlxs'
import StatisticsPage from './pages/Statistics/StatisticsPage'
import "./quil.snow.css"

function App() {
  const { loggedIn, initialLoading } = useContext(LoginContext)
  return (
    <div
      style={
        {
          // // Theme 1
          // // '--background-light': '#C2F9BB',
          // // '--background-dark': '#20063b',
          // '--background-light': '#C6D8FF33',
          // '--background-dark': '#387780',
          // '--background-darker': '#363537',
          // '--background-button': '#387780',
          // '--background-second': '#9AD1D4',
          // '--background-third': '#fff',
          // // '--background-third': '#D2CCA1',
          // '--background-effect': '#1A8FE3',
          // '--background-error': '#E63B2E',
          // '--border-main': '#888482',

          // Second Theme
          '--background-light': '#C6D8FF33',
          '--background-dark': '#1f253d',
          '--background-darker': '#083D77',
          '--background-button': '#11A8AB',
          '--background-button-hover': '#079193',
          // '--background-second': '#e64c65',
          '--background-primary': '#FFFBFF',
          '--background-center': '#FFF',
          // '--background-third': '#D2CCA1',
          '--background-effect': '#11A8AB',
          // '--background-effect': '#e64c65',
          '--background-error': '#E63B2E',
          '--border-main': '#50597b',
          '--border-selected': '#50597b',
        } as React.CSSProperties
      }
    >
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route
            path="/admin"
            element={
              !initialLoading ? (
                loggedIn ? (
                  <Admin />
                ) : (
                  <Navigator to="/login" delay={0}></Navigator>
                )
              ) : (
                <StartLoader></StartLoader>
              )
            }
          />
          <Route
            path="/admin/statistics/:id"
            element={
              !initialLoading ? (
                loggedIn ? (
                  <StatisticsPage />
                ) : (
                  <Navigator to="/login" delay={0}></Navigator>
                )
              ) : (
                <StartLoader></StartLoader>
              )
            }
          />
          <Route path="/login" element={!initialLoading ? <Login /> : <></>} />
          {/* <Route path="/download" element={<DownloadXlxs />} ></Route> */}
          <Route path="/*" element={<Survey />} />
        </Routes>
      </ErrorBoundary>
    </div>
  )
}

export default App
