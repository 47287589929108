import React, { useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Input from './Input'
import mailer from '../services/mail.api'
import { SurveyContext } from '../pages/Survey/SurveyContext'

const Feedback = () => {
  const {surveyData} = useContext(SurveyContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const [feedbackContent, setFeedbackContent] = useState<string>('')
  const handleInputChange = (e: any) => {
    setFeedbackContent(e.target.value)
  }
  const sendMail =()=>{
    mailer.sendFeedbackMAil({to:"jofri.issac91@gmail.com",feedback:feedbackContent, user:{name:surveyData?.name, email:surveyData?.contact}}).then((res:any)=>{
      console.log(res);
      if(res.success) {
        console.log(res.data)
        navigate(`/${id}/user`)
      }else{
        console.log(res.err)
        navigate(`/${id}/user`)
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  return (
    <div className="flex items-center w-full mx-auto border rounded-lg lg:w-4/5 h-[600px] mt-10">
      <div className="w-[50%] relative h-full">
        <div className="absolute w-full h-full rounded-lg opacity-70"style={{backgroundImage:"linear-gradient(310deg,#0a1808 0%,#172820 100%)"}}></div>
        <img src="/Assets/world.png" className="object-cover object-center w-full h-full"></img>
      </div>
      <div className="w-[50%] h-full px-5">
        <h3 className="px-3 mx-auto my-6 text-4xl text-center">Share your Feedback</h3>
        <div className="my-2">
          We always appreciate your valuable feedback, It helps us improve. So please feel free to share your feedback.
        </div>
        <div className="mx-auto my-6 mt-10">
          <div className="relative w-auto tracking-wider">
            <textarea
              rows={7}
              onChange={handleInputChange}
              defaultValue={''}
              className={`text-[#383635] bg-[#00000000] autofill:!bg-[#00000000] focus:outline-none w-full p-4 px-4 border border-slate-500 tracking-wide rounded-lg `}
            ></textarea>
            <label
              htmlFor={'Feedback'}
              className={`absolute ${
                feedbackContent ? 'top-[-12px] text-[#383635] ' : 'text-[#383635] top-[-4px]'
              }  bg-[#f3f7ff] left-4 w-fit h-fit inset-0 px-1 capitalize transition-all ease-in-out duration-200`}
            >
              Feedback
            </label>
          </div>
        </div>
        <button className='text-lg flex mx-auto items-center justify-center rounded-lg text-center bg-[color:var(--background-button)] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-10 py-4'
        onClick={sendMail}>
            Submit
        </button>
      </div>
    </div>
  )
}

export default Feedback
