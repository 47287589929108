import React from 'react'

const FeedbackIcon = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="21"
      height="21"
      viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
      className='fill-[#fff] hover:scale-110 cursor-pointer'
    >
      <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)" fill="inherit" stroke="none">
        <path d="M50 910 c-19 -19 -20 -33 -20 -421 0 -398 2 -430 34 -451 14 -10 668 -10 682 0 24 15 34 54 34 126 0 42 -4 76 -8 76 -4 0 -30 -22 -56 -49 -38 -38 -65 -55 -121 -75 -81 -29 -105 -32 -123 -14 -19 19 -14 50 19 136 29 77 36 85 160 206 l129 127 0 48 0 49 -109 4 c-149 4 -151 6 -151 150 l0 108 -225 0 c-212 0 -226 -1 -245 -20z m200 -320 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m250 0 c0 -6 -38 -10 -95 -10 -57 0 -95 4 -95 10 0 6 38 10 95 10 57 0 95 -4 95 -10z m140 0 c0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10 0 6 20 10 45 10 25 0 45 -4 45 -10z m-390 -110 c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0 40 -4 40 -10z m190 0 c0 -6 -28 -10 -65 -10 -37 0 -65 4 -65 10 0 6 28 10 65 10 37 0 65 -4 65 -10z m200 0 c0 -6 -27 -10 -60 -10 -33 0 -60 4 -60 10 0 6 27 10 60 10 33 0 60 -4 60 -10z m-320 -110 c0 -6 -32 -10 -75 -10 -43 0 -75 4 -75 10 0 6 32 10 75 10 43 0 75 -4 75 -10z m140 0 c0 -5 -25 -10 -55 -10 -30 0 -55 5 -55 10 0 6 25 10 55 10 30 0 55 -4 55 -10z" />
        <path d="M540 824 c0 -123 2 -126 118 -122 l92 3 -105 105 -105 105 0 -91z" />
        <path d="M805 550 c-11 -4 -75 -64 -143 -132 l-122 -123 63 -63 62 -62 127 128 c151 151 161 171 105 227 -35 35 -55 41 -92 25z" />
        <path d="M500 200 c-32 -94 -24 -102 67 -70 38 13 70 25 72 27 5 4 -100 113 -109 113 -3 0 -17 -31 -30 -70z" />
      </g>
    </svg>
  )
}

export default FeedbackIcon
