import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { useCallback, useMemo, useRef } from "react";

function App() {
    const containerRef = useRef(null);

    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback((container) => {
        containerRef.current = container;

        window.particlesContainer = container;
    }, [containerRef]);

    const options = useMemo(() => ({
        fullScreen: {
            zIndex: 1,
        },
        particles: {
            number: {
                value: 100,
            },
            links: {
                enable: true,
            },
            move: {
                enable: true,
                "speed": 1,
            },
        },
        background:{
            color : "#000000",
            opacity: 0.1
        },
        "interactivity": {
            "detect_on": "window",
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              },
              "onclick": {
                "enable": true,
                "mode": "push"
              },
              "resize": true
            },
            "modes": {
              "grab": {
                "distance": 364,
                "line_linked": {
                  "opacity": 1
                }
              },
              "bubble": {
                "distance": 400,
                "size": 4,
                "duration": 2,
                "opacity": 1,
                "speed": 3
              },
              "repulse": {
                "distance": 150,
                "duration": 0.4
              },
              "push": {
                "particles_nb": 4
              },
              "remove": {
                "particles_nb": 2
              }
            }
          },
        themes: [
            // {
            //     name: "light",
            //     default: {
            //         value: true,
            //         auto: true,
            //         mode: "light",
            //     },
            //     options: {
            //         background: {
            //             color: "#ffffff00",
            //         },
            //         particles: {
            //             color: {
            //                 value: "#000000",
            //             },
            //             links: {
            //                 color: "#000000",
            //             },
            //         },
            //     },
            // },
            {
                name: "dark",
                default: {
                    value: true,
                    auto: true,
                    mode: "dark",
                },
                options: {
                    background: {
                        color: "#00000000",
                    },
                    particles: {
                        color: {
                            value: "#ffffff",
                        },
                        links: {
                            color: "#ffffff",
                        },
                    },
                },
            },
        ],
        style: {
            opacity : 0.5,
            position: "absolute",
        }
    }), []);

    // const lightTheme = useCallback(() => {
    //     if (!containerRef.current) {
    //         return;
    //     }

    //     console.log(containerRef.current);

    //     setTimeout(() => {
    //         containerRef.current.loadTheme("light");
    //     }, 500);
    // }, [containerRef]);

    // const darkTheme = useCallback(() => {
    //     if (!containerRef.current) {
    //         return;
    //     }

    //     console.log(containerRef.current);

    //     setTimeout(() => {
    //         containerRef.current.loadTheme("dark");
    //     }, 500);
    // }, [containerRef]);

    return (
        <div className="App w-full h-full absolute z-10 inset-0">
          
            <Particles id="tsparticles" init={particlesInit} loaded={particlesLoaded} options={options} />
        </div>
    );
}

export default App;