import React, { FC, useState } from 'react'
import ConnectionList from './ConnectionList'

const ToolFooter: FC<any> = ({ setFixed, changeNode, centerNodeData, openAccordionData, contentData, setContentData, setOpenAccordion }) => {
  const [showConnectionList, setShowConnectionList] = useState(false)
  const handleCloseConnectionList = () =>{
    setShowConnectionList(false);
  }
  return (
    <>
    <div className="fixed bottom-0 w-full h-[50px] flex items-center justify-end bg-[#304164] z-[1000]">
      <div className="flex m-1.5 items-center justify-end gap-5 mr-5">
        <button
          className="savenexit  text-sm  font-[Sora] border border-white text-[#fff] px-6 py-2 rounded-md  flex gap-2 items-center"
          onClick={() => {
            if (openAccordionData) changeNode(centerNodeData, openAccordionData, 'openAccordion')
              setOpenAccordion('')
            setShowConnectionList(true)
          }}
        >
          View Connection list
        </button>
        <button
          className="savenexit text-sm bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md  flex gap-2 items-center"
          onClick={() => {
            setFixed({})
            changeNode(centerNodeData, openAccordionData, 'end')
          }}
        >
          Save & Exit
        </button>
      </div>
    </div>
    <ConnectionList showPopup={showConnectionList} handleClose={handleCloseConnectionList} nodes={contentData?.nodes} setContentData={setContentData} />
    </>
  )
}

export default ToolFooter
