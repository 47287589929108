import React from 'react'
import { bootstrapStyle, breezedStyle } from './temp'
import Slider from '../../components/Landingpage/Slider'
import About from '../../components/Landingpage/About'
import { BsChevronDoubleDown } from 'react-icons/bs'
import Footer from '../../components/Landingpage/Footer'
import Informative from '../../components/Landingpage/Informative'
import ContactUs from '../../components/Landingpage/ContactUs'
import {Link} from "react-router-dom"
const LandingPage = () => {
  return (
    <>
      <header className="header-area header-sticky">
        <div dangerouslySetInnerHTML={{ __html: `<style>${bootstrapStyle} ${breezedStyle}</style>` }}></div>
        <div className="my-2">
          <div className="w-full">
            <div className="w-full">
              <nav className="main-nav">
                <a href="/" className="logo">
                  <div className="flex items-center my-auto">
                    <div className="w-[75px] h-[60px] ml-[20px] my-auto">
                      <img className="w-full h-full" src="/Assets/logonodes.svg"></img>
                    </div>
                    <div
                      className="font-[Montserrat] font-[500] text-2xl text-[#304164] md:text-white pl-4"
                      style={{ fontFamily: 'Sora' }}
                    >
                      Systems Mapper
                    </div>
                  </div>
                </a>
                <ul className="nav font-normal">
                  <li className="scroll-to-section">
                    <a href="#top" className="active">
                      Home
                    </a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#about">About</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#contact-us">Contact Us</a>
                  </li>
                  <li className="scroll-to-section">
                    <Link to="/login">Sign in</Link>
                  </li>
                </ul>
                {/* <a className="menu-trigger">
                  <span>Menu</span>
                </a> */}
              </nav>
            </div>
          </div>
        </div>
      </header>

      <Slider />
      <div className="scroll-down scroll-to-section">
        <a href="#about" className='flex items-center justify-center'>
        <BsChevronDoubleDown className='' style={{display:"inherit"}} />
        </a>
      </div>

      <About />
      <Informative />

      {/* <!-- ***** Features Big Item Start ***** --> */}
      {/* <section className="section" id="features">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/images/features-icon-1.png" alt="" />
                </div>
                <div className="features-content">
                  <h4>Initial Work</h4>
                  <p>
                  Ready to create your own survey tool and extract valuable knowledge from your stakeholders?

                  </p>
                  <a href="#" className="text-button-icon">
                  Get started with Systems Mapper Tool today! <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
              data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s"
            >
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/images/features-icon-1.png" alt="" />
                </div>
                <div className="features-content">
                  <h4>Master Planning</h4>
                  <p>
                    Proin euismod sem ut diam ultricies, ut faucibus velit ultricies. Nam eu turpis quam. Duis ac
                    condimentum eros.
                  </p>
                  <a href="#" className="text-button-icon">
                    Learn More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
              data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
            >
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/images/features-icon-1.png" alt="" />
                </div>
                <div className="features-content">
                  <h4>Smooth Execution</h4>
                  <p>
                    Proin euismod sem ut diam ultricies, ut faucibus velit ultricies. Nam eu turpis quam. Duis ac
                    condimentum eros.
                  </p>
                  <a href="#" className="text-button-icon">
                    Learn More <i className="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ***** Features Big Item End ***** --> */}

      <ContactUs />

      <Footer />
    </>
  )
}

export default LandingPage
