import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../../components/Sidebar'
import AllProjects from '../../components/AllProjects'
import { Helmet } from 'react-helmet'
import Navbar from '../../components/Navbar'
import AllUsers from '../../components/AllUsers'
import DownloadSheet from '../../components/DownloadSheet'
import UploadSheet from '../../components/UploadSheet'

const Admin = () => {
  const [sidebarOptions, setSidebarOptions] = useState<Array<{ id: string; title: string }>>([
    { id: 'upload', title: 'Upload project' },
    { id: 'configuration', title: 'Edit project' },
    { id: 'download', title: 'Download datasheet' },
  ])
  const [selectedOption, setSelectedOption] = useState<any>('download')

  const handleChange = (item: any) => {
    setSelectedOption(item.id)
  }

  return (
    <>
      <Navbar {...{ showCategories: true, showLogoutButton: true }} />
      <div className="flex w-full h-full">
        <Helmet>
          <meta charSet="utf-8" />
          <title> System Mapper Tool | Admin </title>
        </Helmet>
        <Sidebar options={sidebarOptions} handleChange={handleChange} selectedOption={selectedOption}></Sidebar>
        <div className="w-4/5 h-full ml-auto">
          <div className="mt-[50px] w-11/12 mx-auto gap-5 items-center">
            {selectedOption === 'upload' ? (
              <UploadSheet setSelectedOption={setSelectedOption} sidebarOptions={sidebarOptions} />
            ) : (
              <></>
            )}
            {selectedOption === 'download' ? <DownloadSheet></DownloadSheet> : <></>}
            {selectedOption === 'configuration' ? <AllProjects></AllProjects> : <></>}
            {selectedOption === 'users' ? <AllUsers /> : <></>}
          </div>
        </div>
      </div>
    </>
  )
}
export default Admin
