import React, { FC, useEffect, useState } from 'react'
import content from '../services/content.api'
import survey from '../services/survey.api'
import NodesIcon from './Icons/NodesIcon'
import SubNodesIcon from './Icons/SubNodesIcon'
import { SurveyIcon } from './Icons'
import SurveysIcon from './Icons/SurveysIcon'
import { IoChevronDownCircleOutline } from 'react-icons/io5'
import { MdArrowRight } from 'react-icons/md'
import { FiChevronDown } from 'react-icons/fi'
import { NodeType } from '../pages/Tool/Tool.types'
import { statisticsAnalyzeData } from '../lib/utils'
import { ratingConstants } from '../lib/constants'
import { stat } from 'fs'
export type statConnectionType = {
  node: string
  subNode: string
}
export type StatType = {
  totalNodes: number
  totalSubNodes: number
  totalSurveyDone?: number
  totalReinforcingConnections: number
  totalBalancingConnections: number
  totalConnections: number
  connectionsPerSurvey?: number
  highestConList: { start: statConnectionType; end: statConnectionType, value : any }[]
  lowestConList: { start: statConnectionType; end: statConnectionType, value :any }[],
  nodeConnections : {[key : string] : {
    totalIncomingConnections: number,
    totalOutGoingConnections: number,
    totalConnections: number,
}},
subNodeConnections : {[key : string] : {
  totalIncomingConnections: number,
  totalOutGoingConnections: number,
  totalConnections: number,
}}
}
const Statistics: FC<{ projectId: string; filter: {} }> = ({ projectId, filter={} }) => {
  const initialStat = {
    totalNodes: 0,
    totalSubNodes: 0,
    totalSurveyDone: 0,
    totalReinforcingConnections: 0,
    totalBalancingConnections: 0,
    totalConnections: 0,
    connectionsPerSurvey: 0,
    highestConList: [],
    lowestConList: [],
    nodeConnections : {},
    subNodeConnections : {},
  }

  const [loading, setLoading] = useState(true)
  const [contentData, setContentData] = useState<any>({})
  const [surveyData, setSurveyData] = useState<any>({})
  const [stats, setStats] = useState<StatType>(initialStat)
  const getContent = () => {
    content
      .getContentById(projectId)
      .then((res) => {
        setContentData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getAggregatedSurvey = (body: any) => {
    survey
      .getAggregatedData({ contentId: [projectId], ...body })
      .then((res) => {
        // console.log(res, 'res')
        setSurveyData(res.data)
        setStats(statisticsAnalyzeData(res.data.nodes as NodeType[], res.data.totalSurveys))
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    if (projectId) {
      getContent()
      getAggregatedSurvey(filter)
    }
  }, [projectId, filter])
  // const stats = {
  //   totalNodes: 40,
  //   totalSubNodes: 50,
  //   totalSurveyDone: 50,
  //   totalReinforcingConnections: 100,
  //   totalBalancingConnections: 100,
  //   totalConnections: 300,
  //   connectionsPerSurvey: 50,
  //   highestConList : [{start : {node:"Climate hazards and Risks", subNode :"Climate Risks"}, end : {node:"Financial Challenges", subNode :"Hunger"}}, {start : {node:"Climate hazards and Risks", subNode :"Climate Risks"}, end : {node:"Financial Challenges", subNode :"Hunger"}}],
  //   lowestConList : [{start : {node:"Climate hazards and Risks", subNode :"Climate Risks"}, end : {node:"Financial Challenges", subNode :"Hunger"}}, {start : {node:"Climate hazards and Risks", subNode :"Climate Risks"}, end : {node:"Financial Challenges", subNode :"Hunger"}}],
  // }
  const layout = {
    globalMainStats: [
      { icon: <NodesIcon />, title: 'Number of nodes', key: 'totalNodes' },
      { icon: <SubNodesIcon />, title: 'Number of Sub nodes', key: 'totalSubNodes' },
      { icon: <SurveysIcon />, title: 'Number of Surveys done', key: 'totalSurveyDone' },
    ],
    globalSubStats: [
      { title: 'Number of Reinforcing connections', key: 'totalReinforcingConnections' },
      { title: 'Number of Balancing connections', key: 'totalBalancingConnections' },
      { title: 'Number of Total connections', key: 'totalConnections' },
      { title: 'Connections per survey ', key: 'connectionsPerSurvey' },
    ],
    lists: [
      { key: 'highestConList', title: 'Top 10 highest rated connections' },
      { key: 'lowestConList', title: 'Top 10 lowest rated connections' },
    ],
  }
  const [listAccordion, setListAccordion] = useState<{ [key: string]: boolean }>({})
  const [tableAccordion, setTableAccordion] = useState<{ [key: string]: boolean }>({})
  const getConnectionRating = (value : any) =>{
    return Math.abs( (value) &&
    !ratingConstants.BALANCING_REGEX.test(value)  &&
    !ratingConstants.REINFORCING_REGEX.test(value) &&
      parseFloat(value))?.toFixed(1) ||
      0
  }
  const checkConnectionType = (value: any) =>{
    return isNaN(parseFloat(value)) ? ratingConstants.REINFORCING_REGEX.test(value) : parseFloat(value) >0 
  }
  return (
    <div className="w-full h-full px-6 py-5 lg:px-10">
      <div className="grid grid-cols-1 gap-3 md:grid-cols-3 lg:gap-5">
        {layout.globalMainStats.map((stat, index) => {
          return (
            <div
              className="bg-[#304164] text-white flex md:flex-col items-center md:items-start justify-start gap-2.5 p-5 rounded-lg"
              key={index}
            >
              <div className="bg-[#11A8AB] rounded-lg w-[34px] h-[34px] flex items-center justify-center">
                {stat.icon}
              </div>
              <div className="text-base font-normal" style={{ fontFamily: 'Lexend Deca' }}>
                {stat.title}
              </div>
              <div className="font-semibold text-2xl lg:text-4xl font-[Sora] ml-auto md:ml-0">
                {stats[stat.key as keyof typeof stats] as number}
              </div>
            </div>
          )
        })}
      </div>
      <div className="grid grid-cols-1 gap-3 mt-5 md:grid-cols-2 lg:grid-cols-4 lg:gap-5">
        {layout.globalSubStats.map((stat, index) => {
          return (
            <div
              className="flex md:flex-col items-center justify-start gap-2.5 p-5 rounded-lg bg-white text-[#304164] border-[0.5px] border-[#BDBDBD]"
              key={index}
            >
              <div className="text-base font-normal text-center" style={{ fontFamily: 'Lexend Deca' }}>
                {stat.title}
              </div>
              <div className="font-semibold text-2xl font-[Sora] ml-auto md:ml-0">
                {stats[stat.key as keyof typeof stats] as number}
              </div>
            </div>
          )
        })}
      </div>
      <div className="grid grid-cols-1 gap-3 mt-5 lg:grid-cols-2 lg:gap-5">
        {layout.lists.map((list, index) => {
          return (
            <div
              className="relative p-5 rounded-lg bg-white text-[#304164] border-[0.5px] border-[#BDBDBD] flex items-center gap-2.5 justify-between cursor-pointer"
              key={index}
              onClick={() => {
                setListAccordion((t) => ({ ...t, [list.key]: !t?.[list.key] }))
              }}
            >
              <div className="text-base font-semibold" style={{ fontFamily: 'Sora' }}>
                {list.title}
              </div>
              <IoChevronDownCircleOutline className="text-2xl" />
              <div
                className={`absolute left-0 z-10 w-full bg-white rounded-lg top-[110%] transition-[height] ease-in-out duration-300 overflow-y-auto box-border ${
                  listAccordion?.[list.key] ? 'h-[300px]' : 'h-0'
                }`}
              >
                <div className="w-full px-5 ">
                  {(list.key === 'highestConList' ? stats?.highestConList : stats?.lowestConList)?.map((con, index) => {
                    return (
                      <div
                        className="flex items-center justify-start gap-2 py-8 border-b border-[#BDBDBD] last:border-0"
                        key={index}
                      >
                        <div className="flex flex-col items-start gap-3 font-normal">
                          <span className="text-[#304264] font-lexend-deca max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                            {con?.start?.node}
                          </span>
                          <span className="text-white px-5 font-[Sora] py-1.5 rounded-md bg-[#1f253d] max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                            {con?.start?.subNode}
                          </span>
                        </div>
                        <div
                          className={`relative border-b-[2px]  w-auto flex-1 h-[1px] mt-auto mb-5 
                            ${checkConnectionType(con?.value || 0) ? "border-[#000000]" : "border-red-600 border-dashed"}
                  `}
                  >
                          <div className="absolute left-1/2 -translate-x-1/2 top-0 -translate-y-1/2 bg-[color:var(--background-button)] text-white text-xs rounded-full p-1 w-6 h-6 flex items-center justify-center font-semibold">
                            {/* {getConnectionRating(connection?.value || 0)} */} 
                            {getConnectionRating(con.value)}
                          </div>
                          <div
                            className={`absolute right-0 z-10 top-[1px] -translate-y-1/2 translate-x-1/2 text-xl 
                              ${checkConnectionType(con?.value || 0) ? "text-[#000000]" : "text-red-600"}
                    `}
                          >
                            <MdArrowRight className="text-2xl" />{' '}
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-3 font-normal">
                          <span className="text-[#304264] font-normal font-lexend-deca max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                            {con?.end?.node}
                          </span>
                          <span className="text-white px-5 py-1.5 font-[Sora] rounded-md bg-[#1f253d] max-w-[180px] text-ellipsis whitespace-nowrap overflow-hidden inline-block">
                            {con?.end?.subNode}
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="mt-5">
        <table className="w-full">
          <thead className="bg-[#11A8AB] text-white text-base font-normal" style={{ fontFamily: 'Lexend Deca' }}>
            <th className="py-3 text-base font-normal border-r border-white">Nodes</th>
            <th className="py-3 text-base font-normal border-r border-white">Number of incoming connections</th>
            <th className="py-3 text-base font-normal border-r border-white">Number of outgoing connections</th>
            <th className="py-3 text-base font-normal border-r border-white">Number of connections</th>
          </thead>
          <tbody className="text-base">
            {/* {console.log(surveyData?.nodes)} */}
            {surveyData?.nodes?.map((node: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => {
                      setTableAccordion((t) => ({ ...t, [node.id]: !t?.[node.id] }))
                    }}
                    className="!bg-[#304164] text-white font-normal cursor-pointer select-none"
                  >
                    <td>
                      <span className="flex items-center gap-3 pl-1">
                        <FiChevronDown /> {node.title}
                      </span>
                    </td>
                    <td>
                      <span>{stats?.nodeConnections?.[node.id]?.totalIncomingConnections}</span>
                    </td>
                    <td>
                      <span>{stats?.nodeConnections?.[node.id]?.totalOutGoingConnections}</span>
                    </td>
                    <td>
                      <span>{stats?.nodeConnections?.[node.id]?.totalConnections}</span>
                    </td>
                  </tr>
                  {tableAccordion?.[node.id] ? (
                    <>
                      {node.nodes?.map((subnode: any, i: number) => {
                        return (
                          <tr key={i}>
                            <td>
                              <span className="pl-7">{subnode?.title}</span>
                            </td>
                            <td>
                              <span className="">{stats?.subNodeConnections?.[subnode.id]?.totalIncomingConnections}</span>
                            </td>
                            <td>
                              <span className="">{stats?.subNodeConnections?.[subnode.id]?.totalOutGoingConnections}</span>
                            </td>
                            <td>
                              <span className="">{stats?.subNodeConnections?.[subnode.id]?.totalConnections}</span>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Statistics
