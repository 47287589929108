import React, { useState, useEffect, useContext } from 'react'
import { designations, domains } from '../lib/constants'
import survey from '../services/survey.api'
import content from '../services/content.api'
import Input, { SelectInput, MultipleSelect, RadioInput } from './Input'
import { SurveyData, getSourceTargetData, getxlsx } from '../lib/utils'
import Statistics from './Statistics'
const DownloadSheet = () => {
  const [fields, setFields] = useState<Array<any>>([
    {
      type: 'select',
      title: 'Project',
      name: 'contentId',
      options: [{ title: 'Project 1 (CBH)', value: '11000' }],
    },
    // {
    //   type: 'select',
    //   title: 'Occupation',
    //   name: 'designation',
    //   options: designations,
    // },
    // {
    //   type: 'select',
    //   title: 'Domain',
    //   name: 'domain',
    //   options: domains,
    // },
    {
      type: 'multiselect',
      title: 'Stakeholder',
      name: 'surveyId',
      options: [{ title: '', value: '' }],
    },
  ])
  const [selectedData, setSelectedData] = useState<any>({})
  const [distinctContents, setDistinctContents] = useState<any>([])
  const [selectedOptionsStakeholders, setSelectedOptionsStakeholders] = useState<string[]>([])
  const [selectedFilter, setSelectedFilter] = useState<{[key:string] : string | Array<string>}>({})
  const [contentData, setContentData] = useState<any>({})
  const [surveyData, setSurveyData] = useState<any>({})
  const [allSurveys, setAllSurveys] = useState<any>([])
  const [fileName, setFileName] = useState<string>('')
  const [errorDownload, setDownloadError] = useState<string>('')
  const [selectedFileType, setSelectedFileType] = useState('sourceTarget')
  const [showStatistics, setShowStatistics] = useState<boolean>(false)
  useEffect(() => {
    survey
      .getAllSurveys()
      .then((res) => {
        if (res.data && res.data.length) {
          let options = res.data.map((item: any) => {
            return {
              title: `${item.surveyId} - ${item?.name || item?.userData?.Name || ''}`,
              id: item.surveyId,
            }
          })
          setAllSurveys(res.data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    getDistinctContents()
  }, [])
  type XlsxData = Array<{
    sheet: string
    columns: Array<{ label: string; value: string | number }>
    content: Array<any>
  }>
  const download = (contentData: any, surveyData: any) => {
    const schema = surveyData?.nodes?.reduce(
      (acc: any, curr: any) => {
        acc.refIds[curr.id] = acc.refCount
        acc.columns.push({ label: `${acc.refCount}`, value: curr.id })
        acc.content[0][curr.id] = curr.title
        acc.content.push({ title: curr.title })
        acc.refCount++
        curr?.nodes?.forEach((subNode: any) => {
          acc.refIds[subNode.id] = acc.refCount
          acc.columns.push({ label: `${acc.refCount}`, value: subNode.id })
          acc.content[0][subNode.id] = subNode.title
          let rowObject: any = { title: subNode.title }
          subNode.connections?.forEach((con: any, index: number) => {
            if (con.id) {
              rowObject[con.id] = con.value || ''
            }
          })
          acc.content.push(rowObject)
          acc.refCount++
        })
        return acc
      },
      { refCount: 1, columns: [{ label: '0', value: 'title' }], content: [{ title: '' }], refIds: {} },
    )
    let xlsxData: XlsxData = [
      { sheet: contentData?.title || 'Survey', columns: schema.columns, content: schema.content },
    ]
    getxlsx(xlsxData, fileName || 'Adjacency-matrix')
  }
  const downloadSourcetargetXLSX = (surveyData: SurveyData) => {
    const content = getSourceTargetData(surveyData)
    let xlsxData: XlsxData = [
      {
        sheet: contentData?.title || 'Survey',
        columns: [
          { label: 'Source', value: 'source' },
          { label: 'Target', value: 'target' },
          { label: 'Value', value: 'value' },
          { label: 'Count', value: 'count' },
          { label: 'Notes', value: 'notes' },
        ],
        content: content,
      },
    ]
    getxlsx(xlsxData, fileName || 'Edge-list')
  }

  const getContent = (id: string) => {
    content
      .getContentById(id)
      .then((res) => {
        setContentData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getDistinctContents = () => {
    content
      .getDistinctContents()
      .then((res) => {
        if (res.data.data) {
          setDistinctContents(res.data.data)
          setFields((f) =>
            f.map((i) =>
              i.name === 'contentId'
                ? {
                    ...i,
                    options: res.data.data.map((item: any) => {
                      return { title: item.projectName, value: item.contentId }
                    }),
                  }
                : i,
            ),
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getAggregatedSurvey = (body: any) => {
    survey
      .getAggregatedData(body)
      .then((res) => {
        console.log(res, 'res')
        setSurveyData(res.data)
        // if (res.data) download(contentData, res.data)
        if (res.data) {
          if (selectedFileType === 'sourceTarget') downloadSourcetargetXLSX(res.data)
          else download(contentData, res.data)
        } else {
          setDownloadError('No data available for selected combination.')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSelectChange = (e: any) => {
    if (e.target.name === 'contentId') {
      let selectedContentId = parseInt(e.target.value)
      let selectedContent = distinctContents?.find((content: any) => content?.contentId === selectedContentId)
      setContentData(selectedContent)
      setSelectedFilter({});
      setDownloadError("")
      let referenceField = selectedContent?.userFormSchema?.find((field: any) => field?.referenced)
      let options = allSurveys.reduce((acc: Array<{ title: string; id: string }>, item: any) => {
        if (item.contentId === selectedContentId)
          acc.push({
            // title: `${item.surveyId} - ${item.name || item?.userData?.name || item?.userData?.Name || ""}`,
            title: `${item.surveyId}${referenceField?.name ? `${item?.userData?.[referenceField?.name] ? ' - ' +item?.userData?.[referenceField?.name] : ""}` : ''}`,
            id: item.surveyId,
          })
        return acc
      }, [])
      let contentUserFilters = selectedContent?.userFormSchema?.filter(
        (field: any) => field?.showFilter && field?.type === 'dropdown',
      )
      setFields((e: any): any => {
        let tmp = e.filter((item: any) => item.name === 'contentId')
        tmp.push({
          type: 'multiselect',
          title: 'Stakeholder',
          name: 'surveyId',
          options: options,
        })
        if (contentUserFilters && contentUserFilters?.length) {
          tmp.push(
            ...contentUserFilters.map((filter: any) => ({
              type: 'multiselect',
              title: filter?.name,
              name: filter?.name,
              options: filter?.options.map((option: any) =>({id: option, title: option})),
            })),
          )
        }
        return tmp
      })
    }
    setSelectedData((t: any) => {
      return { ...t, [e.target.name]: e.target.value }
    })
  }
  const handleDownload = () => {
    getContent(selectedData?.contentId || 11023)
    let {surveyId, ...customFilters} = selectedFilter;
    setDownloadError("")
    getAggregatedSurvey({
      // contentId: parseInt(selectedData?.contentId) || 11023,
      customFilters,
      ...(selectedData?.contentId ? { contentId: [selectedData?.contentId] } : {}),
      ...(selectedFilter?.surveyId?.length ? { surveyId: selectedFilter.surveyId } : {}),
      // ...(selectedData.designation ? { designation: [selectedData.designation] } : {}),
      // ...(selectedData.domain ? { domain: [selectedData.domain] } : {}),
    })
  }
  return (
    <>
    {showStatistics && selectedData?.contentId ? <Statistics projectId={selectedData?.contentId} filter={{...selectedFilter,
      ...(selectedFilter?.surveyId?.length ? { surveyId: selectedFilter.surveyId } : {}),}} /> : <div className="relative p-4 px-6 border-2 border-dashed rounded-xl border-slate-400">
      <span className="font-[Sora] text-lg absolute top-0 -translate-y-1/2 px-2 bg-[#f3f7ff]">
        Download Aggregated Sheet
      </span>
      <div className="box-border flex items-start justify-between gap-5 my-4">
        <div className="box-border flex flex-wrap w-1/2 gap-3">
          {fields.map((field, index) => {
            if (field.type === 'select')
              return (
                <div className="w-full my-2">
                  <SelectInput
                    title={field.title}
                    name={field.name}
                    options={field.options}
                    handleChange={handleSelectChange}
                    labelClass={'bg-[#d2e4d8]'}
                    optionsClass={'bg-[#d2e4d8]'}
                  ></SelectInput>
                </div>
              )
          })}
          {fields.map((field, index) => {
            if (field.type === 'multiselect')
              return (
                <div className="w-full my-2">
                  <MultipleSelect
                    title={field.title}
                    options={field.options}
                    selectedOptions={selectedFilter?.[field.name]}
                    setSelectedOptions={(value:string)=>setSelectedFilter((t)=>({...t, [field.name]: value}))}
                    labelClass={'bg-[#d2e4d8]'}
                    optionsClass={'bg-[#d2e4d8]'}
                  ></MultipleSelect>
                </div>
              )
          })}
        </div>
        <div className="flex items-center w-1/3 gap-3">
          <div className="flex flex-col items-start justify-center w-full gap-3 mx-auto my-2">
            <RadioInput
              options={[
                { title: 'Edge list', value: 'sourceTarget' },
                { title: 'Adjacency matrix', value: '2d' },
              ]}
              type={'radio'}
              title={'File Type'}
              defaultValue={selectedFileType}
              name={'fileType'}
              handleChange={(e: any) => {
                setSelectedFileType(e.target.value)
              }}
              titleClass={'ml-4 text-lg font-[Sora] font-normal'}
              inputClassName={'m-auto'}
              labelClassName={'bg-[#f3f7ff] !normal-case'}
              value={selectedFileType}
              error={''}
              formClass={''}
            />
            <div className="w-full my-2">
              <Input
                type={'text'}
                title={'Save as'}
                defaultValue={''}
                name={'Save as'}
                onChange={(e) => {
                  setFileName(e.target.value)
                }}
                inputClassName={''}
                labelClassName={'bg-[#f3f7ff] !normal-case'}
                value={fileName}
                error={''}
              />
            </div>
            <div className="flex flex-col w-full gap-3">
              <div className='flex items-center gap-3'>
              <button
                className="mx-auto px-8 xl:px-10 py-3 font-[Ubuntu] rounded-lg bg-[color:var(--background-button)] font-normal tracking-wide text-white w-fit"
                onClick={()=>setShowStatistics(true)}
                >
                Statistics
              </button>
                <div className='flex flex-col'>
              <button
                className="mx-auto px-8 xl:px-10 py-3 font-[Ubuntu] rounded-lg bg-[color:var(--background-button)] font-normal tracking-wide text-white w-fit"
                onClick={handleDownload}
                >
                Download
              </button>{' '}
              {errorDownload && <span className="text-sm text-[color:var(--background-error)]">{errorDownload}</span>}
              </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  )
}

export default DownloadSheet
