import React, { useState } from 'react'
import {BiInfoCircle} from "react-icons/bi"
import {CgArrowLongRight} from "react-icons/cg"


const TopicCard = ({ node,showDetails, startSurvey }: any) => {
  const [hover, setHover] = useState<boolean>(false)
  return (
    <div className="w-full h-[450px] relative">
      <img
        src={node?.image?.src}
        className="object-cover object-center w-full h-full z-[-10]"
        onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src='https://st.depositphotos.com/1605581/2301/i/600/depositphotos_23019286-stock-photo-gray-abstract-background.jpg';
          }}
      ></img>
      <div
        className={`absolute z-10 text-2xl font-bold text-white inset-0 h-full w-full`}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
      >
        {hover && <div className="h-full w-full z-[-10] bg-[#00000050] absolute ease-in-out transition-colors duration-300"></div>}
        <div className={`w-[50%] h-[60%] border-r-2 border-[#fff] ease-in-out transition-colors duration-300 ${hover ? 'visible' : 'invisible'}`}></div>
        <div className={`${hover ? 'h-[30px]' : 'h-0'} w-full transition-[height] duration-200 ease-in-out`}></div>
        <span
          className={`top-[50%] px-4 z-40 transition-all duration-200 ease-in-out ${
            hover ? 'opacity-100 pt-[30px]' : ''
          }`}
        >
          {node?.title}
        </span>
        <div className={`px-4 my-2 text-sm`}> {node?.description?.slice(0,140)}{node?.description?.length > 140 && '...'}</div>
        <div className={`flex justify-between items-center px-4 gap-4  ${hover ? 'block': 'hidden'}`}> 
        <button className="flex items-center gap-2 text-sm" onClick={()=>showDetails(node)}>
            Details <BiInfoCircle />
        </button>
        <button className="flex items-center gap-2 text-base" onClick={()=>startSurvey(node?.id)}>
            Start <CgArrowLongRight />
        </button>
        </div>
      </div>
    </div>
  )
}
export default TopicCard
