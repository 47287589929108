import React from 'react'

const Modal = ({onClose, children}:any) => {
  return (
    <div
      className="w-full h-full bg-[#000000CC] fixed left-0 top-0 z-[99999] flex
    justify-center items-center"
    >
      <div className="bg-[#fff] flex flex-col mx-2 justify-center text-[Ubuntu] text-[18px] font-bold  p-0 md:p-2 lg:p-4 max-w-[800px] rounded-md">
        <div className="flex justify-end" onClick={onClose}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 16 16"
            className="cursor-pointer"
            height="1.2em"
            width="1.2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 8.707l3.646 3.647.708-.707L8.707 8l3.647-3.646-.707-.708L8 7.293 4.354 3.646l-.707.708L7.293 8l-3.646 3.646.707.708L8 8.707z"
            ></path>
          </svg>
        </div>
        {children}
      </div>
    </div>
  )
}
export default Modal
