import { createContext, useState, useEffect } from 'react'
import userObj from '../services/user.api'
import { Step } from 'react-joyride'
import { BsBoxArrowInDownRight } from 'react-icons/bs'
export const TourGuideContext = createContext<any>(null)

export const TourGuideState = (props: any) => {
  const initialSteps: Step[] = [
    {
      content: <h2>Welcome to Systems Mapper!</h2>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: 'center',
      spotlightClicks: true,
      target: 'body',
    },
    {
      title: 'Node themes',
      content: (
        <div>
          Select any node themes, to identify similar node groups.
          <br></br>
        </div>
      ),
      disableOverlayClose: true,
      disableBeacon: false,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 20,
      target: '.categoryBar',
    },
    {
      content: (
        <div>
          The title of the node group broadly represents the theme of the nodes within the group.
          <br></br>
        </div>
      ),
      placement: 'bottom',
      styles: {
        options: {
          width: 300,
        },
      },
      floaterProps: { placement: 'auto' },
      target: '.sidenode',
      title: 'Node Group',
      disableScrolling: false,
    },
    {
      content: (
        <div>
          Click on the “⌵” icon to view the list of nodes under the node group.
          <br />
        </div>
      ),
      disableBeacon: false,
      disableOverlayClose: true,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 20,
      target: '.dropdown',
      title: 'Dropdown',
    },
    {
      content: (
        <div className="inline">
          Click on the “<BsBoxArrowInDownRight className="inline" />” icon to bring your preferred node to the center of the page.
          <br />
        </div>
      ),
      disableBeacon: false,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 20,
      target: '.bringCenter',
      title: 'Node group to center',
    },
    // {
    //   content: <h2>Centered Node</h2>,
    //   // floaterProps: {
    //   //   disableAnimation: true,
    //   // },
    //   placement: 'auto',
    //   spotlightPadding: 20,
    //   target: '.centeredNode',
    // },
    {
      title: 'Create a connection.',
      content: <h2>Open this node to create connection with centered node</h2>,
      disableBeacon: false,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      spotlightPadding: 20,
      target: '.anothersideNode',
    },
    {
      content: (
        <div>
          Click the “+” icon to start adding a new connection.
          <br />
        </div>
      ),
      disableBeacon: false,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '.addConnection',
      title: 'Add a new connection between nodes',
    },
    {
      content: (
        <div>
          Click on the <span className='font-medium text-red-500'>red</span> or <span className='font-medium text-black'>black</span> button, depending on the type of connection you would like to create, drag the link
          to a destination node* and drop it.
          <br></br>
          <span className='font-medium text-black'>Black – for a reinforcing link</span>
          <br></br>
          <span className='font-medium text-red-500'>Red – for a balancing link</span>
          <br></br>
          <br></br>
          (*Remember to open the destination node box before creating a connection)
          <br />
        </div>
      ),
      disableBeacon: false,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '.startConnection',
      title: 'Select the connection type between nodes',
    },
    {
      content: (
        <div>
          Click to end the link to the destination point of the target node.
          <br />
        </div>
      ),
      disableBeacon: false,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: 'right-end',
      placementBeacon : 'bottom-end',
      spotlightClicks: true,
      offset:30,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '.endConnection',
      title: 'Complete the connection between nodes',
    },
    {
      content: (
        <div>
          Click pin icon to highlight connections with that subnode .
          <br />
        </div>
      ),
      disableBeacon: false,
      disableOverlayClose: true,
      hideCloseButton: false,
      hideFooter: false,
      placement: 'bottom',
      spotlightClicks: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: '.pinsubnode',
      title: 'Pin subnode',
    },
    {
      title: 'Confidence rating',
      content: (
        <div>
          <h2>
            Provide a rating for the connection based on the level of confidence stakeholders have in the established
            link. Click on the green icon and select a rating from the following scale,
          </h2>
          <div className='mx-auto text-left w-min'>
          <span>0.1 – 0.3 low</span> <br></br>
          <span>0.4 – 0.6 Medium</span><br></br>
          <span>0.7 – 1.0 High</span><br></br>
          </div>
        </div>
      ),
      // floaterProps: {
      //   disableAnimation: true,
      // },
      disableBeacon: false,
      disableOverlayClose: true,
      placement: 'auto',
      spotlightPadding: 20,
      target: '.rateConnection',
    },
    {
      title: 'Add a new sub-node',
      content: <h2>Click the “+” icon to add a new sub-node </h2>,
      // floaterProps: {
      //   disableAnimation: true,
      // },
      disableBeacon: false,
      disableOverlayClose: true,
      placement: 'auto',
      spotlightPadding: 20,
      target: '.addsubNode',
    },
    {
      title: 'Close the node box',
      content: <h2>Click the “X” icon to close the node box and send it back to its original position
      </h2>,
      // floaterProps: {
      //   disableAnimation: true,
      // },
      disableBeacon: false,
      disableOverlayClose: true,
      placement: 'auto',
      spotlightPadding: 20,
      target: '.closeNode',
    },
    {
      title: 'Other features',
      content: (
        <div>
          <h2>Click here for more information on the rating scale, type of connections, and guiding questions for confidence rating</h2>
          <br />
        </div>
      ),
      // floaterProps: {
      //   disableAnimation: true,
      // },
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'auto',
      spotlightPadding: 20,
      target: '.helpButtons',
    },
    {
      title: 'Save and exit',
      content: (
        <div>
          <h2>Click on ‘Save & exit’ after completion</h2>
          <br />
        </div>
      ),
      // floaterProps: {
      //   disableAnimation: true,
      // },
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'auto',
      spotlightPadding: 20,
      target: '.savenexit',
    },
    {
      content: <h2>Lets start with the survey!</h2>,
      locale: { last: <strong aria-label="last"> Let’s begin!</strong> },
      placement: 'center',
      spotlightClicks: true,
      target: 'body',
    },
  ]
  // Joyride
  const [{ run, steps, stepIndex, go, next }, setState] = useState<{
    run: boolean
    steps: Step[]
    stepIndex: number
    go: (index: number) => void
    next: () => void
  }>({
    run: false,
    stepIndex: 1,
    go: () => {},
    next: () => {},
    steps: initialSteps,
  })

  return (
    <TourGuideContext.Provider
      value={{
        initialSteps,
        run,
        steps,
        stepIndex,
        go,
        next,
        setState,
      }}
    >
      {props.children}
    </TourGuideContext.Provider>
  )
}
