import React, { useState, useRef } from 'react'
import Toaster, { ToasterRef } from '../../Toaster/Toaster'
import mailer from "../../../services/mail.api"

const ContactUs = () => {
  const [contactusForm, setContactusForm] = useState<any>({})
  const [errors, setErrors] = useState<any>({})
  const toasterRef = useRef<any>(null)
  const handleChange = (e: any) => {
    setContactusForm((t: any) => ({ ...t, [e.target.name]: e.target.value }))
  }
  const validate = (): boolean => {
    let status = true
    if (!contactusForm?.email) {
      setErrors((e: any) => ({ ...e, email: 'Please enter email address.' }))
      status = false
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(contactusForm.email)) {
      setErrors((e: any) => ({ ...e, email: 'Please enter valid email address.' }))
      status = false
    }
    if (!contactusForm?.message) {
      setErrors((e: any) => ({ ...e, message: 'Please enter message.' }))
      status = false
    }
    if (!contactusForm?.name) {
      setErrors((e: any) => ({ ...e, name: 'Please enter name.' }))
      status = false
    }
    if (!status) {
      if (toasterRef.current) toasterRef.current.showToast('Please fill required fields to proceed.', 'error')
    }
    return status
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    console.log()
    if (validate()) {
      mailer.sendContactUsEmail(contactusForm).then((info)=>{
        // console.log(info);
        if(toasterRef.current) toasterRef.current.showToast('Email sent successfully', 'success')
        setContactusForm({})
      }).catch((error)=>{
        console.log(error);
        if (toasterRef.current) toasterRef.current.showToast('Unable to send mail, please try again later.', 'error')
      })
    }
  }
  return (
    <section className="section" id="contact-us">
      <Toaster ref={toasterRef} />
      <div className="w-9/12 mx-auto">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-xs-12">
            <div className="left-text-content">
              <div className="section-heading">
                <h6>Contact Us</h6>
                <p className='mt-4 font-medium'>Ready to create your own survey tool and extract valuable knowledge from your stakeholders?</p>
                <h2 className='!text-3xl leading-10 mt-3'>GET STARTED WITH SYSTEMS MAPPER TODAY!</h2>
              </div>
              <ul className="contact-info">
                <li className="flex items-center">
                  <img src="Assets/images/contact-info-01.png" alt="" />
                  010-020-0860
                </li>
                <li className="flex items-center">
                  <img src="Assets/images/contact-info-02.png" alt="" />
                  info@company.com
                </li>
                <li className="flex items-center">
                  <img src="Assets/images/contact-info-03.png" alt="" />
                  www.company.com
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 col-md-7 col-xs-12">
            <div className="mb-5 contact-form">
              <form id="contact" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input
                        name="name"
                        type="text"
                        id="name"
                        placeholder="Your Name *"
                        className={`rounded-lg ${
                          errors?.name ? '!border !border-red-400' : '!border !border-slate-400'
                        }`}
                        onChange={handleChange}
                        value={contactusForm?.name}
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input
                        name="phone"
                        type="text"
                        id="phone"
                        placeholder="Your Phone"
                        className={`rounded-lg ${
                          errors?.phone ? '!border !border-red-400' : '!border !border-slate-400'
                        }`}
                        onChange={handleChange}
                        value={contactusForm?.phone}
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input
                        name="email"
                        type="email"
                        id="email"
                        placeholder="Your Email *"
                        className={`rounded-lg ${
                          errors?.email ? '!border !border-red-400' : '!border !border-slate-400'
                        }`}
                        onChange={handleChange}
                        value={contactusForm?.email}
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input
                        name="subject"
                        type="text"
                        id="subject"
                        placeholder="Subject"
                        className={`rounded-lg ${
                          errors?.subject ? '!border !border-red-400' : '!border !border-slate-400'
                        }`}
                        onChange={handleChange}
                        value={contactusForm?.subject}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        rows={6}
                        id="message"
                        placeholder="Message *"
                        className={`rounded-lg ${
                          errors?.message ? '!border !border-red-400' : '!border !border-slate-400'
                        }`}
                        onChange={handleChange}
                        value={contactusForm?.message}
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button type="submit" id="form-submit" className="main-button-icon" onClick={handleSubmit}>
                        Send Message Now <i className="fa fa-arrow-right"></i>
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUs
