import React from 'react'
import { InputPropsType } from './Input.types'
import QuillEditor from './QuillEditor'

const TextEditor = (props: InputPropsType) => {
    const { type, title, defaultValue, name, onChange, inputClassName, labelClassName, value ,error, disabled, required, wrapperClassName} = props
    return (
      <div className={`relative w-auto tracking-wider ${wrapperClassName? wrapperClassName: ""}`}>
        <QuillEditor
          type={type}
          name={name}
          onChange={(content:string)=>onChange({target: {name : name||"", value: content}} as any)}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          theme="snow"
          className={`text-[#383635] bg-[#00000000] autofill:!bg-[#00000000] focus:outline-none w-full p-4 px-4 border border-slate-500 tracking-wide rounded-lg font-[Sora] font-normal ${inputClassName} ${error && 'border-red-500'}`}
        ></QuillEditor>
        <label
          htmlFor={title}
          className={`absolute font-[Sora] ${
            value ? 'top-[-12px] text-[#383635] ' : 'text-[#383635] top-[-4px]'
          } ${error && 'text-red-500'} ${labelClassName} left-4 w-fit h-fit inset-0 px-1 capitalize transition-all ease-in-out duration-200`}
        >
          {title}{required && <span className=""> *</span>}
        </label>
        <span className="px-2 my-1 text-xs text-red-500">{error}</span>
      </div>
    )
  }
export default TextEditor