import client from './client'
type contentType = {
 feedback: string,
 to:string
}

class MailService {
  sendFeedbackMAil(body:any) {
    return client.post('/mail',body)
  }
  sendContactUsEmail(body:any) {
  return client.post('/mail/contactus',body)
}
}
let mailer = new MailService()
export default mailer
