import React, { useState, useEffect, FC } from 'react'
import { SelectInput } from '../components/Input'
import Input from '../components/Input'
import content from '../services/content.api'
import { readExcel, getObjKey } from '../lib/utils'
import { HiCheckCircle } from 'react-icons/hi'

const UploadSheet: FC<{ setSelectedOption: React.Dispatch<string>; sidebarOptions: Array<{ id: string }> }> = ({
  setSelectedOption,
  sidebarOptions,
}) => {
  const [contentData, setContentData] = useState<any>({})
  const [html, setHtml] = useState<any>('')
  const [json, setJson] = useState<any>({})
  const [uploadOption, setuploadOption] = useState<string>('add')
  const [projectData, setProjectData] = useState<any>({ name: '', errors: '' })
  const [distinctContents, setDistinctContents] = useState<any>(null)
  const getDistinctContents = () => {
    content
      .getDistinctContents()
      .then((res) => {
        if (res.data.data) {
          setDistinctContents(
            res.data.data.map((item: any) => {
              return { title: item.projectName, value: item.contentId }
            }),
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    if (json && json.length) {
      let content: any = { nodes: [] }
      let currNode: any = {}
      let nodeCount = 1
      let subNodeCount = 1
      let schema: any = {}
      // To create schema
      json.map((item: any, i: number) => {
        if (item[`__EMPTY`] !== '|') {
          if (currNode.id) {
            schema[`sn-${nodeCount}|${subNodeCount}`] = item[`__EMPTY`]
            subNodeCount++
          } else {
            currNode.id = `n-${nodeCount}`
            schema[`n-${nodeCount}`] = item[`__EMPTY`]
          }
        } else {
          nodeCount++
          subNodeCount = 1
          currNode = {}
        }
      })
      currNode = {}
      nodeCount = 1
      subNodeCount = 1
      // To create nodes,subnodes and add connections
      json.map((item: any, i: number) => {
        if (item[`__EMPTY`] !== '|') {
          if (currNode.id) {
            if (currNode.nodes) {
              let tmp: any = { id: `sn-${nodeCount}|${subNodeCount}`, title: item[`__EMPTY`] }
              if (Object.keys(item).length > 1) {
                tmp.connections = []
                Object.keys(item).forEach((key) => {
                  if (key !== '__EMPTY') tmp.connections.push({ id: getObjKey(schema, key), value: String(item[key]) })
                })
              }
              currNode.nodes.push(tmp)
            } else {
              let tmp: any = { id: `sn-${nodeCount}|${subNodeCount}`, title: item[`__EMPTY`] }
              if (Object.keys(item).length > 1) {
                tmp.connections = []
                Object.keys(item).forEach((key) => {
                  if (key !== '__EMPTY') tmp.connections.push({ id: getObjKey(schema, key), value: String(item[key]) })
                })
              }
              currNode.nodes = [tmp]
            }
            subNodeCount++
          } else {
            currNode.id = `n-${nodeCount}`
            currNode.title = item[`__EMPTY`]
          }
        } else {
          nodeCount++
          subNodeCount = 1
          content.nodes.push(currNode)
          currNode = {}
        }
      })
      if (currNode.id) {
        content.nodes.push(currNode)
        nodeCount = 0
        subNodeCount = 1
        currNode = {}
      }
      console.log(content)
      setContentData({ ...content, meta: { schema }, createdOn: new Date(), surveyName: 'Test' })
    }
    getDistinctContents()
  }, [json])
  const AddSurvey = () => {
    if (uploadOption === 'add') {
      if (!projectData?.name || projectData.name === '') {
        setProjectData({ error: 'Please Enter Project Name' })
      } else {
        content
          .addContent({ projectName: projectData?.name, ...contentData })
          .then((res) => {
            console.log('res', res)
            if (res?.data?.contentId) {
              setSelectedOption(sidebarOptions?.[1]?.id)
            }
          })
          .catch((err) => {
            console.log('err', err)
          })
      }
    } else if (uploadOption === 'edit') {
      if (!projectData?.refContent) {
        setProjectData({ error: 'Please Select Project' })
      } else {
        content
          .addExistingContent({ refContent: projectData?.refContent, ...contentData })
          .then((res) => {
            console.log('res', res)
            if (res?.data?.contentId) {
              setSelectedOption(sidebarOptions?.[1]?.id)
            }
          })
          .catch((err) => {
            console.log('err', err)
          })
      }
    }
  }
  const handleInputChange = (e: any) => {
    setProjectData((t: any) => {
      return { ...t, error: '', name: e.target.value }
    })
  }
  const handleProjectChange = (e: any) => {
    // console.log(e.target.value)
    setProjectData((t: any) => {
      return { ...t, error: '', refContent: parseInt(e.target.value) }
    })
  }
  return (
    <>
      <div className="relative flex justify-between w-full p-4 px-5 border-2 border-dashed rounded-xl border-slate-400">
        <span className="font-[Sora] text-lg absolute top-0 -translate-y-1/2 px-2 bg-[#f3f7ff]">Upload datasheet</span>
        <div className="">
          <div className="my-2 text-base font-normal font-[Sora]">{/* Choose an excel file */}</div>
          <input
            type="file"
            placeholder="Upload yor excel file here..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files?.[0]
              readExcel({ file, setResultantHtml: setHtml, setResultantJson: setJson })
            }}
          />
        </div>
        <div className="w-1 border-r border-slate-400"></div>
        {json && json.length && (
          <>
            <div className="flex flex-col gap-3 px-5">
              <div className="my-2 text-base font-normal font-[Sora]">Select option to upload</div>
              <div className="flex items-center gap-3">
                <span
                  className={`px-3 cursor-pointer relative py-1 border border-gray-400 ${
                    uploadOption === 'add' ? 'border-b-2 border-b-[color:var(--border-main)]' : ''
                  }`}
                  onClick={() => setuploadOption('add')}
                >
                  {uploadOption === 'add' && (
                    <div className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
                      <HiCheckCircle className="text-[color:var(--background-button)] bg-[#f3f4f7]" />
                    </div>
                  )}
                  Add new
                </span>
                <span
                  className={`px-3 cursor-pointer relative py-1 border border-gray-400 ${
                    uploadOption === 'edit' ? 'border-b-2 border-b-[color:var(--border-main)]' : ''
                  }`}
                  onClick={() => setuploadOption('edit')}
                >
                  {uploadOption === 'edit' && (
                    <div className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
                      <HiCheckCircle className="text-[color:var(--background-button)] bg-[#f3f4f7]" />
                    </div>
                  )}
                  Edit existing
                </span>
              </div>
            </div>
            <div className="w-1 border-r border-slate-400"></div>
            <div className="flex items-center justify-center gap-5 px-5">
              {uploadOption === 'add' ? (
                <div className="my-3 min-w-[230px]">
                  <Input
                    type={'text'}
                    title={'Project Name'}
                    defaultValue={''}
                    name={'projectName'}
                    onChange={handleInputChange}
                    inputClassName={''}
                    labelClassName={'bg-[#f3f7ff]'}
                    value={projectData?.name}
                    error={projectData?.error}
                  />
                </div>
              ) : uploadOption === 'edit' ? (
                <div className="my-3 min-w-[230px]">
                  <SelectInput
                    title={'Projects'}
                    name={'projects'}
                    options={distinctContents}
                    error={projectData?.error}
                    handleChange={handleProjectChange}
                    labelClass={'bg-[#d2e4d8]'}
                    optionsClass={'bg-[#d2e4d8]'}
                  ></SelectInput>
                </div>
              ) : (
                <></>
              )}
              <div className="flex flex-col items-center justify-center gap-3">
                <span className="my-2 text-base font-normal font-[Sora]">Save survey?</span>
                <button
                  className="p-1.5 px-5 rounded-md bg-button text-button font-[Ubuntu]  bg-[color:var(--background-button)] font-normal tracking-wide text-white "
                  onClick={AddSurvey}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="my-[50px]">
        {html && <div className="font-[Sora] text-lg p-2">Preview</div>}
        <div className="mx-auto overflow-auto h-[600px] p-3">
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </div>
      </div>
    </>
  )
}

export default UploadSheet
