import React, { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { SurveyContext } from '../Survey/SurveyContext'
import TopicCard from '../../components/TopicCard'
import Modal from '../../components/Modal'

const TopicList = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { loading, contentData, getContent, surveyData, setSurveyData, setCenteredNode } = useContext(SurveyContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<any>({})

  const showDetails = (node: any) => {
    setModalContent(node)
    setShowModal(true)
  }
  const startSurvey = (NodeId: any) => {
    setCenteredNode(NodeId)
    navigate(`/${id}/survey`)
  }
      
  useEffect(() => {
    if (!loading && !contentData?.surveyName && id) {
      getContent(id)
    }
  }, [id, loading])
  return (
    <div className="w-full">
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
            setModalContent({})
          }}
        >
          <div className="flex flex-col gap-5 p-6 lg:flex-row">
            <div className="w-full lg:w-[60%] h-[500px]">
              <img
                src={modalContent?.image?.src}
                className="object-cover object-center w-full h-full z-[-10]"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src =
                    'https://st.depositphotos.com/1605581/2301/i/600/depositphotos_23019286-stock-photo-gray-abstract-background.jpg'
                }}
              ></img>
            </div>
              <div className="mx-auto space-x-2">
                <div className='text-[30px]'>{modalContent?.title}</div>
                <div className="my-4 text-sm font-normal">{modalContent?.description}</div>
                <ul className='space-x-2 text-base font-normal'>
                  <span>Sub-Nodes:</span>
                  {
                    modalContent?.nodes?.map((node:any,i:number)=>{
                      return <li key={i} className="my-2 text-sm">{node?.title}</li>
                    })
                  }
                </ul>
              </div>
          </div>
        </Modal>
      )}
      <div className="w-full mx-auto my-8 text-[32px] font-bold text-center"> Major areas for Analysis</div>
      <div className="grid grid-cols-3 w-[80%] mx-auto">
        {contentData?.nodes?.map((node: any, index: number) => {
          return <TopicCard node={node} key={index} showDetails={showDetails} startSurvey={startSurvey}></TopicCard>
        })}
      </div>
    </div>
  )
}
export default TopicList;