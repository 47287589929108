import React from 'react'

const SubNodesIcon = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.38233 13.4304C9.38233 13.1517 9.15637 12.9257 8.87764 12.9257H5.67977C4.65864 12.9257 3.82849 13.7539 3.82849 14.777V17.8638C5.28433 18.0231 6.40003 19.3098 6.29001 20.8297C6.18974 22.2149 5.03529 23.3353 3.64785 23.3973C2.05632 23.4683 0.743652 22.199 0.743652 20.6232C0.743652 19.415 1.51729 18.3861 2.59493 18.0041V14.777C2.59493 13.0733 3.97606 11.6922 5.67977 11.6922H14.0105C15.0336 11.6922 15.8618 10.862 15.8618 9.8409V6.46186H15.5539C14.5315 6.46186 13.7026 5.63301 13.7026 4.61057V2.45138C13.7026 1.42894 14.5315 0.600098 15.5539 0.600098H17.4052C18.4276 0.600098 19.2565 1.42894 19.2565 2.45138V4.61057C19.2565 5.63301 18.4276 6.46186 17.4052 6.46186H17.0973V17.9145C18.4232 18.2156 19.39 19.4683 19.2414 20.9164C19.1031 22.2644 17.9668 23.3335 16.6132 23.397C15.0188 23.4717 13.7026 22.2011 13.7026 20.6232C13.7026 19.3019 14.6263 18.1951 15.8618 17.9145V13.1687C15.8618 12.816 15.5057 12.5628 15.1794 12.6966C14.8193 12.8443 14.4246 12.9257 14.0105 12.9257H11.1225C10.8438 12.9257 10.6178 13.1517 10.6178 13.4304V17.9145C11.9437 18.2156 12.9105 19.4683 12.7619 20.9164C12.6236 22.2644 11.4873 23.3335 10.1337 23.397C8.5393 23.4717 7.22314 22.2011 7.22314 20.6232C7.22314 19.3019 8.14683 18.1951 9.38233 17.9145V13.4304Z"
        fill="white"
      />
    </svg>
  )
}

export default SubNodesIcon
