import React, { useState, useEffect, useContext } from 'react'
import content from '../services/content.api'
import { AiFillDatabase } from 'react-icons/ai'
import { IoGlobeSharp } from 'react-icons/io5'
import { BiCategoryAlt, BiDetail } from 'react-icons/bi'
import CategoriesSelection from './CategoriesSelection'
import ProjectInfoSelection from './ProjectInfoSelection'
import { LoginContext } from '../context/LoginContext'
import { DateConvertor } from '../lib/utils'
import UserFormCreation from './UserFormCreation'
import { RiDashboardFill  } from "react-icons/ri";

const AllProjects = () => {
  const [loading, setLoading] = useState(false)
  const [selectedProject, setSelectedProject] = useState('')
  const [editType, setEditType] = useState('')
  const [distinctContents, setDistinctContents] = useState<any>(null)
  const [allProjects, setAllProjects] = useState([])
  const [otherClientsProject, setOtherClientsProjects] = useState([])
  const { user } = useContext(LoginContext)
  // handlechange of project selected
  const handleProjectChange = (type: string, cat: any) => () => {
    setEditType(type)
    setSelectedProject(cat)
  }
  const getDistinctContents = () => {
    content
      .getDistinctContents()
      .then((res) => {
        if (res.data.data) {
          setDistinctContents(
            res.data.data.map((item: any) => {
              return { title: item.projectName, value: item.contentId }
            }),
          )
          setAllProjects(res.data.data)
          setOtherClientsProjects(res.data.otherProjects)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    getDistinctContents()
  }, [])
  return (
    <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
      {!selectedProject && (
        <div className="">
          <table className="w-full text-left">
            <thead className="!bg-[#304164] text-base text-white" style={{ fontFamily: 'Lexend Deca' }}>
              <tr className="!bg-[#304164] text-left">
                <td className="px-4 text-left min-w-[50px]">Project Id</td>
                <td className="px-4 text-left min-w-[130px]">Project name</td>
                <td className="px-4 text-center max-w-[70px]">Created at</td>
                <td className="px-4 text-center max-w-[90px]">Edit categories</td>
                <td className="px-4 text-center max-w-[70px]">User Form</td>
                <td className="px-4 text-center max-w-[70px]">Project info</td>
                <td className="px-4 text-center max-w-[70px]">Survey link</td>
                <td className="px-4 text-center max-w-[70px]">Statistics</td>
              </tr>
            </thead>
            <tbody>
              {!allProjects?.length && (
                <tr>
                  <td colSpan={6} className="mx-auto text-center">
                    {' '}
                    No Projects
                  </td>
                </tr>
              )}
              {allProjects?.map((project: any, index: number) => {
                return (
                  <tr key={index}>
                    <td className="px-4 text-left"> {project.contentId}</td>
                    <td className="px-6 text-left"> {project.projectName}</td>
                    <td className="px-6 text-center">
                      {' '}
                      {project?.createdOn ? DateConvertor(new Date(project?.createdOn), 'ddmmyyyy').toString() : '-'}
                    </td>
                    <td className="px-3">
                      <div className="flex justify-center gap-3 items center">
                        <span
                          className="cursor-pointer hover:scale-110"
                          onClick={handleProjectChange('category', project.contentId)}
                        >
                          <BiCategoryAlt />
                        </span>
                      </div>
                    </td>
                    <td className="px-3">
                      <div className="flex justify-center gap-3 items center">
                        <span
                          className="cursor-pointer hover:scale-110"
                          onClick={handleProjectChange('userform', project.contentId)}
                        >
                          <BiDetail   />
                        </span>
                      </div>
                    </td>
                    <td className="px-3">
                      <div className="flex justify-center gap-3 items center">
                        <span
                          className="cursor-pointer hover:scale-110"
                          onClick={handleProjectChange('projectInfo', project.contentId)}
                        >
                          <AiFillDatabase className="" />
                        </span>
                      </div>
                    </td>
                    <td className="px-3 text-left">
                      <div className="flex items-center justify-center">
                        <a href={`/${project.contentId}`} className="hover:scale-110" target="_blank">
                          {' '}
                          <IoGlobeSharp />{' '}
                        </a>
                      </div>
                    </td>
                    <td className="px-3 text-left">
                      <div className="flex items-center justify-center">
                        <a href={`/admin/statistics/${project.contentId}`} className="hover:scale-110" target="_blank">
                          {' '}
                          <RiDashboardFill />{' '}
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* Other clients Project */}

      {!selectedProject && user.role === 'superadmin' && (
        <div className="mb-10">
          <div className="mt-[50px]">
            <h1 className="my-4 text-lg">All Projects</h1>
          </div>
          <table className="w-full text-left">
            <thead className="!bg-[#304164] text-base text-white" style={{ fontFamily: 'Lexend Deca' }}>
              <tr className="!bg-[#304164] text-left">
                <td className="px-4 text-left min-w-[50px]">Project Id</td>
                <td className="px-4 text-left min-w-[150px]">Project name</td>
                <td className="px-4 text-center max-w-[70px]">Created at</td>
                {/* <td className="px-4 text-center max-w-[70px]">Edit Category</td> */}
                {/* <td className="px-4 text-center max-w-[70px]">Project Info</td> */}
                <td className="px-4 text-center max-w-[70px]">Client</td>
                <td className="px-4 text-center max-w-[60px]">Survey link</td>
              </tr>
            </thead>
            <tbody>
              {!otherClientsProject?.length && (
                <tr>
                  <td colSpan={6} className="mx-auto text-center">
                    {' '}
                    No Projects
                  </td>
                </tr>
              )}
              {otherClientsProject?.map((project: any, index: number) => {
                return (
                  <tr key={index}>
                    <td className="px-4 text-left"> {project.contentId}</td>
                    <td className="px-6 text-left"> {project.projectName}</td>
                    <td className="px-6 text-center">
                      {' '}
                      {project?.createdOn ? DateConvertor(new Date(project?.createdOn), 'ddmmyyyy').toString() : '-'}
                    </td>
                    <td className="px-6 text-left"> {project.client.clientName}</td>
                    {/* <td className="px-3">
                      <div className="flex justify-center gap-3 items center">
                        <span
                          className="cursor-pointer hover:scale-110"
                          onClick={handleProjectChange('category', project.contentId)}
                        >
                          <BiCategoryAlt />
                        </span>
                      </div>
                    </td> */}
                    {/* <td className="px-3">
                      <div className="flex justify-center gap-3 items center">
                        <span
                          className="cursor-pointer hover:scale-110"
                          onClick={handleProjectChange('projectInfo', project.contentId)}
                        >
                          <AiFillDatabase className="" />
                        </span>
                      </div>
                    </td> */}
                    <td className="px-3 text-left">
                      <div className="flex items-center justify-center">
                        <a href={`/${project.contentId}`} className="hover:scale-110" target="_blank">
                          {' '}
                          <IoGlobeSharp />{' '}
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {selectedProject && editType === 'category' ? (
        <CategoriesSelection
          distinctContents={distinctContents}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setEditType={setEditType}
        ></CategoriesSelection>
      ) :editType === 'userform' ? (
        <UserFormCreation
          distinctContents={distinctContents}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setEditType={setEditType}
        ></UserFormCreation>
      ) : editType === 'projectInfo' ? (
        <ProjectInfoSelection
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          setEditType={setEditType}
        ></ProjectInfoSelection>
      ) : (
        <></>
      )}
    </div>
  )
}

export default AllProjects
