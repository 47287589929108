import React from 'react'
import {FaFacebookF ,FaTwitter, FaLinkedinIn, FaDribbble} from "react-icons/fa"
const Footer = () => {
  return (
    <footer>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-xs-12">
          <div className="left-text-content">
            <p>
              Copyright &copy; System Mapper
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-xs-12">
          <div className="right-text-content">
            <ul className="social-icons">
              <li className='px-1'>
                <p>Follow Us</p>
              </li>
              <li className='px-1'>
                <a rel="nofollow" href="https://fb.com/templatemo" className='group'>
                 <FaFacebookF className='text-[#5fb759] group-hover:text-white'  style={{display:"inherit"}} />
                </a>
              </li>
              <li className='px-1'>
                <a rel="nofollow" href="https://fb.com/templatemo" className='group'>
                  <FaTwitter className='text-[#5fb759] group-hover:text-white'  style={{display:"inherit"}}></FaTwitter>
                </a>
              </li>
              <li className='px-1'>
                <a rel="nofollow" href="https://fb.com/templatemo" className='group'>
                <FaLinkedinIn className='text-[#5fb759] group-hover:text-white'  style={{display:"inherit"}}></FaLinkedinIn>
                </a>
              </li>
              <li className='px-1'>
                <a rel="nofollow" href="https://fb.com/templatemo" className='group'>
                <FaDribbble className='text-[#5fb759] group-hover:text-white'  style={{display:"inherit"}}></FaDribbble>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer