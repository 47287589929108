import React from 'react';
import xlsx from "json-as-xlsx";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { ConnectionType, NodeType, SubNodeType } from '../pages/Tool/Tool.types';
import { StatType } from '../components/Statistics';

export const TwoDArrayToJson =(twoDArray:Array<Array<number|string>>):any => {
    console.log(twoDArray)
    return [...twoDArray]
}

let settings = {
    fileName: "MySpreadsheet", // Name of the resulting spreadsheet
    extraLength: 6, // A bigger number means that columns will be wider
    writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  }
export const getxlsx = (data:any,fileName?:string)=>{
    console.log('callclsx', fileName, data)
   return xlsx(data, {...settings, ...(fileName?{fileName:fileName}:{})})
  }

  type ReadXlsX ={
    file:any
    setResultantJson?:React.SetStateAction<any>
    setResultantHtml?:React.SetStateAction<any>
  }

export const readExcel = ({file, setResultantJson, setResultantHtml }:ReadXlsX) => {
    console.log("file",file)
    const promise = new Promise((resolve, reject) => {
      const fileReader:any = new FileReader();
      fileReader.readAsArrayBuffer(file);
    
      fileReader.onload = (e:any) => {
        const bufferArray = e.target?.result;
    
        const wb = XLSX.read(bufferArray, { type: "buffer",cellFormula:true });
        console.log("wb",wb)
        const wsname = wb.SheetNames[0];
    
        const ws = wb.Sheets[wsname];
    
        const json = XLSX.utils.sheet_to_json(ws);
        const html = XLSX.utils.sheet_to_html(ws);
        const txt = XLSX.utils.sheet_to_formulae(ws);
        console.log("txt:",txt)
        
        resolve({json,html});
      };
    
      fileReader.onerror = (error:any) => {
        reject(error);
      };
    });
    promise.then((d:any) => {
      if(d.json && setResultantJson){
        setResultantJson(d.json)
      }
      if(d.json && setResultantHtml){
        setResultantHtml(d.html)
      }
    }).catch((err)=>{
      console.log({err})
    })
    }

export const getObjKey =(obj:any, value:string) =>{
  if(value.split("_").length > 1 ){
    if( !isNaN(parseInt(value.split("_")[1]))){
      let arr = Object.keys(obj).filter(key => obj[key] === value.split("_")[0])
      console.log(arr,"arr")
      return arr[parseInt(value.split("_")[1])];
    }else{
      return Object.keys(obj).find(key => obj[key] === value);
    }
  }else{
    return Object.keys(obj).find(key => obj[key] === value);
  }
}

export const DateConvertor = (date: Date, format:"time" | "date" | "ddmmyyyy", type?:"start" | "end" | "beginningOfMonth" | "endOfMonth") => {
  switch (format) {
    case 'time':
      return new Date(convertTime(date, type)).getTime()
    case 'date':
      return new Date(convertTime(date, type))
    case 'ddmmyyyy':
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear());
      return `${day}/${month}/${year}`;
    default:
      return date
  }
}

export const convertTime = (date :Date | number, type ?:"start" | "end" | "beginningOfMonth" | "endOfMonth") => {
  const d = new Date(date)
  const timezoneOffset = d.getTimezoneOffset()
  if (type === 'start') {
    d.setUTCHours(0)
    d.setUTCMinutes(0)
    d.setUTCSeconds(0)
  } else if (type === 'end') {
    d.setUTCHours(23)
    d.setUTCMinutes(59)
    d.setUTCSeconds(59)
  } else if (type === 'beginningOfMonth') {
    d.setDate(1)
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
  } else if (type === 'endOfMonth') {
    d.setDate(1) // Avoids edge cases on the 31st day of some months
    d.setMonth(d.getMonth() + 1)
    d.setDate(0)
    d.setHours(23)
    d.setMinutes(59)
    d.setSeconds(59)
  }

  return d
}
export type SurveyData = {
  name : string
  nodes : Array<{title:string, id:string, nodes : Array<{title:string, id:string, connections:Array<{id:string, value?:string, notes?:string, count : string|number}>}>}>
} 

export const getSourceTargetData = (surveyData:SurveyData)=>{
  const schema = surveyData.nodes.reduce((acc : {[key:string] : string}, node)=>{
     node.nodes.map((subnode)=>{
         acc[subnode.id] = subnode.title
     })
     return acc 
  },{})
  const result = surveyData.nodes.reduce((acc:Array<{source:string, target:string, value:string, notes:string, count : string|number
  }>, node)=>{
      node.nodes.map((subnode)=>{
          if(subnode.connections){
              subnode.connections.map((con)=>{
                  acc.push({source:subnode.title, target:schema[con.id], value: removeChars(con.value)||"", notes:con?.notes || '', count: con?.count||""})
              })
          }
      })
      return acc
  },[])
  return result
}
const removeChars =(value?:string)=>{
  if(value){
    if(value ==="b" || value === "B" || value === "r" || value === "R"){
      return ""
    }else return value
  }else return ""
}

export const downloadPdf = (reportTemplateRef:React.RefObject<HTMLDivElement>, fileName:string) =>{
  const doc = new jsPDF({
    format: 'a4',
    unit: 'px',
    precision : 100
  });

  // Adding the fonts.
  doc.setFont('Inter-Regular', 'normal');

  if(reportTemplateRef?.current) doc.html(reportTemplateRef.current, {
    async callback(doc) {
      await doc.save(fileName);
    },
  });
}

export const debounce = <T extends (...args: any[]) => any>(
  fn: T,
  delay = 300
) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};



// export const statisticsAnalyzeData = (data : Array<NodeType>): StatType => {
//   let totalNodes = data.length;
//   let totalSubNodes = 0;
//   let totalReinforcingConnections = 0;
//   let totalBalancingConnections = 0;
//   let totalConnections = 0;
//   let allConnections:any[] = [];

//   // Helper function to get the connection details
//   function getConnectionDetails(node : NodeType, subNode : SubNodeType, connection :ConnectionType , connectedNode:NodeType, connectedSubNode?: SubNodeType) {
//       return {
//           start: {
//               node: node.title,
//               nodeId: node.id,
//               subNode: subNode.title,
//               subNodeId: subNode.id,
//           },
//           end: {
//               node: connectedNode.title,
//               nodeId: connectedNode.id,
//               subNode: connectedSubNode?.title,
//               subNodeId: connectedSubNode?.id,
//           },
//           value : connection.value
//       };
//   }

//   data.forEach(node => {
//       totalSubNodes += node?.nodes?.length || 0;

//       node?.nodes?.forEach(subNode => {
//           if (subNode.connections) {
//               subNode.connections.forEach(connection => {
//                   totalConnections++;

//                   let value = connection.value;
//                   if (typeof value === 'string') {
//                       if (value.toLowerCase() === 'r') {
//                           totalReinforcingConnections++;
//                       } else if (value.toLowerCase() === 'b') {
//                           totalBalancingConnections++;
//                       }
//                   } else if (typeof value === 'number') {
//                       if (value >= 0) {
//                           totalReinforcingConnections++;
//                       } else {
//                           totalBalancingConnections++;
//                       }

//                       // Track all connections with their ratings for sorting
//                       let connectedNode = data.find(n => n?.nodes?.some(sn => sn.id === connection.id));
//                       if (connectedNode) {
//                           let connectedSubNode = connectedNode?.nodes?.find(sn => sn.id === connection.id);
//                           allConnections.push({
//                               value: value,
//                               details: getConnectionDetails(node, subNode, connection, connectedNode, connectedSubNode) as any
//                           });
//                       }
//                   }
//               });
//           }
//       });
//   });

//   // Sort connections to get highest and lowest
//   let highestConList = allConnections.sort((a, b) => b.value - a.value).slice(0, 10).map(c => c.details);
//   let lowestConList = allConnections.sort((a, b) => a.value - b.value).slice(0, 10).map(c => c.details);

//   return {
//       totalNodes: totalNodes,
//       totalSubNodes: totalSubNodes,
//       totalReinforcingConnections: totalReinforcingConnections,
//       totalBalancingConnections: totalBalancingConnections,
//       totalConnections: totalConnections,
//       highestConList: highestConList,
//       lowestConList: lowestConList,
//   };
// }

export const statisticsAnalyzeData = (data: Array<NodeType>, totalSurveys:number): StatType => {
  let totalNodes = data.length;
  let totalSubNodes = 0;
  let totalReinforcingConnections = 0;
  let totalBalancingConnections = 0;
  let totalConnections = 0;
  let totalSurveyDone = totalSurveys || 0;
  let allConnections: any[] = [];

  // Create objects to track incoming/outgoing/total connections for each node and subnode
  let nodeConnections: Record<string, { totalIncomingConnections: number; totalOutGoingConnections: number; totalConnections: number }> = {};
  let subNodeConnections: Record<string, { totalIncomingConnections: number; totalOutGoingConnections: number; totalConnections: number }> = {};

  // Helper function to get the connection details
  function getConnectionDetails(node: NodeType, subNode: SubNodeType, connection: ConnectionType, connectedNode: NodeType, connectedSubNode?: SubNodeType) {
    return {
      start: {
        node: node.title,
        nodeId: node.id,
        subNode: subNode.title,
        subNodeId: subNode.id,
      },
      end: {
        node: connectedNode.title,
        nodeId: connectedNode.id,
        subNode: connectedSubNode?.title,
        subNodeId: connectedSubNode?.id,
      },
      value: connection.value
    };
  }

  // Initialize nodeConnections and subNodeConnections for each node and subnode
  data.forEach(node => {
    nodeConnections[node.id] = { totalIncomingConnections: 0, totalOutGoingConnections: 0, totalConnections: 0 };
    node?.nodes?.forEach(subNode => {
      subNodeConnections[subNode.id] = { totalIncomingConnections: 0, totalOutGoingConnections: 0, totalConnections: 0 };
    });
  });

  // Iterate through nodes and subnodes to count connections and track details
  data.forEach(node => {
    totalSubNodes += node?.nodes?.length || 0;

    node?.nodes?.forEach(subNode => {
      if (subNode.connections) {
        subNode.connections.forEach(connection => {
          totalConnections++;

          let value = connection.value;
          if (typeof value === 'string') {
            if (value.toLowerCase() === 'r') {
              totalReinforcingConnections++;
            } else if (value.toLowerCase() === 'b') {
              totalBalancingConnections++;
            }
          } else if (typeof value === 'number') {
            if (value >= 0) {
              totalReinforcingConnections++;
            } else {
              totalBalancingConnections++;
            }

            // Track outgoing connections from the current node/subnode
            nodeConnections[node.id].totalOutGoingConnections++;
            subNodeConnections[subNode.id].totalOutGoingConnections++;

            // Find the connected node and subnode
            let connectedNode = data.find(n => n?.nodes?.some(sn => sn.id === connection.id));
            if (connectedNode) {
              let connectedSubNode = connectedNode?.nodes?.find(sn => sn.id === connection.id);

              // Track incoming connections for the connected node/subnode
              nodeConnections[connectedNode.id].totalIncomingConnections++;
              subNodeConnections[connectedSubNode!.id].totalIncomingConnections++;

              // Add to the list of all connections
              allConnections.push({
                value: value,
                details: getConnectionDetails(node, subNode, connection, connectedNode, connectedSubNode) as any
              });
            }
          }
        });
      }
    });
  });

  // Sort connections to get highest and lowest
  let highestConList = allConnections.sort((a, b) => b.value - a.value).slice(0, 10).map(c => c.details);
  let lowestConList = allConnections.sort((a, b) => a.value - b.value).slice(0, 10).map(c => c.details);

  // Calculate total connections for each node and subnode
  Object.keys(nodeConnections).forEach(nodeId => {
    nodeConnections[nodeId].totalConnections = nodeConnections[nodeId].totalIncomingConnections + nodeConnections[nodeId].totalOutGoingConnections;
  });

  Object.keys(subNodeConnections).forEach(subNodeId => {
    subNodeConnections[subNodeId].totalConnections = subNodeConnections[subNodeId].totalIncomingConnections + subNodeConnections[subNodeId].totalOutGoingConnections;
  });

  return {
    totalNodes: totalNodes,
    totalSubNodes: totalSubNodes,
    totalReinforcingConnections: totalReinforcingConnections,
    totalBalancingConnections: totalBalancingConnections,
    totalConnections: totalConnections,
    highestConList: highestConList,
    lowestConList: lowestConList,
    totalSurveyDone : totalSurveyDone,
    connectionsPerSurvey : parseFloat((totalConnections / (totalSurveyDone || 1))?.toFixed(2)),
    nodeConnections: nodeConnections,  // Export node connection stats
    subNodeConnections: subNodeConnections,  // Export subnode connection stats
  };
};
