import React, { useState, useRef, useEffect } from 'react'
import Xarrow, { useXarrow } from 'react-xarrows'
import Draggable from 'react-draggable'
import Rating from '../components/Rating'
import { FaPlus, FaChevronDown } from 'react-icons/fa'
import Input from './Input'
import { AiFillPushpin, AiOutlinePushpin } from 'react-icons/ai'
import { BsBoxArrowInDownRight, BsBoxArrowInDownLeft } from 'react-icons/bs'
import { VscChromeClose } from 'react-icons/vsc'
import { MdClose } from 'react-icons/md'
import { BiPlus } from 'react-icons/bi'
import { HiPlus } from 'react-icons/hi'
import { ratingConstants } from '../lib/constants'

export const Table = ({
  node,
  cursor,
  setCursor,
  addingNodes,
  addedNodes,
  addConnection,
  position,
  centeredNode,
  centerNodeData,
  setCenterNodeData,
  editor,
  setEditor,
  addSubNode,
  openAccordion,
  setOpenAccordion,
  fixed,
  setFixed,
  leftNode,
  setCenteredNode,
  selectedSubnodes,
  setSelectedSubnodes,
  changeNode,
  selectedCategory,
  openAccordionData,
  setOpenAccordionData,
  tourguideNode,
  setRerender
}: any) => {
  const updateXarrow = useXarrow()
  const [dragging, setDragging] = useState<boolean>(false)
  const [addingSubnode, setAddingSubnode] = useState<boolean>(false)
  const [inputValues, setInputValues] = useState<Array<string>>([''])
  const tableRef = useRef<any>(null)
  const [fields, setFields] = useState<Array<any>>([{ type: 'text', title: 'Title', value: '', name: 'title' }])
  let timerRef: any = null
  const subnodes =
    node?.nodes?.reduce((obj: any, cur: any) => {
      obj[cur.id] = cur
      return obj
    }, {}) || {}

  // Accordion
  // const contentEl = useRef<HTMLDivElement>(null)
  const [childHeight, setChildHeight] = useState<number>(node.nodes?.length * 33)

  useEffect(() => {
    // if (contentEl.current) setChildHeight(contentEl.current.scrollHeight)
    if (openAccordion === node.id) {
      setChildHeight(10)
      setTimeout(() => {
        // setChildHeight(node.nodes.length/2*33)
        setChildHeight(node.nodes?.length * 33)
      }, 100)
    } else if (node.id === centeredNode) {
      setChildHeight(node.nodes?.length * 33)
    } else {
      setChildHeight(0)
    }
  }, [openAccordion])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(e: any) {
      if (tableRef.current && !tableRef.current?.contains(e.target)) {
        // setCursor({})
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('onscroll', updateXarrow)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('onscroll', updateXarrow)
    }
  }, [tableRef, tableRef.current])

  const handleInputChange = (e: any, index: number) => {
    let subnodes = [...inputValues]
    subnodes[index] = e.target.value
    setInputValues(subnodes)
  }
  const handleScroll = () => {
    updateXarrow()
  }

  const handleAccordion = () => {
    if (node.id !== centeredNode) {
      if (openAccordionData) changeNode(centerNodeData, openAccordionData, 'openAccordion')
      setFixed((e: any) => {
        let { start, end } = e
        return { ...(start ? { start: start } : {}) }
      })
      setOpenAccordion((e: string) => {
        if (node.id === e) {
          return ''
        } else {
          return node.id
        }
      })
    }
  }

  return (
    // <Draggable
    //   onDrag={() => {
    //     updateXarrow()
    //     setDragging(true)
    //   }}
    //   onStop={() => {
    //     updateXarrow()
    //     setDragging(false)
    //   }}
    // >
    <div
      className={`w-auto min-w-[250px] max-w-[320px] mx-[30px] my-[12px] rounded-md z-[100] ${
        dragging ? 'cursor-move' : 'cursor-pointer'
      } ${centeredNode === node.id ? 'centeredNode' : `node ${tourguideNode?.nodeClass}`}`}
      ref={tableRef}
      style={position}
      id={centeredNode === node.id ? '#centeredNode' : '#node'}
    >
      <div
        id={node.id}
        className={`${
          !node.id ? 'hidden' : ''
        } transition-colors duration-300 ease-in-out mx-3 hover-underline-animation relative h-auto py-1 pl-4 pr-3 border border-[color:var(--border-main)] rounded-md text-base font-medium text-white  ${
          node.id === centeredNode ? 'bg-[color:var(--background-darker)]' : 'bg-[color:var(--background-dark)]'
        } ${selectedCategory?.includes(node?.category?.value) ? '!bg-[#0a8d8d]' : ''} ${
          !cursor.node ? ' animate-fadeIn' : ' animate-fadeOut'
        } ${editor?.type ? 'opacity-100' : ''}`}
      >
        <div
          className={`flex items-center p-1 font-[400] ${
            centeredNode === node.id ? 'justify-between' : 'justify-between'
          } gap-4`}
          onClick={() => {
            handleAccordion()
          }}
          style={{ fontFamily: 'Lexend Deca' }}
        >
          {node.title}
          <span className="flex gap-2">
            <span
              className={`transition-all ease-in-out duration-500 select-none cursor-pointer ${
                openAccordion !== node.id || node.id === 'cn' ? 'hidden' : ''
              } ${centeredNode === node.id ? '!block addsubNode' : ''} tooltip`}
              onClick={(e) => {
                e.stopPropagation()
                if (addingSubnode) {
                  setEditor({})
                } else setEditor({ type: 'nodes' })
                setAddingSubnode((t) => !t)
              }}
            >
              {' '}
              {addingSubnode ? <MdClose /> : centeredNode === node.id ? <HiPlus className="" /> : <FaPlus />}{' '}
              <span
                className={`text-sm tooltip-text animate-[ease-in-out] duration-200  ${
                  leftNode ? 'tooltip-right-top' : 'tooltip-left-top'
                }`}
              >
                {' '}
                <span className="rotate-[45deg]"></span>
                {addingSubnode ? 'Close adding subnodes' : 'Add sub-nodes'}{' '}
              </span>
            </span>
            <span
              className={`transition-all ease-in-out duration-500 select-none ${tourguideNode?.dropdownClass} ${
                openAccordion !== node.id && centeredNode !== node.id ? tourguideNode?.anothersideNode : ''
              } ${openAccordion === node.id ? 'rotate-[90deg]' : ''} ${
                centeredNode === node.id ? 'hidden' : ''
              } tooltip`}
              onClick={() => {
                console.log('run', tourguideNode.run, tourguideNode, tourguideNode?.next)
                if (tourguideNode?.run) {
                  tourguideNode?.next?.()
                }
              }}
            >
              <FaChevronDown />
              {openAccordion !== node.id && (
                <span className={`text-sm tooltip-text  ${leftNode ? 'tooltip-right-top' : 'tooltip-left-top'}`}>
                  {' '}
                  <span className="rotate-[45deg]"></span> Open node box{' '}
                </span>
              )}
            </span>
            <span
              className={`transition-all ease-in-out duration-500 select-none cursor-pointer ${
                tourguideNode?.bringCenter
              } ${centeredNode === node.id ? 'hidden' : ''} tooltip`}
              onClick={(e) => {
                e.stopPropagation()
                // console.log(node.id,centeredNode)
                if (node.id !== centeredNode && node.id !== 'cn') {
                  changeNode(centerNodeData, openAccordionData, node.id)
                }
                if (tourguideNode?.run) {
                  tourguideNode?.next?.()
                }
              }}
            >
              {leftNode ? <BsBoxArrowInDownRight /> : <BsBoxArrowInDownLeft />}
              <span className={`text-sm tooltip-text ${leftNode ? 'tooltip-right-top' : 'tooltip-left-top'} `}>
                {' '}
                <span className="rotate-[45deg]"></span>Bring the node to the center{' '}
              </span>
            </span>
            {centeredNode === node.id && (
              <span
                className={`closeNode transition-all duration-500 ease-in-out select-none tooltip ${
                  centeredNode === node.id ? '' : 'invisible'
                }`}
                onClick={() => changeNode(centerNodeData, openAccordionData, 'close')}
              >
                <VscChromeClose />
                <span className={`text-sm tooltip-text tooltip-right-top `}>
                  {' '}
                  <span className="rotate-[45deg]"></span>To close center node{' '}
                </span>
              </span>
            )}
          </span>
        </div>
        {/* {editor?.type === 'nodes' && (node.id === centeredNode || node.id === openAccordion) && ( */}
        {/* { node.id === openAccordion && (
          <div
            className="absolute font-bold right-[-8px] top-[-12px] p-1.5 rounded-full bg-[color:var(--background-button)]"
            onClick={() => {
              setAddingSubnode(true)
            }}
          >
            {' '}
            <FaPlus className="" />{' '}
          </div>
        )} */}

        {/* Add subnodes */}
        {openAccordion === node.id || centeredNode === node.id ? (
          <div
            className={`absolute right-5 top-0 font-medium bg-white ring-slate-600 shadow-lg rounded-lg py-4 px-2 flex flex-col ${
              addingSubnode ? 'opacity-100 top-10 z-[999]' : 'opacity-0 z-[-10]'
            }`}
            style={{ transition: 'all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)' }}
          >
            <div className="flex flex-col gap-1">
              {fields.map((field, index) => {
                return (
                  <div className="my-3" key={index}>
                    <Input
                      type={field.type}
                      title={field.title}
                      defaultValue={field.value}
                      name={field.name}
                      onChange={(e) => handleInputChange(e, index)}
                      inputClassName={''}
                      labelClassName={'bg-[#fff]'}
                      value={inputValues[index]}
                    />
                  </div>
                )
              })}
              <div className="flex justify-center gap-2">
                <button
                  className=" my-2 w-fit px-4 mx-auto py-2 text-base font-[Sora] rounded-lg bg-[color:var(--background-button)] font-light text-white"
                  onClick={() => {
                    let tmpFields = [...fields]
                    tmpFields.push({ type: 'text', title: 'Title', value: '', name: 'title' })
                    let tmpValues = [...inputValues]
                    tmpValues.push('')
                    setFields(tmpFields)
                    setInputValues(tmpValues)
                  }}
                >
                  Add More{' '}
                </button>
                <button
                  className=" my-2 w-fit px-4 mx-auto py-2 font-[Sora] rounded-lg bg-[color:var(--background-button)] font-light text-white"
                  onClick={() => {
                    addSubNode(node, inputValues)
                    setAddingSubnode(false)
                  }}
                >
                  Done{' '}
                </button>
              </div>{' '}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`transition-[height] duration-700 ease-in-out px-3 ${
          node.id === centeredNode ? '' : '!overflow-y-hidden'
        } ${openAccordion === node.id || node.id === centeredNode ? 'max-h-[100vh]' : 'h-0 overflow-hidden'}`}
        style={{ height: childHeight + 'px' }}
        // style={{ ...(openAccordion===node.id ?{height: childHeight +'px'}:{})}}
      >
        {/* Subnodes mapping */}
        {/* {console.log(cursor)} */}
        {node.nodes?.map((subNode: any, index: number) => {
          return (
            <div
              id={subNode.id}
              className={`relative p-1 px-5 border-b z-[100] border-[color:var(--border-main)] ${
                centeredNode === node?.id
                  ? 'bg-[color:var(--background-center)]' // background color for center table's subnodes
                  : 'bg-[color:var(--background-primary)]' // background color for other table's subnodes
              } rounded-md cursor-pointer duration-500 ease-in-out ${editor?.type ? 'opacity-100' : ''}
              ${
                (fixed?.start && centeredNode === node?.id) || (fixed?.end && centeredNode !== node?.id)
                  ? fixed.start === subNode?.id || fixed.end === subNode?.id
                    ? 'animate-fadeIn !border-b-4 !border-[color:var(--border-selected)]'
                    : 'animate-fadeOut !z-[1]'
                  : `${
                      !cursor.node || cursor.subNode === subNode.id || cursor?.connections?.includes(subNode.id)
                        ? 'animate-fadeIn' // highlight subnode on hover and opacity full if no subnode is hovered
                        : 'animate-fadeOut'
                    } ${
                      cursor?.node && (cursor?.subNode === subNode.id || cursor?.connections?.includes(subNode.id))
                        ? '!border-b-4 !border-[color:var(--border-selected)]' // bottom border for hovered subnode and its other end of link
                        : ''
                    }`
              }
              `}
              onMouseEnter={() => {
                if (timerRef) clearTimeout(timerRef)
                // console.log("enter")
                if (subNode?.connections && subNode.connections?.length && centeredNode === node?.id) {
                  setCursor({
                    node: node.id,
                    subNode: subNode.id,
                    connections: subNode.connections.map((item: any) => {
                      return item.id.split('|')[0] === centeredNode ? `cn-${item.id.split('|')[1]}` : item.id
                    }),
                  })
                } else {
                  setCursor({})
                }
              }}
            >
              <span
                className={`font-[300] mr-3 ${centeredNode === node.id ? 'pr-6' : 'pr-3'}`}
                style={{ fontFamily: 'Lexend Deca' }}
              >
                {subNode.title}
              </span>
              {(centeredNode === node.id || openAccordion === node.id) && node.id !== 'cn' && (
                <span
                  className={`absolute right-4 ml-3 my-0.5 text-right align-middle select-none ${
                    !selectedSubnodes?.includes(subNode.id) && node.id === centeredNode
                      ? tourguideNode?.addConnection
                      : ''
                  }`}
                  onClick={() => {
                    if (centeredNode === node.id || openAccordion === node.id) {
                      if (selectedSubnodes?.includes(subNode.id)) {
                        setSelectedSubnodes((e: any) => {
                          return e.filter((t: string) => t !== subNode.id)
                        })
                      } else {
                        setSelectedSubnodes((e: any) => {
                          return [...e, subNode.id]
                        })
                      }
                    }
                    if (tourguideNode?.run) {
                      tourguideNode?.next?.()
                    }
                  }}
                >
                  <BiPlus />
                </span>
              )}
              <span
                className={`absolute ${node.id === 'cn' ? 'right-5' : 'right-9'} ml-3 my-0.5 text-right align-middle ${
                  fixed?.start !== subNode.id && fixed?.end !== subNode.id ? tourguideNode?.pinsubnode : ''
                }`}
                onClick={() => {
                  if (!editor?.type) {
                    setFixed((e: any) => {
                      const { start, end } = e
                      if (centeredNode === node.id) {
                        if (start && start === subNode.id) {
                          return { ...(end ? { end: end } : {}) }
                        } else {
                          return { start: subNode.id, ...(end ? { end: end } : {}) }
                        }
                      } else {
                        if (end && end === subNode.id) {
                          return { ...(start ? { start: start } : {}) }
                        } else {
                          return { ...(start ? { start: start } : {}), end: subNode.id }
                        }
                      }
                      // if(start && centeredNode === node.id){
                      //   return {...(end ? {end:end}: {})}
                      // }else if(!start && centeredNode === node.id){
                      //   return {start:subNode.id,...(end?{end:end}:{})}
                      // }else if(end && centeredNode !== node.id){

                      // }
                      // return { ...((centeredNode === node.id && !e.start)? {start:subNode.id}: {}), ...((centeredNode !== node.id && !e.end)? {end:subNode.id}: {})}
                    })
                    if (tourguideNode?.run) {
                      tourguideNode?.next?.()
                    }
                  }
                }}
              >
                {fixed?.start === subNode.id || fixed?.end === subNode.id ? (
                  <AiFillPushpin />
                ) : (
                  <AiOutlinePushpin></AiOutlinePushpin>
                )}
              </span>

              {/* Connections */}
              {node?.id === centeredNode &&
                subNode?.connections?.map((con: any, i: number) => {
                  // getlocation(subNode.id,con.id)
                  if (
                    con.id &&
                    openAccordion &&
                    (con.id.slice(1).split('|')?.[0] === openAccordion ||
                      (con.id.slice(1).split('|')?.[0] === centeredNode && openAccordion === 'cn'))
                  )
                    return (
                      <div
                        className={`
                        ${
                          fixed?.start || fixed?.end
                            ? (fixed?.start && !fixed?.end && subNode.id === fixed.start) ||
                              (fixed?.end && fixed?.start && subNode.id === fixed.start && con.id === fixed.end) ||
                              (fixed?.end && con.id === fixed.end) ||
                              fixed.end === `cn-${con.id.split('|')[1]}`
                              ? 'animate-fadeIn z-[999]'
                              : 'animate-fadeOut z-[1]'
                            : `${
                                !cursor.node ||
                                (cursor.subNode === subNode.id &&
                                  (cursor?.connections?.includes(con.id) ||
                                    cursor?.connections?.includes(`cn-${con.id.split('|')[1]}`)))
                                  ? 'animate-fadeIn dem'
                                  : 'animate-fadeOut'
                              }`
                        }
                         ${editor?.type ? 'opacity-100' : ''}`}
                        onMouseEnter={() => {
                          if (timerRef) clearTimeout(timerRef)
                          // console.log("enter")
                          if (subNode?.connections && subNode.connections?.length) {
                            setCursor({
                              node: node.id,
                              subNode: subNode.id,
                              connections: [con.id.includes(centeredNode) ? `cn-${con.id.split('|')[1]}` : con.id],
                            })
                          } else {
                            setCursor({})
                          }
                        }}
                      >
                        <Xarrow
                          start={subNode.id}
                          end={con.id.slice(1).split('|')?.[0] === centeredNode ? `cn-${con.id.split('|')[1]}` : con.id}
                          labels={{
                            // middle: 'Reinforcing',
                            middle: (
                              <Rating
                                value={con.value}
                                i={i}
                                subNode={subNode}
                                setCenterNodeData={setCenterNodeData}
                                setCursor={setCursor}
                                connection={con}
                                centerNodeData={centerNodeData}
                                setFixed={setFixed}
                                openAccordionData={openAccordionData}
                                setOpenAccordionData={setOpenAccordionData}
                                changeNode={changeNode}
                                setRerender={setRerender}
                              />
                            ),
                          }}
                          // headShape={{svgElem:<path onClick={()=>{console.log("subnode:",subNode.id, "  con:", con.id )}} d="M 0 0 L 1 0.5 L 0 1 L 0.25 0.5 z"></path>}}
                          strokeWidth={cursor.subNode === subNode.id ? 3 : 2}
                          headSize={6}
                          lineColor={
                            con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value) ? '#ff3e3e' : '#2E2E2E'
                          }
                          headColor={
                            con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value) ? '#ff3e3e' : '#2E2E2E'
                          }
                          dashness={
                            (con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value)) && {
                              strokeLen: 5,
                              nonStrokeLen: 7,
                              animation: 2,
                            }
                          }
                          zIndex={10}
                          curveness={position?.curve ? 1 : 1}
                        />
                      </div>
                    )
                  // This is for to show connection in collapse tables
                  {
                    !con.id && console.log('No con Id', con, subNode)
                  }
                  if (con.id && (!openAccordion || con.id.slice(1).split('|')?.[0] !== openAccordion))
                    return (
                      <div
                        className={`${
                          !cursor.node || (cursor.subNode === subNode.id && cursor?.connections?.includes(con.id))
                            ? 'animate-fadeIn '
                            : 'animate-fadeOut'
                        } ${editor?.type ? 'opacity-100' : ''}`}
                        onMouseEnter={() => {
                          if (timerRef) clearTimeout(timerRef)
                          // console.log("enter")
                          if (subNode?.connections && subNode.connections?.length) {
                            setCursor({})
                          } else {
                            setCursor({})
                          }
                        }}
                      >
                        <Xarrow
                          start={subNode.id}
                          end={
                            con.id.slice(1).split('|')?.[0] === centeredNode
                              ? `cn`
                              : con.id.split('|')[0].replace('s', '')
                          }
                          strokeWidth={cursor.subNode === subNode.id ? 1.5 : 0.8}
                          headSize={10}
                          lineColor={'#C7B8EA'}
                          headColor={'#C7B8EA'}
                          dashness={
                            (con.value <= 0 || ratingConstants.BALANCING_REGEX.test(con.value)) && {
                              strokeLen: 5,
                              nonStrokeLen: 7,
                              animation: 2,
                            }
                          }
                          zIndex={10}
                          curveness={position?.curve ? 1 : 1}
                          // _cpx1Offset={10 + i * 10}
                          // _cpx2Offset={10 + i * 10}
                        />
                      </div>
                    )
                })}

              {/* add connections with centered node */}
              {selectedSubnodes?.includes(subNode.id) && (node.id === centeredNode || openAccordion === node.id) ? (
                <>
                  <div className="absolute left-[1px] flex flex-col gap-1 top-[50%] translate-y-[-50%]">
                    <span
                      className={`rounded-r-md w-2.5 h-3.5 bg-[#000000be] hover:scale-110 ${tourguideNode?.startConnection}`}
                      onClick={() => {
                        addConnection(subNode, 'positive', centeredNode === node.id ? 'center' : 'side')
                        if (tourguideNode?.run) {
                          tourguideNode?.next?.()
                        }
                      }}
                    ></span>
                    {/* <span
                      className="w-2 h-2.5 bg-[#ff3e3ed1] rounded-r-full hover:scale-110"
                      onClick={() => {
                        addConnection(subNode, 'negative', centeredNode === node.id ? 'center' : 'side')
                      }}
                    ></span> */}
                  </div>
                  <div className="absolute right-[1px] flex flex-col gap-1 top-[50%] translate-y-[-50%]">
                    <span
                      className="rounded-l-md w-2.5 h-3.5 bg-[#000000be] hover:scale-110"
                      onClick={() => {
                        addConnection(subNode, 'positive', centeredNode === node.id ? 'center' : 'side')
                      }}
                    ></span>
                    {/* <span
                      className="w-2 h-2.5 bg-[#ff3e3ed1] rounded-l-full hover:scale-110"
                      onClick={() => {
                        addConnection(subNode, 'negative', centeredNode === node.id ? 'center' : 'side')
                      }}
                    ></span> */}
                  </div>
                </>
              ) : (
                <></>
              )}
              {addedNodes?.start &&
                ((addedNodes?.position === 'center' && centeredNode !== node.id) ||
                  (addedNodes?.position === 'side' && centeredNode === node.id)) && (
                  <>
                    <div className={`absolute left-[1px] z-10 flex flex-col gap-1 top-[50%] translate-y-[-50%]`}>
                      <button
                        className={`w-2 h-3.5 bg-[#373636d4] rounded-r-md`}
                        onClick={() => {
                          addConnection(subNode)
                        }}
                      ></button>
                    </div>
                    <div className={`absolute right-[1px] z-10 flex flex-col gap-1 top-[50%] translate-y-[-50%]`}>
                      <button
                        className={`w-2 h-3.5 rounded-l-md bg-[#373636d4] ${tourguideNode?.endConnection}`}
                        onClick={() => {
                          if (tourguideNode?.run) {
                            tourguideNode?.next?.()
                          }
                          addConnection(subNode)
                        }}
                      ></button>
                    </div>
                  </>
                )}
            </div>
          )
        })}
      </div>
    </div>
    // </Draggable>
  )
}

// Math.floor(Math.random() * (max - min) + min);
// className={`relative p-1 px-5 border-b z-[100] border-[color:var(--border-main)] ${
//   centeredNode === node?.id
//     ? 'bg-[color:var(--background-center)]'  // background color for center table's subnodes
//     : 'bg-[color:var(--background-primary)]'  // background color for other table's subnodes
// } rounded-md cursor-pointer duration-500 ease-in-out ${
//   !cursor.node || cursor.subNode === subNode.id || cursor?.connections?.includes(subNode.id)
//     ? 'animate-fadeIn' // highlight subnode on hover and opacity full if no subnode is hovered
//     : 'animate-fadeOut'
// } ${
//   cursor?.node && (cursor?.subNode === subNode.id || cursor?.connections?.includes(subNode.id))
//     ? '!border-b-4 !border-[color:var(--border-selected)]' // bottom border for hovered subnode and its other end of link
//     : ''
// } ${editor?.type ? 'opacity-100' : ''}
// ${fixed?.start ?( (centeredNode === node?.id ? (fixed.start === subNode.id || fixed.end === subNode.id ? 'animate-fadeIn' : 'animate-fadeOut') : '') ? '' :''): ''}
// `}

// ${(fixed?.start || fixed?.end) ?( (centeredNode === node?.id ? (fixed.start === subNode.id || fixed.end === subNode.id ? 'animate-fadeIn' : 'animate-fadeOut') : '') ? '' :''): ''}
