import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Tutorial } from '../../components'
import { SurveyState } from './SurveyContext';
import {Admin} from "../Admin"
import Feedback from '../../components/Feedback';
import Start from "../Start"
import TopicList from "../TopicList"
import Tool from "../Tool"
import UserForm from "../UserForm"

const Survey = () => {
  return (
    <div>
      <SurveyState>
        <Routes>
          <Route path="/:id" element={<Start />} />
          <Route path="/:id/user" element={<UserForm />} />
          <Route path="/:id/details" element={<TopicList />} />
          <Route path="/:id/survey" element={<Tool />} />
          <Route path="/:id/tutorial" element={<Tutorial />} />
          <Route path="/:id/feedback" element={<Feedback />} />
          {/* <Route path="/admin" element={<Admin />} /> */}
        </Routes>
      </SurveyState>
    </div>
  )
}
export default Survey
