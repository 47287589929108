import React from 'react'
import { useNavigate } from 'react-router-dom'
const Informative = () => {
  const navigate = useNavigate()
  return (
    <section className="section" id="subscribe">
      <div className="container">
        <div className="row">
          <div className="mx-auto">
            <div className="section-heading">
              <h6>System Mapper Tool</h6>
              <h2>Why Use Systems Mapper Tool</h2>
            </div>
            <div className=" subscribe-content">
              <p className="max-w-5xl mx-auto text-center">
                Perfect for academic groups, non-profit and government organizations, and consultancies Provides
                multiple groups of nodes to enable your participants to create connections based on their knowledge and
                experience.
                <br></br>
                Generates rich and meaningful data that can inform your research and decision-making processes.
              </p>
              <div className="subscribe-form">
                <form id="subscribe-now" action="" method="get">
                  <div className="row">
                    <div className="flex flex-col flex-wrap items-center justify-center w-full gap-4 text-base text-white md:flex-row">
                      {/* <fieldset>
                        <button type="button" id="form-submit" className="main-button">
                          Try Now
                        </button>
                      </fieldset> */}
                      <div className="text-lg font-medium text-white">Try sample surveys :</div>
                      <button
                        className="px-3 py-1.5 bg-cyan-400 rounded-lg flex gap-2 items-center"
                        // style={{
                        //   backgroundImage: 'linear-gradient(135deg, rgb(100 199 140) 0%, rgb(0 105 211) 100%',
                        // }}
                        style={{backgroundImage : "linear-gradient(135deg, rgb(59 224 231) 0%, rgb(41 93 200) 100%)"}}
                        onClick={() => {
                          navigate('/11045')
                        }}
                        type="button"
                      >
                        <img src="/Assets/logonodes.svg" width={20}></img>
                        <span>Boosting Workplace Productivity</span>
                      </button>
                      <button
                        className="px-3 py-1.5 bg-cyan-400 rounded-lg flex gap-2 items-center"
                        onClick={() => {
                          navigate('/11046')
                        }}
                        // style={{backgroundImage: " linear-gradient(135deg, rgb(199 174 255) 0%, rgb(13 112 159) 100%)"}}
                        style={{ backgroundImage: 'linear-gradient(135deg, rgb(59 224 231) 0%, rgb(41 93 200) 100%)' }}
                        type="button"
                      >
                        <img src="/Assets/logonodes.svg" width={20}></img>
                        <span>Community-led Waterbody Conservation</span>
                      </button>
                      <button
                        className="px-3 py-1.5 bg-cyan-400 rounded-lg flex gap-2 items-center"
                        onClick={() => {
                          navigate('/11047')
                        }}
                        style={{ backgroundImage: 'linear-gradient(135deg, rgb(59 224 231) 0%, rgb(41 93 200) 100%)' }}
                        type="button"
                      >
                        <img src="/Assets/logonodes.svg" width={20}></img>
                        <span>Advancing Health & Well-being</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Informative
