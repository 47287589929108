import React, { useState, useEffect, useContext } from 'react'
import userapi from '../services/user.api'
import { CgArrowLongLeft } from 'react-icons/cg'
import { LoginContext } from '../context/LoginContext'
import SignUp from './Login/SignUp'

const AllUsers = () => {
  const [users, setUsers] = useState<any>([])
  const [addingusers, setAddingUsers] = useState(false)
  const { user } = useContext(LoginContext)
  const fetchUsers = () => {
    userapi.getAllUsers().then((users) => {
      console.log(users)
      if (users.data?.data) setUsers(users.data.data)
    })
  }
  useEffect(() => {
    fetchUsers()
  }, [addingusers])
  return (
    <>
      {!addingusers && (
        <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
          <div className="lg:ml-[50px] flex justify-between items-center">
            <h1 className="my-4 text-lg">All Users</h1>
            {user.role === 'superadmin' && (
              <button
                className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center "
                onClick={() => {
                  setAddingUsers((e) => !e)
                }}
              >
                Add new User
              </button>
            )}
          </div>
          <div className="">
            <table className="w-full text-left">
              <thead className="!bg-[#304164] text-base text-white" style={{ fontFamily: 'Lexend Deca' }}>
                <tr className="!bg-[#304164] text-left">
                  <td className="px-4 text-left min-w-[50px]">Id</td>
                  <td className="px-4 text-left min-w-[150px]">User Name</td>
                  <td className="px-4 text-center max-w-[70px]">Name</td>
                  <td className="px-4 text-center ">Email</td>
                  <td className="px-4 text-center ">Role</td>
                  <td className="px-4 text-center ">Status</td>
                  <td className="px-4 text-center ">Created at</td>
                </tr>
              </thead>
              <tbody>
                {!users?.length && (
                  <tr>
                    <td colSpan={6} className="mx-auto text-center">
                      {' '}
                      No Users
                    </td>
                  </tr>
                )}
                {users?.map((user: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="px-4 text-left"> {user.userId}</td>
                      <td className="px-6 text-left"> {user.userName}</td>
                      <td className="px-6 text-left"> {user.name}</td>
                      <td className="px-6 text-left"> {user.email}</td>
                      <td className="px-6 text-left"> {user.role}</td>
                      <td className="px-6 text-left"> <span className={`flex items-center justify-center w-3 h-3 rounded-full m-auto ${user.status ? 'bg-green-600' : 'bg-red-500'}`}></span></td>
                      <td className="px-6 text-center">
                        {' '}
                        {user?.createdOn ? new Date(user?.createdOn).toLocaleDateString() : '-'}
                      </td>
                      {/* <td className="px-3">
                      <div className="flex justify-center gap-3 items center">
                        <span
                          className="cursor-pointer hover:scale-110"
                          onClick={handleProjectChange('category', users.contentId)}
                        >
                          <BiCategoryAlt />
                        </span>
                      </div>
                    </td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {addingusers && (
        <div className="w-full h-full text-base" style={{ fontFamily: 'Lexend Deca' }}>
          <div className="flex justify-between items-center">
            <div className="flex items-center justify-start gap-4 cursor-pointer" onClick={()=>setAddingUsers(false)}>
              {' '}
              <span className="text-xl">
                <CgArrowLongLeft />
              </span>
              <span className="text-base">Back to Users</span>
            </div>
            {/* <button className="bg-[color:var(--background-button)] font-[Sora] hover:bg-[color:var(--background-button-hover)] text-[#fff] px-6 py-2 rounded-md flex gap-2 items-center ">
            Add new User
          </button> */}
          </div>
          <div className="">
            <SignUp setAddingUsers={setAddingUsers}></SignUp>
          </div>
        </div>
      )}
    </>
  )
}

export default AllUsers
