import { FC, useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
export type FAQType =  {
    question?: string
    answer?: string
  }
type FAQProps = {
  faqs?: FAQType []
}
const FAQ: FC<FAQProps> = ({ faqs }) => {
  const [faqExpandedItems, setFaqExpandedItems] = useState<number[]>([])

  const toggleExpand = (index: number) => {
    setFaqExpandedItems((items) => {
      const newState: number[] = JSON.parse(JSON.stringify(items))
      const i = newState.findIndex((it) => it === index)
      if (i > -1) {
        newState.splice(i, 1)
      } else {
        newState.push(index)
      }
      return newState
    })
  }
  return (
    <div className="mx-auto">
      <div
        className={`faq`}
        style={{
          color: '#000',
        }}
      >
        <div className={``}>
          {faqs?.map((item, index) => {
            const expanded = faqExpandedItems.includes(index)
            return (
              <div className={`bg-[$ffffff26] rounded-lg mb-4 lg:mb-7 font-[Montserrat]`}>
                <div
                  className={`py-3 xl:py-4 px-5 xl:px-6 bg-[#2c3e501a] rounded-lg ${
                    expanded ? 'py-5 xl:py-7' : ''
                  }`}
                >
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    onClick={() => {
                      toggleExpand(index)
                    }}
                  >
                    <div
                      className="inline-flex w-5/6 gap-1 mb-2 text-base font-normal cursor-pointer text-[#212529] lg:text-lg xl:text-xl"
                     
                    >
                      <span>{index + 1}.</span> {item.question || ''}
                    </div>
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        zIndex: 1,
                      }}
                      className="text-[#212529]"
                    >
                      {expanded ? <BsChevronUp className="text-[#212529]" /> : <BsChevronDown />}
                    </button>
                  </div>
                  {expanded && (
                    <div
                      className={
                        'text-[#212529]  font-normal text-xs lg:text-sm xl:text-base ml-5 mr-6 transition-[height]'
                      }
                    >
                      {item.answer || ''}
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default FAQ
