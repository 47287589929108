import client from './client'
type userType = {
  userName: string
  name: string
  img?: string
  email?: string
  contactNumber?: number
  dob?: Date
  password: string
  role?: string
  createdOn?: string
  modifiedOn?: string
  status: boolean
}

type updatePasswordType = { oldPassword: string; newPassword: string }
type loginType = { userName: string; password: string }

class UserServices {
  addUser(body: userType) {
    return client.post('/user/signup', body)
  }
  loginUser(body: loginType) {
    return client.post(`/user/login`, body)
  }
  getUserProfile() {
    return client.get('/user/profile')
  }
  updateUser(body: userType) {
    return client.put(`/user/profile/update`, body)
  }
  updatePassword(body: updatePasswordType) {
    return client.put(`/user/profile/updatepassword`, body)
  }
  getAllUsers() {
    return client.get(`/user/profile/all`)
  }
  deleteUser(id: number) {
    return client.delete(`/user/profile/${id}`)
  }
  forgotPassword(email:string) {
    return client.put(`/user/forgotpassword`,{email})
  }
  verifyOTP(email:string ,otp: number) {
    return client.put(`/user/verify/${email}/${otp}`)
  }
  resetPassword(email:string,otp: number, newPassword:string) {
    return client.put(`/user/resetpassword/${email}/${otp}`, {newPassword})
  }
}
let user = new UserServices()
export default user
