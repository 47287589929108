import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

type ToastType = 'success' | 'error';

interface Toast {
  id: number;
  message: string;
  type: ToastType;
}

let toastId = 0;

interface ToasterProps {
  // Add any additional props if needed
}

export interface ToasterRef {
  showToast: (message: string, type: ToastType) => void;
}

const Toaster = forwardRef<ToasterRef, ToasterProps>((props, ref) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showToast = (message: string, type: ToastType) => {
    const newToast: Toast = {
      id: toastId++,
      message,
      type,
    };
    setToasts((prevToasts) => [...prevToasts, newToast]);
  };

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    const autoRemoveToast = setTimeout(() => {
      if (toasts.length > 0) {
        removeToast(toasts[0].id);
      }
    }, 3000);

    return () => clearTimeout(autoRemoveToast);
  }, [toasts]);

  useImperativeHandle(ref, () => ({
    showToast,
  }));

  return (
    <div className="fixed z-[10000] top-4 right-4">
      {toasts.map((toast) => (
        <div
          key={toast.id}
          className={`p-4 rounded ${
            toast.type === 'success' ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          <p className="text-white">{toast.message}</p>
        </div>
      ))}
    </div>
  );
});

export default Toaster;
