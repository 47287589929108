import React, { useEffect, useState, useRef } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { ratingConstants } from '../lib/constants'
import { FaCommentDots } from 'react-icons/fa'
import InputSwitch from './Input/InputSwitch'
import AdjacentPortal from './AdjacentPortal'
import { BiMinus, BiPlus } from 'react-icons/bi'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
const Rating = ({
  value,
  i,
  subNode,
  setCenterNodeData,
  setCursor,
  connection,
  centerNodeData,
  setFixed,
  openAccordionData,
  setOpenAccordionData,
  changeNode,
  setRerender
}: {
  value: string
  i: number
  subNode: any
  setCenterNodeData: any
  setCursor: any
  connection: any
  centerNodeData: any
  setFixed?: any
  openAccordionData?: any
  setOpenAccordionData?:any
  changeNode:any,
  setRerender :React.Dispatch<React.SetStateAction<number>>
}) => {
  const [selected, setSelected] = useState<boolean>(false)
  const [showInput, setShowInput] = useState<boolean>(false)
  const [updatedConection, setUpdatedConection] = useState(
    centerNodeData?.nodes
      ?.find((subn: any) => subn.id === subNode?.id)
      ?.connections?.find((item: any) => item.id === connection?.id) ||
      openAccordionData?.nodes
        ?.find((subn: any) => subn.id === subNode?.id)
        ?.connections?.find((item: any) => item.id === connection?.id),
  )
  const [updatedValue, setUpdatedValue] = useState<number>(
    ((updatedConection?.value) &&
    !ratingConstants.BALANCING_REGEX.test(updatedConection.value)  &&
    !ratingConstants.REINFORCING_REGEX.test(updatedConection.value) &&
      parseFloat(updatedConection.value)) ||
      0
  )
  const [inputValue, setInputValue] = useState<number>(
    ( (updatedConection?.value) &&
    !ratingConstants.BALANCING_REGEX.test(updatedConection.value)  &&
    !ratingConstants.REINFORCING_REGEX.test(updatedConection.value) &&
      parseFloat(updatedConection.value)) ||
      0
  )
  const [isPositiveConnection, setIsPositiveConnection] = useState<boolean>(
    isNaN(parseFloat(value)) ? ratingConstants.REINFORCING_REGEX.test(value) : parseFloat(value) >0 
  )
  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [notesInputValue, setNotesInputValue] = useState(connection.notes || "")
  useEffect(()=>{
    if(!updatedConection || !updatedValue){
      setUpdatedConection(openAccordionData?.nodes
        ?.find((subn: any) => subn.id === subNode?.id)
        ?.connections?.find((item: any) => item.id === connection?.id) || centerNodeData?.nodes
        ?.find((subn: any) => subn.id === subNode?.id)
        ?.connections?.find((item: any) => item.id === connection?.id) )
    }
  },[openAccordionData])

  useEffect(() =>{
    setUpdatedValue(((updatedConection?.value) &&
    !ratingConstants.BALANCING_REGEX.test(updatedConection.value)  &&
    !ratingConstants.REINFORCING_REGEX.test(updatedConection.value)  &&
    parseFloat(updatedConection.value)) ||
    0)
    setInputValue(((updatedConection?.value) &&
    !ratingConstants.BALANCING_REGEX.test(updatedConection.value)  &&
    !ratingConstants.REINFORCING_REGEX.test(updatedConection.value) &&
    parseFloat(updatedConection.value)) ||
    0)
    setIsPositiveConnection(isNaN(parseFloat(value)) ? ratingConstants.REINFORCING_REGEX.test(value) : parseFloat(value) >0 )
  },[updatedConection])
  // useEffect(()=>{
  //   setUpdatedValue( (updatedConection?.value &&
  //     parseInt(updatedConection.value) !== -1 &&
  //     parseInt(updatedConection.value) !== 1 &&
  //     parseInt(updatedConection.value)) ||
  //     0)
  //   setInputValue( (updatedConection?.value &&
  //     parseInt(updatedConection.value) !== -1 &&
  //     parseInt(updatedConection.value) !== 1 &&
  //     parseInt(updatedConection.value)) ||
  //     0)
  // },[updatedConection?.value])

  const updateNode = () => {
    setCenterNodeData((e: any) => {
      return {
        ...e,
        nodes: e.nodes.map((item: any, i: number) => {
          if (item?.id !== subNode?.id) return item
          else {
            let temp = item.connections.map((con: any) => {
              if (con.id === connection.id) {
                return {
                  id: con.id,
                  value: inputValue===0 ? value : inputValue.toString(),
                  ...((updatedValue !== parseFloat(value) && updatedValue !== 0 && !isNaN(parseFloat(value)))? { rated: true } : {}),
                  notes : notesInputValue
                }
              } else return con
            })
            return { ...item, connections: temp }
            // temp.connections[i] = { ...temp.connections[i], value: inputValue.toString() }
          }
        }),
      }
    })
    setOpenAccordionData((e: any) => {
      return {
        ...e,
        nodes: e.nodes.map((item: any, i: number) => {
          if (item?.id !== subNode?.id) return item
          else {
            let temp = item.connections.map((con: any) => {
              if (con.id === connection.id) {
                return {
                  id: con.id,
                  value: inputValue===0 ? value : inputValue.toString(),
                  ...((updatedValue !== parseFloat(value) && updatedValue !== 0 && !isNaN(parseFloat(value)))? { rated: true } : {}),
                  notes : notesInputValue
                }
              } else return con
            })
            return { ...item, connections: temp }
            // temp.connections[i] = { ...temp.connections[i], value: inputValue.toString() }
          }
        }),
      }
    })
    setUpdatedValue(inputValue)
  }

  // const add = () => {
  //   if (inputValue < -0.1 || (inputValue > 0 && inputValue < 0.9) || (inputValue === 0 && isPositiveConnection)) {
  //     setInputValue((e) => (e*10 + 1)/10)
  //   }
  //   // if (inputValue > -10 && inputValue < 9) {
  //   //   setInputValue((e) => e + 1)
  //   // }
  // }
  // const sub = () => {
  //   if ((inputValue > -0.9 && inputValue < 0) || (inputValue > 0.1 && inputValue < 1) || (inputValue === 0 && !isPositiveConnection)) {
  //     // console.log('sub')
  //     setInputValue((e) => (e*10 - 1)/10)
  //   }
  //   // if (inputValue > -9 && inputValue < 10) {
  //   //   // console.log('sub')
  //   //   setInputValue((e) => e - 1)
  //   // }
  // }
  const toggleConnectionType = () => {
    // setInputValue((e) => {
    //   if(e===0){
    //     return -0.1
    //   }else{
    //     return -e
    //   }
    // })
    setInputValue((e) => -e)
    setIsPositiveConnection((e) => !e)
    updateNode()
    changeNode(centerNodeData, openAccordionData, 'openAccordion')
    setRerender((e)=>e+1)
  }

  const add =()=>{
    if(Math.abs(inputValue)>=0 && Math.abs(inputValue)<1){
      setInputValue((e) => (Math.abs(e)*10 + 1)/10*(isPositiveConnection?1:-1))
    }
  }

  const sub =()=>{
    if(Math.abs(inputValue)>0.1 && Math.abs(inputValue)<=1 ){
      setInputValue((e) => (Math.abs(e)*10 - 1)/10*(isPositiveConnection?1:-1))
    }
  }
  const ratingRef = useRef<HTMLDivElement>(null)
  const dotRef = useRef<HTMLDivElement>(null)
  const ratingCircleRef = useRef<HTMLDivElement>(null)
  const [notesDirection, setNotesDirection] = useState<"up" | "down">("up")

  // useEffect(() => {}, [showInput])

  return (
    <div className="relative rounded-full" ref={ratingRef}>
      <AdjacentPortal
          alignCenter
          targetRef={ratingCircleRef.current ? ratingCircleRef : dotRef}
          offset={{ top: 0 }}
        >
      <div
        className={`absolute bg-white shadow-lg rounded-lg bottom-0 w-[130px] left-[-40px] animate duration-400 ease-in-out ${
          showInput && (updatedConection?.rated || updatedValue !== 0)
            ? 'opacity-100 bottom-[50px] fadeInAnim'
            : showInput
            ? 'opacity-100 bottom-[20px] fadeInAnim'
            : 'opacity-0 fadeOutAnim hidden'
        }`}
        style={{ transition: 'all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55)' }}
      >
        {/* <input className='w-full py-2 text-[14px]' value={value} type="number" min="0" max="9" /> */}
        <div className="w-full">
          
          <div className="flex justify-center w-full">
            {/* <div className="px-2 py-1 text-surface bg-secondary-light w-full border border-[color:var(--MTS-backgroundColor-secondary-light)] !w-full">
              Text
            </div> */}
            <div className="flex justify-between items-center px-2 bg-white text-[color:var(--color-main)]  border border-[color:var(--border-main)] w-full">
              <button type="button" className="text-[inherit] focus:outline-none opacity-100 select-none" onClick={sub}>
                <IoChevronBack  className="text-[15px]" />
              </button>
              <span className="text-[inherit] px-2 font-bold py-1">{Math.abs(inputValue)}</span>
              <button type="button" className="text-[inherit] focus:outline-none opacity-100 select-none" onClick={add}>
              <IoChevronForward  className="text-[15px]" />
              </button>
            </div>
            <div className='flex items-center justify-center px-2.5 border border-[color:var(--border-main)] cursor-pointer select-none' onClick={()=>{setShowNotes(e=>!e); console.log({...ratingRef?.current})}}>
              <FaCommentDots  className='text-xl'/>
            </div>
           {showNotes && <div className={`absolute ${notesDirection==="up" ? "bottom-[120%]" : "top-[150%]"}  left-1/2 -translate-x-1/2 z-10 flex flex-col items-center justify-center gap-2 p-2 bg-white shadow-md rounded-md border border-slate-300`}>
              <div className='w-full p-1 text-center text-white bg-gray-500 rounded-md'>Notes (optional)</div>
              <textarea rows={3} className='p-1 text-sm border rounded-md bg-inherit border-slate-300 focus:outline-none' value={notesInputValue || connection.notes || ""} onChange={(e:any)=>{setNotesInputValue(e.target.value)}}></textarea>
            </div>}
          </div>
          <div className='flex justify-center w-full border border-[color:var(--border-main)] py-1 text-[13px]'>
            <div className='font-bold px-2  bg-white text-[color:var(--color-main)] whitespace-nowrap flex items-center'><span className='text-[15px]'><BiPlus></BiPlus></span>ve</div>
            <InputSwitch switchClassName={"!w-8 !p-[1.5px] !h-4 before:!w-3.5 before:!h-3.5 peer-checked:before:!left-[14px]"} value={!isPositiveConnection} name={`connection-${subNode?.id}-${connection?.id}`} onChange={toggleConnectionType} />
            <div className='font-bold px-2 bg-white text-[color:var(--color-main)] whitespace-nowrap flex items-center'><span className='text-[15px]'><BiMinus></BiMinus></span> ve</div>
          </div>
        </div>
      </div>
      </AdjacentPortal>
      {!updatedConection?.rated && updatedValue === 0? (
        <span
          className="flex w-4 h-4"
          ref={dotRef}
          onClick={() => {
            if (!showInput) {
              setShowInput(true)
            } else {
              updateNode()
              setFixed({})
              setShowInput(false)
              setShowNotes(false)
            }
          }}
        >
          <span
            className={`rateConnection absolute inline-flex w-full h-full rounded-full opacity-75 bg-[color:var(--background-effect)] cursor-pointer ${
              !selected ? 'animate-ping' : ''
            }`}
            onClick={() => {
              setSelected(true)
            }}
          ></span>
          <span className="relative inline-flex w-4 h-4 rounded-full bg-[color:var(--background-effect)]"></span>
        </span>
      ) : (
        <div
          className="rating"
          ref={ratingCircleRef}
          style={{
            background: `conic-gradient${inputValue > 0 ? '(var(--background-effect)' : '(var(--background-error)'} ${
              Math.abs(updatedValue) * 100
            }%, transparent 0 100%)`,
          }}
          onClick={() => {
            if (!showInput) {
              setShowInput(true)
            } else {
              updateNode()
              setFixed({})
              setShowInput(false)
              setShowNotes(false)
            }
          }}
        >
          <span>{Math.abs(updatedValue)}</span>
        </div>
      )}
    </div>
  )
}
export default Rating
